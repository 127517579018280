import { useEffect, useState } from "react";
import { Route, Redirect, useLocation } from "react-router-dom";
import AuthHelper from "../services/auth-helper";
import TenantApiService from "../services/TenantApiService";
import { Loader } from "@fluentui/react-northstar";

// const renderRedirect = () => {
//   storeCurrentPath();

//   return <Redirect to={{ pathname: "/login", state: { from: location } }} />;
// };

const AuthenticatedRoute = ({ component: Component, ...rest }) => {
  const _location = useLocation();
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [isTenantProvisioned, setIsTenantProvisioned] = useState(false);
  const [isAnalytics, setIsAnalytics] = useState(false);
  const [isAdmin, setIsAdmin] = useState(false);
  const [canLogin, setCanLogin] = useState(false);
  const [provisioningInProgress, setProvisioningInProgress] = useState(false);
  const [tenantStatus, setTenantStatus] = useState(0);
  const [isDeleted, setIsDeleted] = useState(false);
  const [isLoaded, setIsLoaded] = useState(false);
  const [isFirstLogin, setIsFirstLogin] = useState(false);
  const [demoModeVisible, setDemoModeVisible] = useState(false);

  const authSuccessCallback = (token) => {
    if (token) {
      setIsAuthenticated(true);
    }
    TenantApiService.GetAccountPermission(token).then((response) => {
      if (response) {
        if (response.provisioningInProgress) {
          setProvisioningInProgress(true);
          setTenantStatus(response.tenantStatus);
        } else {
          setIsTenantProvisioned(true);
          setIsAdmin((response.isAdmin || response.isAdminInherited === 1));
          setIsAnalytics((response.isAnalytics || response.isAnalyticsInherited === 1));
          setCanLogin(response.canLogin);
          setIsDeleted(response.isDeleted);
          setDemoModeVisible(response.demoModeVisible);
          setIsFirstLogin(response.isFirstTimeLogin);
          // setIsFirstLogin(true);
        }
      }
      setIsLoaded(true);
    });
  };

  useEffect(() => {
    //debugger;
    AuthHelper.getAccessToken(authSuccessCallback);
  }, []);

  return (
    <>
      {isLoaded ? (
        <Route
          {...rest}
          render={(props) =>
            isAuthenticated ? (
              provisioningInProgress ? (
                <Redirect
                  to={{
                    pathname: "/provisioning",
                    state: { tenantStatus: tenantStatus },
                  }}
                />
              ) : isTenantProvisioned ? (
                !isDeleted && canLogin ? (
                  (isAnalytics &&
                    props.location.pathname.includes("dashboards")) ||
                  (isAdmin &&
                    props.location.pathname.includes("configuration")) ? (
                    !isFirstLogin ? (
                      <Component demoModeVisible={demoModeVisible} {...props} />
                    ) : (
                      <Redirect
                        to={{
                          pathname: "/appoverview",
                          state: { from: _location },
                        }}
                      />
                    )
                  ) : (
                    <Redirect
                      to={{
                        pathname: "/accessdenied",
                        state: { from: _location },
                      }}
                    />
                  )
                ) : (
                  <Redirect
                    to={{
                      pathname: "/accessdenied",
                      state: { from: _location },
                    }}
                  />
                )
              ) : (
                <Redirect
                  to={{ pathname: "/provision", state: { from: _location } }}
                />
              )
            ) : (
              <Redirect
                to={{ pathname: "/login", state: { from: _location } }}
              />
            )
          }
        ></Route>
      ) : (
        <Loader />
      )}
    </>
  );
};

export default AuthenticatedRoute;
