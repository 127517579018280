import React from "react";
import { useState, useEffect } from "react";
import {
  Flex,
  Box,
  Checkbox,
  Button,
  Text,
  Divider,
  Avatar,
  Tooltip,
  Input,
  Loader,
} from "@fluentui/react-northstar";
import { Dialog } from "@progress/kendo-react-dialogs";
import {
  Grid,
  GridColumn as Column,
  GridNoRecords,
} from "@progress/kendo-react-grid";
import {
  TeamCreateIcon,
  SearchIcon,
  PresenceAvailableIcon,
  ExclamationTriangleIcon,
  ShiftActivityIcon
} from "@fluentui/react-icons-northstar";
import "../Dashboard/dashboard.scss";
import "./config.scss";
import ConfigurationApiService from "../../../services/ConfigurationApiService";
import AuthHelper from "../../../services/auth-helper";
import CenteredHeader from "../../Shared/Header/Header";
import useMediaQuery from "../../Shared/CustomHooks/useMediaQuery";
import WindowContext from "../../Shared/Context/Context";
import {GroupInheritanceIcon, TrialExpiredIcon, LicenseWarningIcon} from "./commonIcons";


const CellWithUserImage = (props) => {
  const user = props.dataItem;
  return (
    <WindowContext.Consumer>
      {
        context => (
        <td>
          <Avatar
            name={user.userName}
            image={user.userImage}
            size={context.mediaCategory.sm ? "small" : "large"}
          />
        </td>
        )
      }
    </WindowContext.Consumer>
  );
};

const CellWithUserName = (props) => {
  const user = props.dataItem;
  return (
    <td>
      <Box className="textOverFlow">
        <Text content={user.userName} size="medium" />
      </Box>
      <Text content={user.jobTitle} size="small" timestamp />
    </td>
  );
};

const CellWithAdminAcess = (props) => {
  const user = props.dataItem;
  const [adminAccessLoading, setAdminAccessLoading] = useState(false);

  const handleAdminAccess = (isChecked) => {
    setAdminAccessLoading(true);
    var data = {
      userGUIDList: [user.userGUID],
      AccessGranted: isChecked.checked,
    };
    AuthHelper.getAccessToken(function (token) {
      ConfigurationApiService.UpdateUserAdminAccess(data, token).then(
        (response) => {
          if (response !== undefined && response !== "") {
            props.handleUserAdminAccess(isChecked.checked, user);
          }
          setAdminAccessLoading(false);
        }
      );
    });
  };
  return (
    <td style={{ textAlign: "center" }}>
      {adminAccessLoading ? (
        <Loader size="small" />
      ) : (
        !user.administrativeAccess && user.administrativeAccessInherited !== 0 ? (
          user.administrativeAccessInherited === 1 ? <GroupInheritanceIcon /> : <LicenseWarningIcon />
        ) : (
          <Tooltip
            trigger={
              <Box>
                <Checkbox
                  toggle
                  disabled={(user.email == props.loggedInUserEmail) || (!user.isSubscribed && user.isSubscribedInherited !== 1)}
                  defaultChecked={user.administrativeAccess}
                  onClick={(event, isChecked) => {
                    handleAdminAccess(isChecked);
                  }}
                />
              </Box>
            }
            align="center"
            position="below"
            content={(user.email == props.loggedInUserEmail) && "Edit permission disabled for own account"}
          />
        )
      )}
    </td>
  );
};

const CellWithReportingAcess = (props) => {
  const user = props.dataItem;
  const [reportingAccessLoading, setReportingAccessLoading] = useState(false);

  const handleReportingAccess = (isChecked) => {
    setReportingAccessLoading(true);
    var data = {
      userGUIDList: [user.userGUID],
      AccessGranted: isChecked.checked,
    };
    AuthHelper.getAccessToken(function (token) {
      ConfigurationApiService.UpdateUserReportingAccess(data, token).then(
        (response) => {
          if (response !== undefined && response !== "") {
            props.handleUserReportingAccess(isChecked.checked, user);
          }
          setReportingAccessLoading(false);
        }
      );
    });
  };

  return (
    <td style={{ textAlign: "center" }}>
      {reportingAccessLoading ? (
        <Loader size="small" />
      ) : (
        !user.reportingAccess && user.reportingAccessInherited !== 0 ? (
          user.reportingAccessInherited === 1 ? <GroupInheritanceIcon /> : <LicenseWarningIcon />
        ) : (
          <Checkbox
            toggle
            disabled={!user.isSubscribed && user.isSubscribedInherited !== 1}
            defaultChecked={user.reportingAccess}
            onClick={(event, isChecked) => {
              handleReportingAccess(isChecked);
            }}
          />
        )
      )}
    </td>
  );
};

const CellWithSubscription = (props) => {
  const user = props.dataItem;
  const [subscriptionLoading, setSubscriptionLoading] = useState(false);

  const handleUserSubscription = (isChecked) => {
    setSubscriptionLoading(true);
    if (props.totalSubscribed >= props.maxSubscription && isChecked.checked) {
      setSubscriptionLoading(false);
      return;
    } else {
      var data = {
        userGUIDList: [user.userGUID],
        isSubscribed: isChecked.checked,
      };
      AuthHelper.getAccessToken(function (token) {
        ConfigurationApiService.UpdateUserSubscription(data, token).then(
          (response) => {
            if (response !== undefined && response !== "") {
              props.handleUserSubscription(isChecked.checked, user);
            }
            setSubscriptionLoading(false);
          }
        );
      });
    }
  };

  return (
    <td style={{ textAlign: "center" }}>
      {subscriptionLoading ? (
        <Loader size="small" />
      ) : (
        !user.isSubscribed && user.isSubscribedInherited !== 0 ? (
          user.isSubscribedInherited === 1 ? <GroupInheritanceIcon /> : <LicenseWarningIcon />
        ) : (
          <Tooltip
            trigger={
              <Box>
                <Checkbox
                  toggle
                  defaultChecked={user.isSubscribed}
                  disabled={user.email == props.loggedInUserEmail || (!props.canUserSubscribe && !user.isSubscribed)}
                  onClick={(event, isChecked) => {
                    handleUserSubscription(isChecked);
                  }}
                />
              </Box>
            }
            align="center"
            position="below"
            content={user.email == props.loggedInUserEmail ? "Edit permission disabled for own account" : 
              (!props.canUserSubscribe && !user.isSubscribed
                && "A maximum of " + props.maxSubscription + " people can have Data Enabled")
            }
          />
        )
      )}
    </td>
  );
};

const CellWithTrialSubscription = (props) => {
  const user = props.dataItem;
  const [trialSubscriptionLoading, setTrialSubscriptionLoading] =
    useState(false);

  const handleTrialSubscription = (isChecked) => {
    setTrialSubscriptionLoading(true);
    var data = {
      userGUIDList: [user.userGUID],
      isSubscribed: isChecked.checked,
    };
    AuthHelper.getAccessToken(function (token) {
      ConfigurationApiService.UpdateUserTrialSubscription(data, token).then(
        (response) => {
          if (response !== undefined && response !== "") {
            props.handleTrialSubscription(isChecked.checked, user);
          }
          setTrialSubscriptionLoading(false);
        }
      );
    });
  };

  return (
    <td style={{ textAlign: "center" }}>
      {user.isTrialExpired ? (
        <Box>
          <TrialExpiredIcon />
        </Box>
      ) : (
        <Box>
          {trialSubscriptionLoading ? (
            <Loader size="small" />
          ) : (
            !user.isTrialSubscribed && user.isTrialSubscribedInherited !== 0 ? (
              user.isTrialSubscribedInherited === 1 ? <GroupInheritanceIcon /> : <LicenseWarningIcon />
            ) : (
              <Checkbox
                toggle
                defaultChecked={user.isTrialSubscribed}
                onClick={(event, isChecked) => {
                  handleTrialSubscription(isChecked);
                }}
              />
            )
          )}
        </Box>
      )}
    </td>
  );
};

const ListDetailComponent = (props) => {
  const user = props.dataItem;
  const [adminAccessLoading, setAdminAccessLoading] = useState(false);
  const [reportingAccessLoading, setReportingAccessLoading] = useState(false);
  const [trialSubscriptionLoading, setTrialSubscriptionLoading] =
    useState(false);

  const handleAdminAccess = (isChecked) => {
    setAdminAccessLoading(true);
    var data = {
      userGUIDList: [user.userGUID],
      AccessGranted: isChecked.checked,
    };
    AuthHelper.getAccessToken(function (token) {
      ConfigurationApiService.UpdateUserAdminAccess(data, token).then(
        (response) => {
          if (response !== undefined && response !== "") {
            props.handleUserAdminAccess(isChecked.checked, user);
          }
          setAdminAccessLoading(false);
        }
      );
    });
  };

  const handleReportingAccess = (isChecked) => {
    setReportingAccessLoading(true);
    var data = {
      userGUIDList: [user.userGUID],
      AccessGranted: isChecked.checked,
    };
    AuthHelper.getAccessToken(function (token) {
      ConfigurationApiService.UpdateUserReportingAccess(data, token).then(
        (response) => {
          if (response !== undefined && response !== "") {
            props.handleUserReportingAccess(isChecked.checked, user);
          }
          setReportingAccessLoading(false);
        }
      );
    });
  };

  const handleTrialSubscription = (isChecked) => {
    setTrialSubscriptionLoading(true);
    var data = {
      userGUIDList: [user.userGUID],
      isSubscribed: isChecked.checked,
    };
    AuthHelper.getAccessToken(function (token) {
      ConfigurationApiService.UpdateUserTrialSubscription(data, token).then(
        (response) => {
          if (response !== undefined && response !== "") {
            props.handleTrialSubscription(isChecked.checked, user);
          }
          setTrialSubscriptionLoading(false);
        }
      );
    });
  };

  return (
    <section style={{ paddingRight: "32px" }}>
      <table>
        <tr>
          <CellWithUserImage {...props} colspan={2} />
        </tr>
        <tr>
          <td>App Owner:</td>
          <td class="text-right">
            {adminAccessLoading ? (
              <Loader size="small" />
            ) : (
              !user.administrativeAccess && user.administrativeAccessInherited !== 0 ? (
                user.administrativeAccessInherited === 1 ? <GroupInheritanceIcon /> : <LicenseWarningIcon />
              ) : (
                <Tooltip
                trigger={
                  <Box>
                    <Checkbox
                      className="chkTrial-mobile"
                      toggle
                      disabled={(user.email == props.loggedInUserEmail) || (!user.isSubscribed && user.isSubscribedInherited !== 1)}
                      defaultChecked={user.administrativeAccess}
                      onClick={(event, isChecked) => {
                        handleAdminAccess(isChecked);
                      }}
                    />
                  </Box>
                }
                align="center"
                position="below"
                content={(user.email == props.loggedInUserEmail) && "Edit permission disabled for own account"}
              />
              )
            )}
          </td>
        </tr>
        <tr>
          <td>Reporting Access:</td>
          <td class="text-right">
            {reportingAccessLoading ? (
              <Loader size="small" />
            ) : (
              !user.reportingAccess && user.reportingAccessInherited !== 0 ? (
                user.reportingAccessInherited === 1 ? <GroupInheritanceIcon /> : <LicenseWarningIcon />
              ) : (
                <Checkbox
                  className="chkTrial-mobile"
                  toggle
                  disabled={(!user.isSubscribed && user.isSubscribedInherited !== 1)}
                  defaultChecked={user.reportingAccess}
                  onClick={(event, isChecked) => {
                    handleReportingAccess(isChecked);
                  }}
                />
              )
            )}
          </td>
        </tr>
        <tr>
          <td>Trial :</td>
          <td class="text-right">
            {user.isTrialExpired ? (
              <Box>
                <TrialExpiredIcon />
              </Box>
            ) : (
              <Box>
                {trialSubscriptionLoading ? (
                  <Loader size="small" />
                ) : (
                  !user.isTrialSubscribed && user.isTrialSubscribedInherited !== 0 ? (
                    user.isTrialSubscribedInherited === 1 ? <GroupInheritanceIcon /> : <LicenseWarningIcon />
                  ) : (
                    <Checkbox
                      className="chkTrial-mobile"
                      toggle
                      defaultChecked={user.isTrialSubscribed}
                      onClick={(event, isChecked) => {
                        handleTrialSubscription(isChecked);
                      }}
                    />
                  )
                )}
              </Box>
            )}
          </td>
        </tr>
      </table>
    </section>
  );
};

const PeopleList = (props) => {
  const [userData, setUserData] = useState(null);
  const [tenantDetails, setTenantDetails] = useState(null);
  const [canUserSubscribe, setCanUserSubscribe] = useState(false);
  const [canGroupSubscribe, setCanGroupSubscribe] = useState(false);
  const [totalSubscribed, setTotalSubscribed] = useState(null);
  const [appOwnerChecked, setAppOwnerChecked] = useState(false);
  const [reportingAccessChecked, setReportingAccessChecked] = useState(false);
  const [groupSubscriptionChecked, setGroupSubscriptionChecked] = useState(false);
  const [trialSubscriptionChecked, setTrialSubscriptionChecked] = useState(false);
  const [loggedInUserEmail, setLoggedInUserEmail] = useState(null);


  useEffect(() => {
    setTenantDetails(props.tenantDetails);
    setLoggedInUserEmail(props.loggedInUserEmail);
    setAppOwnerChecked(props.selectedGroupDetail.isAdmin);
    setReportingAccessChecked(props.selectedGroupDetail.isAnalytics);
    setGroupSubscriptionChecked(props.selectedGroupDetail.isSubscribed);
    setTrialSubscriptionChecked(props.selectedGroupDetail.isTrialSubscribed);
    loadUserData();
  }, [props.selectedGroupDetail]);

  const expandChange = (event) => {
    let newData = userData.map((item) => {
      if (item.userName === event.dataItem.userName) {
        item.expanded = !event.dataItem.expanded;
      }
      return item;
    });
    setUserData(newData);
  };

  const loadUserData = () => {
    AuthHelper.getAccessToken(function (token) {
      ConfigurationApiService.GetUsersByGroupId(props.selectedGroupDetail.groupId, token).then(
        (response) => {
          if (response != undefined && response != "") {
            setTotalSubscribed(response.totalSubscribed);
            handleValidations(response.totalSubscribed, response.users);
            setUserData(response.users);
          } else {
            setUserData([]);
          }
        }
      );
    });
  };

  const handleValidations = (totalSubscribedUsers, users) => {
    var maxSubscription = props.tenantDetails.maxUsers;
    var availableUser = users.filter( (x) => !x.isSubscribed && x.isSubscribedInherited !== 1).length;
    if (totalSubscribedUsers < maxSubscription) {
      setCanUserSubscribe(true);
    } else {
      setCanUserSubscribe(false);
    }
    if (availableUser <= (maxSubscription - totalSubscribedUsers)) {
      setCanGroupSubscribe(true);
    } else {
      setCanGroupSubscribe(false);
    }
    
  };

  const handleUserAdminAccess = (checked, user) => {
    var tempUserdata = [...userData];
    var indexId = tempUserdata.findIndex((x) => x.userGUID === user.userGUID);

    tempUserdata[indexId].administrativeAccess = checked;
    setUserData(tempUserdata);
  };

  const handleUserReportingAccess = (checked, user) => {
    var tempUserdata = [...userData];
    var indexId = tempUserdata.findIndex((x) => x.userGUID === user.userGUID);

    tempUserdata[indexId].reportingAccess = checked;
    setUserData(tempUserdata);
  };

  const handleUserSubscription = (checked, user) => {
    var tempUserdata = [...userData];
    var indexId = tempUserdata.findIndex((x) => x.userGUID === user.userGUID);
    tempUserdata[indexId].isSubscribed = checked;
    var totalSubscribedUsers = totalSubscribed;
    if (checked) {
      setTotalSubscribed(totalSubscribedUsers + 1);
      handleValidations(totalSubscribedUsers + 1, tempUserdata);
    } else {
      tempUserdata[indexId].administrativeAccess = user.isSubscribedInherited === 1 ? tempUserdata[indexId].administrativeAccess : checked;
      tempUserdata[indexId].reportingAccess = user.isSubscribedInherited === 1 ? tempUserdata[indexId].reportingAccess : checked;
      if(user.isSubscribedInherited!==1){
        setTotalSubscribed(totalSubscribedUsers - 1);
        handleValidations(totalSubscribedUsers - 1, tempUserdata);
      }else{
        handleValidations(totalSubscribedUsers, tempUserdata);
      }
    }
    setUserData(tempUserdata);
  };

  const handleTrialSubscription = (checked, user) => {
    var tempUserdata = [...userData];
    var indexId = tempUserdata.findIndex((x) => x.userGUID === user.userGUID);

    tempUserdata[indexId].isTrialSubscribed = checked;
    setUserData(tempUserdata);
  };

  const handleGroupAppOwner = (isChecked) => {
    setUserData(null);
    var data = {
      groupId: props.selectedGroupDetail.groupId,
      permissionGranted: isChecked.checked,
    };
    AuthHelper.getAccessToken(function (token) {
      ConfigurationApiService.UpdateGroupAdminAccess(data, token).then(
        (response) => {
          if (response !== undefined && response !== "") {
            props.handleGroupPermissions(isChecked.checked, reportingAccessChecked, groupSubscriptionChecked, trialSubscriptionChecked);
            setAppOwnerChecked(isChecked.checked);
          }
          loadUserData();
        }
      );
    });
  };

  const handleGroupReportingAccess = (isChecked) => {
    setUserData(null);
    var data = {
      groupId: props.selectedGroupDetail.groupId,
      permissionGranted: isChecked.checked,
    };
    AuthHelper.getAccessToken(function (token) {
      ConfigurationApiService.UpdateGroupReportingAccess(data, token).then(
        (response) => {
          if (response !== undefined && response !== "") {
            props.handleGroupPermissions(appOwnerChecked, isChecked.checked, groupSubscriptionChecked, trialSubscriptionChecked);
            setReportingAccessChecked(isChecked.checked);
          }
          loadUserData();
        }
      );
    });
  };

  const handleGroupSubscription = (isChecked) => {
    var tempUserdata = [...userData];
    setUserData(null);
    var data = {
      groupId: props.selectedGroupDetail.groupId,
      permissionGranted: isChecked.checked,
    };
    AuthHelper.getAccessToken(function (token) {
      ConfigurationApiService.UpdateGroupSubscription(data, token).then(
        (response) => {
          if (response !== undefined && response !== "") {
            if(isChecked.checked){
              props.handleGroupPermissions(appOwnerChecked, reportingAccessChecked, isChecked.checked, trialSubscriptionChecked);
            }
            else{
              setAppOwnerChecked(0);
              setReportingAccessChecked(0);
              props.handleGroupPermissions(0, 0, isChecked.checked, trialSubscriptionChecked);
            }
            setGroupSubscriptionChecked(isChecked.checked);
            loadUserData();
          }else{
            setUserData(tempUserdata);
          }
        }
      );
    });
  };

  const handleGroupTrialSubscription = (isChecked) => {
    setUserData(null);
    var data = {
      groupId: props.selectedGroupDetail.groupId,
      permissionGranted: isChecked.checked,
    };
    AuthHelper.getAccessToken(function (token) {
      ConfigurationApiService.UpdateGroupTrialSubscription(data, token).then(
        (response) => {
          if (response !== undefined && response !== "") {
            props.handleGroupPermissions(appOwnerChecked, reportingAccessChecked, groupSubscriptionChecked, isChecked.checked);
            setTrialSubscriptionChecked(isChecked.checked);
          }
          loadUserData();
        }
      );
    });
  };


  const CustomCellWithUserImage = (props) => (
    <CellWithUserImage {...props} />
  );

  const CustomCellWithAdminAcess = (props) => (
    <CellWithAdminAcess
      {...props}
      loggedInUserEmail = {loggedInUserEmail}
      handleUserAdminAccess={(checked, user) => {
        handleUserAdminAccess(checked, user);
      }}
    />
  );

  const CustomCellWithReportingAcess = (props) => (
    <CellWithReportingAcess
      {...props}
      handleUserReportingAccess={(checked, user) => {
        handleUserReportingAccess(checked, user);
      }}
    />
  );

  const CustomCellWithSubscription = (props) => (
    <CellWithSubscription
      {...props}
      loggedInUserEmail = {loggedInUserEmail}
      maxSubscription={tenantDetails.maxUsers}
      canUserSubscribe={canUserSubscribe}
      totalSubscribed={totalSubscribed}
      handleUserSubscription={(checked, user) => {
        handleUserSubscription(checked, user);
      }}
    />
  );

  const CustomCellWithTrialSubscription = (props) => (
    <CellWithTrialSubscription
      {...props}
      handleTrialSubscription={(checked, user) => {
        handleTrialSubscription(checked, user);
      }}
    />
  );

  const CustomListDetailedComponent = (props) => (
    <ListDetailComponent
      {...props}
      handleUserAdminAccess={(checked, user) => {
        handleUserAdminAccess(checked, user);
      }}
      handleUserReportingAccess={(checked, user) => {
        handleUserReportingAccess(checked, user);
      }}
      handleTrialSubscription={(checked, user) => {
        handleTrialSubscription(checked, user);
      }}
    />
  );

  return (
    <WindowContext.Consumer>
      {
        context => (
          <Box style={{ height: "100%" }}>
            {userData != null && userData.length > 0 && (
              <>
                <Flex
                  space="between"
                  styles={{ marginBottom: "10px", fontSize: "0.9rem" }}
                >
                  <Flex gap="gap.small" column={context.mediaCategory.sm}>
                    <Box>
                      <Checkbox
                        toggle
                        label="App Owner : "
                        labelPosition="start"
                        disabled={!groupSubscriptionChecked}
                        defaultChecked={appOwnerChecked}
                        checked={appOwnerChecked}
                        onClick={(event, isChecked) => {
                          handleGroupAppOwner(isChecked);
                        }}
                      />
                    </Box>
                    <Box>
                      <Checkbox
                        toggle
                        label="Reporting Access : "
                        labelPosition="start"
                        disabled={!groupSubscriptionChecked}
                        defaultChecked={reportingAccessChecked}
                        checked={reportingAccessChecked}
                        onClick={(event, isChecked) => {
                          handleGroupReportingAccess(isChecked);
                        }}
                      />
                    </Box>
                    <Tooltip
                      trigger={
                        <Box>
                          <Checkbox
                            toggle
                            label="Data Enabled : "
                            labelPosition="start"
                            defaultChecked={groupSubscriptionChecked}
                            checked={groupSubscriptionChecked}
                            disabled={
                              !canGroupSubscribe && groupSubscriptionChecked
                                ? false
                                : !canGroupSubscribe
                            }
                            onClick={(event, isChecked) => {
                              handleGroupSubscription(isChecked);
                            }}
                          />
                        </Box>
                      }
                      align="center"
                      position="below"
                      content={
                        !canGroupSubscribe && !groupSubscriptionChecked
                          && "A maximum of " +
                            tenantDetails.maxUsers +
                            " people can have Data Enabled"
                      }
                    />
                    <Box>
                      <Checkbox
                        toggle
                        label="Trial : "
                        labelPosition="start"
                        defaultChecked={trialSubscriptionChecked}
                        checked={trialSubscriptionChecked}
                        onClick={(event, isChecked) => {
                          handleGroupTrialSubscription(isChecked);
                        }}
                      />
                    </Box>
                  </Flex>
                </Flex>
              </>
            )}
            <Divider />
            <Flex
              gap="gap.small"
              padding="padding.medium"
              column
              styles={{ overflowY: "scroll", width: "100%" }}
            >
              <Flex.Item>
                <Grid
                  style={{
                    maxHeight: context.mediaCategory.sm
                      ? "calc(100vh - 300px)"
                      : "calc(100vh - 200px)",
                  }}
                  detail={context.mediaCategory.sm ? CustomListDetailedComponent : null}
                  expandField="expanded"
                  onExpandChange={expandChange}
                  data={userData}
                >
                  <GridNoRecords>
                    {userData != null ? "No records available" : <Loader />}
                  </GridNoRecords>
                  {!context.mediaCategory.sm && (
                    <Column
                      width={context.mediaCategory.sm ? "40px" : "56px"}
                      title=""
                      cell={CustomCellWithUserImage}
                    />
                  )}
                  <Column
                    width={context.mediaCategory.sm ? "calc(100% - 192px)" : "calc(100% - 265px)"}
                    title="Name"
                    cell={CellWithUserName}
                  />
                  {!context.mediaCategory.sm && (
                    <Column
                      width="110px"
                      title="App Owner"
                      cell={CustomCellWithAdminAcess}
                      headerCell={CenteredHeader}
                    />
                  )}
                  {!context.mediaCategory.sm && (
                    <Column
                      width="135px"
                      title="Reporting Access"
                      cell={CustomCellWithReportingAcess}
                      headerCell={CenteredHeader}
                    />
                  )}
                  <Column
                    width="135px"
                    title="Data Enabled"
                    cell={CustomCellWithSubscription}
                    headerCell={CenteredHeader}
                  />
                  {!context.mediaCategory.sm && (
                    <Column
                      width="100px"
                      title="Trial"
                      cell={CustomCellWithTrialSubscription}
                      headerCell={CenteredHeader}
                    />
                  )}
                </Grid>
              </Flex.Item>
            </Flex>
          </Box>
        )
      }
    </WindowContext.Consumer>
  );
};




const CellWithMemberList = (props) => {
  const group = props.dataItem;
  return (
    <td>
      <Button
        className="memberButton"
        onClick={() => props.onOpen(group)}
        icon={<TeamCreateIcon outline />}
     //   content={"" + group.memberCount}
        size="small"
      />
    </td>
  );
};

const DetailComponent = (props) => {
  const group = props.dataItem;
  return (
    <section style={{ paddingRight: "32px" }}>
      <table>
        <tr>
          <td>Type:</td>
          <td class="text-right">
            <Text content={group.groupTypeName} />
          </td>
        </tr>
        <tr>
          <td>Privacy:</td>
          <td class="text-right">
            <Text content={group.visibilityName} />
          </td>
        </tr>
      </table>
    </section>
  );
};

const GroupsConfig = (props) => {
  const [searchInput, setSearchInput] = useState("");
  const [groupsData, setGroupsData] = useState(null);
  const [tenantDetails, setTenantDetails] = useState(null);
  const [selectedGroupDetail, setSelectedGroupDetail] = useState(null);
  const [open, setOpen] = useState(false);
  const [loggedInUserEmail, setLoggedInUserEmail] = useState(null);

  useEffect(() => {
    setSearchInput("");
    setTenantDetails(props.tenantDetails);
    setLoggedInUserEmail(props.loggedInUserEmail);
    loadGroupsData();
  }, []);

  const onOpen = (group) => {
    setSelectedGroupDetail(group);
    setOpen(true);
  };

  const onCancel = () => {
    setOpen(false);
    setSelectedGroupDetail(null);
  };

  const expandChange = (event) => {
    let newData = groupsData.map((item) => {
      if (item.groupName === event.dataItem.groupName) {
        item.expanded = !event.dataItem.expanded;
      }
      return item;
    });
    setGroupsData(newData);
  };

  const loadGroupsData = () => {
    setGroupsData(null);
    AuthHelper.getAccessToken(function (token) {
      ConfigurationApiService.GetGroups(token).then((response) => {
        if (response != undefined && response != "") {
          setGroupsData(response);
        } else {
          setGroupsData([]);
        }
      });
    });
  };

  const handleGroupPermissions = (isAdmin, isAnalytics, isSubscribed, isTrialSubscribed) => {
    let gpData = [...groupsData];
    var indexId = gpData.findIndex((x) => x.groupId === selectedGroupDetail.groupId);
    gpData[indexId].isAnalytics = isAnalytics;
    gpData[indexId].isAdmin = isAdmin;
    gpData[indexId].isSubscribed = isSubscribed;
    gpData[indexId].isTrialSubscribed = isTrialSubscribed;
    setGroupsData(gpData)
  }

  const CustomCellWithMemberList = (props) => (
    <CellWithMemberList
      {...props}
      onOpen={(groupId) => {
        onOpen(groupId);
      }}
    />
  );

  return (
    <WindowContext.Consumer>
      {
        context => (
        <>
          <Flex gap="gap.small" padding="padding.medium" column fill>
            <Flex.Item>
              <Input
                fluid
                icon={<SearchIcon />}
                clearable
                value={searchInput}
                placeholder="Search..."
                onChange={(e, { name, value }) => {
                  setSearchInput(value);
                }}
              />
            </Flex.Item>
            <Flex.Item>
              <Grid
                style={{ maxHeight: "calc(100vh - 115px)" }}
                detail={context.mediaCategory.sm ? DetailComponent : null}
                expandField="expanded"
                onExpandChange={expandChange}
                data={
                  groupsData != null
                    ? groupsData.filter((group) =>
                        group.groupName
                          .toLowerCase()
                          .includes(searchInput.toLowerCase())
                      )
                    : null
                }
              >
                <GridNoRecords>
                  {groupsData != null ? "No records available" : <Loader />}
                </GridNoRecords>
                <Column
                  width="calc(100% - 120px)"
                  title="Group Name"
                  field="groupName"
                />
                {!context.mediaCategory.sm && (
                  <Column width="150px" title="Type" field="groupTypeName" />
                )}
                <Column
                  width={context.mediaCategory.sm ? "100px" : "150px"}
                  title="Members"
                  cell={CustomCellWithMemberList}
                />
                {!context.mediaCategory.sm && (
                  <Column
                    width="100px"
                    className="text-center"
                    title="Privacy"
                    field="visibilityName"
                    headerCell={CenteredHeader}
                  />
                )}
              </Grid>
            </Flex.Item>
          </Flex>
          {open && (
            <Dialog
              className="settingsDialog"
              title={selectedGroupDetail.groupName}
              onClose={() => onCancel()}
            >
              <PeopleList 
                selectedGroupDetail={selectedGroupDetail} 
                loggedInUserEmail={loggedInUserEmail} 
                tenantDetails={tenantDetails} 
                handleGroupPermissions={handleGroupPermissions}
              />
            </Dialog>
          )}
        </>
        )
      }
    </WindowContext.Consumer>
  );
};

export default GroupsConfig;
