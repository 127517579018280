import { useEffect, useState } from "react";
import { Input } from "@fluentui/react-northstar";
import { CalendarIcon } from "@fluentui/react-icons-northstar";
import './nativedatepicker.scss';

const formatDate = (value) => {
    // API: yyyy-MM-dd
    // Display: dd/MMM/yyyy
    let _date = new Date(value);
    let _dateString = _date.toDateString();
    let _dateStringLength = _dateString.length;
    let _yearString = _dateString.substr(_dateStringLength - 4, 4);
    let _dayString = _date.getDate() < 10 ? "0" + _date.getDate() : String(_date.getDate());
    let _monthString = (_date.getMonth() + 1) < 10 ? "0" + (_date.getMonth() + 1) : String(_date.getMonth() + 1);
    let _monthDisplayString = _dateString.substr(4, 3);

    let returnDateValue = _yearString + "-" + _monthString + "-" + _dayString;
    let returnDateDisplayValue = _dayString + "/" + _monthDisplayString + "/" + _yearString;

    return {
        value : returnDateValue,
        displayValue: returnDateDisplayValue
    }
}


const NativeDatePicker = (props) => {
    const [value, setValue] = useState("");
    const [displayValue, setDisplayValue] = useState("");
    // useEffect(() => {
    //     let result = formatDate(props.value);
    //     setValue(result.value);
    //     setDisplayValue(result.displayValue);
    // }, []);

    useEffect(() => {
        let result = formatDate(props.value);
        setValue(result.value);
        setDisplayValue(result.displayValue);
    }, [props.value]);

    return(
        <div className="datePickerContainer">
            <label className="datePickerLabel" for={props.id}>{displayValue}<CalendarIcon /></label>
            <Input
                className="datePicker"
                type="date"
                value={value}
                defaultValue={value}
                onChange={ (e) => props.onChange(formatDate(e.target.value).value)}
                id={props.id}
            />
        </div>
    )
}

export default NativeDatePicker;