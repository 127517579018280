import { useState, useEffect } from "react";
import "./dashboard.scss";
import _ from "lodash";
import { useHistory } from "react-router-dom";
import {
  Flex,
  Dialog,
  Text,
  Button,
  Form,
  Loader,
  Segment,
  Checkbox,
  FlexItem,
} from "@fluentui/react-northstar";

import {
  AddIcon,
  OpenOutsideIcon,
  TrashCanIcon,
  EditIcon,
} from "@fluentui/react-icons-northstar";

import {
  Grid,
  GridColumn as Column,
  GridNoRecords,
} from "@progress/kendo-react-grid";
import DashboardApiService from "../../../services/DashboardApiService";
import AuthHelper from "../../../services//auth-helper";
import CenteredHeader from "../../Shared/Header/Header";
import WindowContext from "../../Shared/Context/Context";

const EditDashboardCard = ({
  dashboardName,
  dashboardId,
  submitHandler,
  cancelHandler,
}) => {
  const [dashName, setDashName] = useState(dashboardName);
  const [dashNameInput, setDashNameInput] = useState(dashboardName);
  const dashboardSubmitHandler = () => {
    submitHandler(dashboardId, dashNameInput);
  };
  return (
    <Form onSubmit={() => dashboardSubmitHandler()} className="dashCardForm">
      <Form.Input
        style={{ width: "100%" }}
        label="Dashboard Name"
        defaultValue={dashName}
        value={dashNameInput}
        autoFocus
        onChange={(e, { name, value }) => {
          setDashNameInput(value);
        }}
        className="mb-0"
        name={`dashboard${dashboardId}`}
        required
      />
      <Flex
        style={{
          marginTop: "30px",
          flexDirection: "row-reverse",
          justifyContent: "flex-start",
        }}
      >
        <Flex.Item>
          <Form.Button
            style={{ marginLeft: "0.625rem", marginRight: "0" }}
            primary
            className="mt-0 btnFormAction"
            content="Save"
          />
        </Flex.Item>
        <Form.Button
          className="mt-0 btnFormAction"
          content="Cancel"
          onClick={() => cancelHandler()}
        />
      </Flex>
    </Form>
  );
};

const removeFromLS = (key) => {
  if (global.localStorage) {
    global.localStorage.removeItem("layout" + key);
  }
};

const CellWithActionIcon = (props) => {
  const data = props.dataItem;
  const [deleting, setDeleting] = useState(false);
  return (
    <WindowContext.Consumer>
      {(context) => (
        <td>
          <Flex hAlign="center" gap="gap.small">
            <Button
              disabled={deleting}
              icon={<OpenOutsideIcon />}
              iconOnly={context.mediaCategory.sm}
              content={context.mediaCategory.sm ? "" : "Open"}
              size="small"
              primary
              onClick={() => props.navClickHandler(data.userDashboardsID)}
            />
            <Button
              icon={<EditIcon />}
              disabled={deleting}
              iconOnly={context.mediaCategory.sm}
              content={context.mediaCategory.sm ? "" : "Edit"}
              size="small"
              onClick={() => {
                props.handleEdit(data);
              }}
            />
            <Button
              className="btnDanger"
              iconOnly={context.mediaCategory.sm}
              content={context.mediaCategory.sm ? "" : "Delete"}
              size="small"
              icon={<TrashCanIcon />}
              disabled={deleting}
              loading={deleting}
              onClick={() => {
                setDeleting(true);
                props.handleDelete(data.userDashboardsID);
              }}
            />
          </Flex>
        </td>
      )}
    </WindowContext.Consumer>
  );
};

const DashHome = (props) => {
  const [dashboardList, setDashboardList] = useState(null);
  const [demoDashboardList, setDemoDashboardList] = useState(null);

  const [loaded, setLoaded] = useState(false);
  const [isDemo, setIsDemo] = useState(false);
  const [demoMode, setDemoMode] = useState(false);
  const [isEdit, setEdit] = useState(false);
  const [isDemoEdit, setDemoEdit] = useState(false);
  const [demoModeVisible, setDemoModeVisible] = useState(false);

  const [editDashboardDetail, setEditDashboardDetail] = useState(null);
  const [editDemoDashboardDetail, setEditDemoDashboardDetail] = useState(null);

  const [open, setOpen] = useState(false);
  const [demoOpen, setDemoOpen] = useState(false);

  const history = useHistory();

  const handleNavigationClick = (param) => {
    history.push("/dashboards/view/" + param);

    if (global.localStorage) {
      global.localStorage.setItem(
        "contentUrl_dashboards",
        "/dashboards/view/" + param
      );
    }
  };

  useEffect(() => {
    AuthHelper.getAccessToken(function (token) {
      DashboardApiService.GetDashboards(token).then((response) => {
        let demoDashboards = [];
        let dashboards = [];

        response.forEach((e) => {
          if (e.isDemo === true) {
            demoDashboards.push(e);
          }
          if (e.isDemo === false) {
            dashboards.push(e);
          }
        });
        setDemoDashboardList(demoDashboards);

        setDashboardList(dashboards);

        setLoaded(true);
      });
    });
    setDemoModeVisible(props.demoModeVisible);
  }, []);

  useEffect(() => {
    let demo = localStorage.getItem("demo");
    if (demo === "true") {
      setDemoMode(true);
    } else {
      setDemoMode(false);
    }
  }, []);

  const handleUpdateDashboardClick = (dataItem) => {
    setEditDashboardDetail(dataItem);
    setEdit(true);
    setOpen(true);
  };

  const handleUpdateDemoDashboardClick = (dataItem) => {
    setEditDemoDashboardDetail(dataItem);
    setDemoEdit(true);
    setDemoOpen(true);
  };

  const deleteDashboard = (dashboardID) => {
    if (demoMode) {
      AuthHelper.getAccessToken(function (token) {
        DashboardApiService.DeleteDashboard(dashboardID, token).then(
          (response) => {
            if (response) {
              let d = demoDashboardList.filter(
                (item) => item.userDashboardsID !== dashboardID
              );
              setDemoDashboardList(d);
              removeFromLS(dashboardID);
            }
          }
        );
      });
    } else {
      AuthHelper.getAccessToken(function (token) {
        DashboardApiService.DeleteDashboard(dashboardID, token).then(
          (response) => {
            if (response) {
              let d = dashboardList.filter(
                (item) => item.userDashboardsID !== dashboardID
              );
              setDashboardList(d);
              removeFromLS(dashboardID);
            }
          }
        );
      });
    }
  };

  const updateDashboard = (_userDashboardId, _dashboardName) => {
    if (demoMode) {
      AuthHelper.getAccessToken(function (token) {
        DashboardApiService.UpdateDashboard(
          _userDashboardId,
          _dashboardName,
          token
        ).then((response) => {
          let index = demoDashboardList.findIndex(
            (item) => item.userDashboardsID === _userDashboardId
          );
          if (index !== -1) {
            let list = demoDashboardList;
            list[index].name = _dashboardName;
            setDemoDashboardList(list);
          }
          setDemoOpen(false);
          setDemoEdit(false);
          setEditDemoDashboardDetail(null);
        });
      });
    } else {
      AuthHelper.getAccessToken(function (token) {
        DashboardApiService.UpdateDashboard(
          _userDashboardId,
          _dashboardName,
          token
        ).then((response) => {
          let index = dashboardList.findIndex(
            (item) => item.userDashboardsID === _userDashboardId
          );
          if (index !== -1) {
            let list = dashboardList;
            list[index].name = _dashboardName;
            setDashboardList(list);
          }
          setOpen(false);
          setEdit(false);
          setEditDashboardDetail(null);
        });
      });
    }
  };

  const onCancel = () => {
    setOpen(false);
    setDemoOpen(false);
  };
  const onOpen = () => {
    setOpen(true);
  };
  const onDemoModeOpen = () => {
    setDemoOpen(true);
  };

  const addDashboard = (_dashId, _dashName) => {
    AuthHelper.getAccessToken(function (token) {
      DashboardApiService.AddDashboard(_dashName, token).then((response) => {
        if (response) {
          //response.isEdit = true;
          setDashboardList([...dashboardList, response]);
        }
        setOpen(false);
      });
    });
  };

  const addDemoDashboard = (_dashId, _dashName) => {
    AuthHelper.getAccessToken(function (token) {
      DashboardApiService.AddDashboard(_dashName, token, demoMode).then(
        (response) => {
          if (response) {
            //response.isEdit = true;
            setDemoDashboardList([...demoDashboardList, response]);
          }
          setDemoOpen(false);
        }
      );
    });
  };

  const AddCardCell = (props) => {
    return (
      <CellWithActionIcon
        {...props}
        navClickHandler={handleNavigationClick}
        handleDelete={deleteDashboard}
        handleEdit={
          isDemo ? handleUpdateDemoDashboardClick : handleUpdateDashboardClick
        }
      />
    );
  };

  const handleDemoMode = (isChecked) => {
    setDemoMode(isChecked.checked);
    setIsDemo(isChecked.checked);
  };

  return (
    <WindowContext.Consumer>
      {(context) => (
        <>
          <Flex
            gap="gap.small"
            className="mb-3"
            styles={{
              alignItems: "center",
              justifyContent: "space-between",
              padding: "0 8px",
            }}
          >
            <Text content="View My Dashboards" size="large" />
            <div className="demoModeArea">
              {demoModeVisible && (
                <div className="demoMode">
                  <Text content="Demo Mode:" size="medium" />
                  <Checkbox
                    toggle
                    checked={demoMode}
                    onClick={(event, isChecked) => {
                      handleDemoMode(isChecked);
                    }}
                  />
                </div>
              )}
              <Button
                icon={<AddIcon />}
                content="New Dashboard"
                primary
                onClick={() => {
                  setEdit(false);
                  setDemoEdit(false);
                  if (demoMode) {
                    setDemoOpen(true);
                  } else {
                    setOpen(true);
                  }
                }}
              />
            </div>
          </Flex>
          {demoMode && demoModeVisible ? (
            <Grid
              style={{ maxHeight: "calc(100vh - 85px)", padding: "0 8px" }}
              data={demoDashboardList}
            >
              <GridNoRecords>
                {demoDashboardList != null ? (
                  <Flex
                    fill
                    className="default-segment-container"
                    hAlign="center"
                    vAlign="center"
                  >
                    <Segment className="default-segment">
                      <Flex
                        gap="gap.small"
                        column
                        hAlign="center"
                        vAlign="center"
                      >
                        <Text content="You have not yet created a dashboard." />
                        <Button
                          icon={<AddIcon />}
                          content="New Dashboard"
                          primary
                          onClick={() => {
                            setEdit(false);
                            setDemoOpen(true);
                          }}
                        />
                      </Flex>
                    </Segment>
                  </Flex>
                ) : (
                  <Loader />
                )}
              </GridNoRecords>

              <Column
                width={
                  context.mediaCategory.sm
                    ? "calc(100% - 95px)"
                    : "calc(100% - 265px)"
                }
                field="name"
                title="Name"
              />
              <Column
                width={context.mediaCategory.sm ? "95px" : "265px"}
                title="Actions"
                cell={AddCardCell}
                headerCell={CenteredHeader}
              />
            </Grid>
          ) : (
            <Grid
              style={{ maxHeight: "calc(100vh - 85px)", padding: "0 8px" }}
              data={dashboardList}
            >
              <GridNoRecords>
                {dashboardList != null ? (
                  <Flex
                    fill
                    className="default-segment-container"
                    hAlign="center"
                    vAlign="center"
                  >
                    <Segment className="default-segment">
                      <Flex
                        gap="gap.small"
                        column
                        hAlign="center"
                        vAlign="center"
                      >
                        <Text content="You have not yet created a dashboard." />
                        <Button
                          icon={<AddIcon />}
                          content="New Dashboard"
                          primary
                          onClick={() => {
                            setEdit(false);
                            setOpen(true);
                          }}
                        />
                      </Flex>
                    </Segment>
                  </Flex>
                ) : (
                  <Loader />
                )}
              </GridNoRecords>
              <Column
                width={
                  context.mediaCategory.sm
                    ? "calc(100% - 95px)"
                    : "calc(100% - 265px)"
                }
                field="name"
                title="Name"
              />
              <Column
                width={context.mediaCategory.sm ? "95px" : "265px"}
                title="Actions"
                cell={AddCardCell}
                headerCell={CenteredHeader}
              />
            </Grid>
          )}

          {demoMode && demoModeVisible ? (
            <Dialog
              className="editDashboardDialog"
              closeOnOutsideClick={false}
              defaultOpen={demoOpen}
              open={demoOpen}
              onOpen={onDemoModeOpen}
              onCancel={onCancel}
              header={
                isDemoEdit ? editDemoDashboardDetail.name : "New Demo Dashboard"
              }
              content={
                <EditDashboardCard
                  dashboardName={
                    isDemoEdit
                      ? editDemoDashboardDetail.name
                      : "My Demo Dashboard"
                  }
                  dashboardId={
                    isDemoEdit ? editDemoDashboardDetail.userDashboardsID : -1
                  }
                  submitHandler={
                    isDemoEdit ? updateDashboard : addDemoDashboard
                  }
                  cancelHandler={onCancel}
                />
              }
            />
          ) : (
            <Dialog
              className="editDashboardDialog"
              closeOnOutsideClick={false}
              defaultOpen={open}
              open={open}
              onOpen={onOpen}
              onCancel={onCancel}
              header={isEdit ? editDashboardDetail.name : "New Dashboard"}
              content={
                <EditDashboardCard
                  dashboardName={
                    isEdit ? editDashboardDetail.name : "My Dashboard"
                  }
                  dashboardId={
                    isEdit ? editDashboardDetail.userDashboardsID : -1
                  }
                  submitHandler={isEdit ? updateDashboard : addDashboard}
                  cancelHandler={onCancel}
                />
              }
            />
          )}
        </>
      )}
    </WindowContext.Consumer>
  );
};

export default DashHome;
