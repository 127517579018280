import { AllWidgets } from "../../../pages/Dashboard/allwidgets";

export const cardFilters = {
  1: {
    cardFilterID: 1,
    description: "Collaboration",
    date: 1,
    time: 1,
    teams: 2,
    groups: 2,
    users: 2,
    callQuality: 1,
    sentiment: 1,
    reactions: 1,
    modalities: 1,
    messages: 1,
  },
  2: {
    cardFilterID: 2,
    description: "Calls",
    date: 1,
    time: 1,
    teams: 2,
    groups: 2,
    users: 2,
    callQuality: 1,
    sentiment: 0,
    reactions: 0,
    modalities: 1,
    messages: 0,
  },
  3: {
    cardFilterID: 3,
    description: "Messages",
    date: 1,
    time: 1,
    teams: 2,
    groups: 2,
    users: 2,
    callQuality: 0,
    sentiment: 1,
    reactions: 1,
    modalities: 0,
    messages: 1,
  },
  4: {
    cardFilterID: 4,
    description: "UserToUser",
    date: 1,
    time: 1,
    teams: 0,
    groups: 0,
    users: 3,
    callQuality: 0,
    sentiment: 0,
    reactions: 0,
    modalities: 0,
    messages: 0,
  },
  5: {
    cardFilterID: 5,
    description: "TeamToTeam",
    date: 1,
    time: 1,
    teams: 3,
    groups: 0,
    users: 0,
    callQuality: 0,
    sentiment: 0,
    reactions: 0,
    modalities: 0,
    messages: 0,
  },
  6: {
    cardFilterID: 6,
    description: "DeptToDept",
    date: 1,
    time: 1,
    teams: 0,
    groups: 0,
    users: 0,
    callQuality: 0,
    sentiment: 0,
    reactions: 0,
    modalities: 0,
    messages: 0,
  },
  7: {
    cardFilterID: 7,
    description: "TopDept",
    date: 1,
    time: 1,
    teams: 0,
    groups: 0,
    users: 0,
    callQuality: 0,
    sentiment: 0,
    reactions: 0,
    modalities: 0,
    messages: 0,
  },
  8: {
    cardFilterID: 8,
    description: "TopDiv",
    date: 1,
    time: 1,
    teams: 0,
    groups: 0,
    users: 0,
    callQuality: 0,
    sentiment: 0,
    reactions: 0,
    modalities: 0,
    messages: 0,
  },
  9: {
    cardFilterID: 9,
    description: "TopCC",
    date: 1,
    time: 1,
    teams: 0,
    groups: 0,
    users: 0,
    callQuality: 0,
    sentiment: 0,
    reactions: 0,
    modalities: 0,
    messages: 0,
  },
  10: {
    cardFilterID: 10,
    description: "TopTeam",
    date: 1,
    time: 1,
    teams: 2,
    groups: 0,
    users: 0,
    callQuality: 0,
    sentiment: 0,
    reactions: 0,
    modalities: 0,
    messages: 0,
  },
  11: {
    cardFilterID: 11,
    description: "TeamMessageSummary",
    date: 1,
    time: 1,
    teams: 2,
    groups: 0,
    users: 0,
    callQuality: 0,
    sentiment: 1,
    reactions: 1,
    modalities: 0,
    messages: 1,
  },
  12: {
    cardFilterID: 12,
    description: "TeamCollaboration",
    date: 1,
    time: 1,
    teams: 2,
    groups: 0,
    users: 0,
    callQuality: 1,
    sentiment: 1,
    reactions: 1,
    modalities: 1,
    messages: 1,
  },
  13: {
    cardFilterID: 13,
    description: "ChatAndCallTrends",
    date: 1,
    time: 1,
    teams: 2,
    groups: 2,
    users: 2,
    callQuality: 1,
    sentiment: 1,
    reactions: 1,
    modalities: 1,
    messages: 1,
  },
  14: {
    cardFilterID: 14,
    description: "CallNonTeams",
    date: 1,
    time: 1,
    teams: 0,
    groups: 2,
    users: 2,
    callQuality: 1,
    sentiment: 0,
    reactions: 0,
    modalities: 1,
    messages: 0,
  },
  15: {
    cardFilterID: 3,
    description: "MessagesReaction",
    date: 1,
    time: 1,
    teams: 2,
    groups: 2,
    users: 2,
    callQuality: 0,
    sentiment: 1,
    reactions: 0,
    modalities: 0,
    messages: 1,
  },
  16: {
    cardFilterID: 16,
    description: "CallOnlyTeams",
    date: 1,
    time: 1,
    teams: 2,
    groups: 0,
    users: 0,
    callQuality: 1,
    sentiment: 0,
    reactions: 0,
    modalities: 1,
    messages: 0,
  },
  17: {
    cardFilterID: 17,
    description: "CollaborationNonTeams",
    date: 1,
    time: 1,
    teams: 0,
    groups: 2,
    users: 2,
    callQuality: 1,
    sentiment: 1,
    reactions: 1,
    modalities: 1,
    messages: 1,
  },
  18: {
    cardFilterID: 18,
    description: "CollaborationOnlyTeams",
    date: 1,
    time: 1,
    teams: 2,
    groups: 0,
    users: 0,
    callQuality: 1,
    sentiment: 1,
    reactions: 1,
    modalities: 1,
    messages: 1,
  },
  19: {
    cardFilterID: 19,
    description: "MessageOnlyGroups",
    date: 1,
    time: 1,
    teams: 0,
    groups: 2,
    users: 0,
    callQuality: 0,
    sentiment: 1,
    reactions: 1,
    modalities: 0,
    messages: 1,
  },
  20: {
    cardFilterID: 20,
    description: "CallOnlyGroups",
    date: 1,
    time: 1,
    teams: 0,
    groups: 2,
    users: 0,
    callQuality: 1,
    sentiment: 0,
    reactions: 0,
    modalities: 1,
    messages: 0,
  },
  21: {
    cardFilterID: 21,
    description: "User Summary",
    date: 1,
    time: 1,
    teams: 0,
    groups: 0,
    users: 1,
    callQuality: 1,
    sentiment: 1,
    reactions: 1,
    modalities: 1,
    messages: 1,
  },
  22: {
    cardFilterID: 22,
    description: "Platform Summary",
    date: 1,
    time: 1,
    teams: 2,
    groups: 2,
    users: 2,
    callQuality: 0,
    sentiment: 0,
    reactions: 0,
    modalities: 0,
    messages: 0,
  }
};

export const sentimentTypes = [
  {
    sentimentAnalysisTypeID: 0,
    description: "Positive",
  },
  {
    sentimentAnalysisTypeID: 1,
    description: "Neutral",
  },
  {
    sentimentAnalysisTypeID: 2,
    description: "Negative",
  },
];

export const reactionTypes = [
  {
    reactionTypeID: 0,
    Description: "Like",
  },
  {
    reactionTypeID: 1,
    Description: "Love",
  },
  {
    reactionTypeID: 2,
    Description: "Laugh",
  },
  {
    reactionTypeID: 3,
    Description: "Surprise",
  },
  {
    reactionTypeID: 4,
    Description: "Sad",
  },
  {
    reactionTypeID: 5,
    Description: "Angry",
  },
  {
    reactionTypeID: 6,
    Description: " No Reaction",
  },
];

export const callQualityTypes = [
  {
    callQualityID: 0,
    Description: "Good",
  },
  {
    callQualityID: 1,
    Description: "Average",
  },
  {
    callQualityID: 2,
    Description: "Poor",
  },
];

export const modalityTypes = [
  {
    modalityID: 0,
    modalityName: "Audio",
  },
  {
    modalityID: 1,
    modalityName: "Video",
  },
  {
    modalityID: 2,
    modalityName: "Screen Share",
  },
];

export const FilterTypes = [
  { value: 0, header: "People", isApplicable: false },
  { value: 1, header: "Team", isApplicable: false },
  { value: 2, header: "Group", isApplicable: false },
];

export const WIDGETSNAMEMAP = () => {
  let widgetNameMap = new Map();
  AllWidgets.forEach((obj) => {
    widgetNameMap.set(obj.cardWidgetID, obj.widgetName);
  });
  return widgetNameMap;
};

export const WIDGETSWITHFILTERS = AllWidgets.map((obj) => {
  return {
    ...obj,
    ...cardFilters[obj.cardFilterID],
    checked: false,
  };
});
