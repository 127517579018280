import React, { useState, useEffect, Fragment } from "react";
// import { TimePicker } from "@progress/kendo-react-dateinputs";
import {
  Grid,
  GridColumn as Column,
  GridNoRecords,
} from "@progress/kendo-react-grid";
import {
  AcceptIcon,
  ExclamationTriangleIcon,
} from "@fluentui/react-icons-northstar";

import { getter } from "@progress/kendo-react-common";

import {
  Form,
  Loader,
  Flex,
  Dialog as FluentDialog,
  Button,
  Text,
  Input,
} from "@fluentui/react-northstar";

import {
  CellWithTypeIcon,
  CellWithCategoryIcon,
} from "../../../pages/CustomCells/CustomCells";

import FiltersApiService from "../../../../services/FiltersApiService";
import AuthHelper from "../../../../services/auth-helper";
import {
  FilterTypes,
  WIDGETSNAMEMAP,
  WIDGETSWITHFILTERS,
} from "../CardFiltersData/CardFiltersData";
import { Dialog } from "@progress/kendo-react-dialogs";
import { WidgetPreview } from "../../../cards/Card/Card";
import CenteredHeader from "../../../Shared/Header/Header";
import "./MultiCardSettings.scss";
import WindowContext from "../../../Shared/Context/Context";
import TeamsTimePicker from "../../../Shared/uicomponents/TeamsTimePicker";
import NativeTimePicker from "../../../Shared/uicomponents/NativeTimePicker";

const DATA_ITEM_KEY = "cardWidgetID";
const SELECTED_FIELD = "checked";
const idGetter = getter(DATA_ITEM_KEY);

const CellWithViewIcon = (props) => {
  const data = props.dataItem;
  return (
    <Button
      // title="Preview"
      onClick={() => props.handleExpandChange(data)}
      className="widget_category_container"
      text
      iconOnly
      content={
        <>
          {data.categoryTypeID === 1 && (
            <i title="Summary" className={"analytics-summary"} />
          )}
          {data.categoryTypeID === 2 && (
            <i title="Activity" className={"analytics-activity2"} />
          )}
          {data.categoryTypeID === 3 && (
            <i title="Trends" className={"analytics-trends"} />
          )}
          {data.categoryTypeID === 4 && (
            <i title="Comparison" className={"analytics-comparison2"} />
          )}
          {data.categoryTypeID === 5 && (
            <i title="Insight" className={"analytics-insights"} />
          )}
        </>
      }
    />
  );
};

const DetailComponent = (props) => {
  const dataItem = props.dataItem;
  return (
    <section style={{ paddingRight: "32px" }}>
      <WidgetPreview
        widgetID={dataItem.cardWidgetID}
        viewTypeID={dataItem.cardViewTypeID}
      />
    </section>
  );
};

const MultiCardSettings = (props) => {
  const [activeIndex, setActiveIndex] = useState(0);
  const [dashboardId, setDashboardId] = useState(props.dashboardId);
  const [loaded, setLoaded] = useState(false);
  const [filterData, setFilterData] = useState(props.userFiltersData);
  const [submitButtonState, setSubmitButtonState] = useState(0);

  const [date, setDate] = useState(1);
  const [time, setTime] = useState(0);
  const [startTime, setStartTime] = useState("");
  const [endTime, setEndTime] = useState("");
  const [teams, setTeams] = useState([]);
  const [groups, setGroups] = useState([]);
  const [users, setUsers] = useState([]);

  const [dateItems, setDateItems] = useState([]);
  const [timeItems, setTimeItems] = useState([]);
  const [filterTypes, setFilterTypes] = useState([]);
  const [filterTypeId, setFilterTypeId] = useState(0);
  const [filterType, setFilterType] = useState(FilterTypes[0]);
  const [isValid, setIsValid] = useState(false);
  const [teamIsGroup, setTeamIsGroup] = useState(false);
  const [teamIsGroupDisabled, setTeamIsGroupDisabled] = useState(false);

  const [expandPeople, setExpandPeople] = useState(0);
  const [peopleMasKList, setPeopleMaskList] = useState([]);

  const [widgetNameMap, setWidgetNameMap] = useState(null);
  const [selectedState, setSelectedState] = useState({});
  const [filterProperty, setFilterProperty] = useState("users");
  const [filterValue, setFilterValue] = useState(0);
  const [widgets, setWidgets] = useState([]);
  const [isSaveEnabled, setIsSaveEnabled] = useState(false);
  const [openPreview, setOpenPreview] = useState(false);
  const [previewObj, setPreviewObj] = useState(null);
  const [checkedItems, setCheckedItems] = useState(new Map());
  const [open, setOpen] = useState(false);
  const [dropDownOptions, setDropDownOptions] = useState([]);

  useEffect(() => {
    setWidgetNameMap(WIDGETSNAMEMAP);
    setFilterData(props.userFiltersData);
    setStates(props.userFiltersData);
    setPeopleOptions(props.userFiltersData);
  }, []);

  useEffect(() => {
    if (activeIndex === 1) {
      //setWidgets(WIDGETSWITHFILTERS);
      setLoaded(true);
    } else {
      setLoaded(false);
    }
  }, [activeIndex]);

  const selectionChange = (event) => {
    const checked = event.syntheticEvent.target.checked;
    const dataItemKey = idGetter(event.dataItem);

    setSelectedState({ ...selectedState, [dataItemKey]: checked });
    setCheckedItems(checkedItems.set(dataItemKey, checked));

    let checkedArr = [];
    let unCheckedArr = [];

    for (let [key, value] of checkedItems.entries()) {
      if (value === true) {
        checkedArr.push(key);
      } else {
        unCheckedArr.push(key);
      }
    }

    if (checkedArr.length >= 1) {
      setIsSaveEnabled(true);
    } else {
      setIsSaveEnabled(false);
    }
  };

  const headerSelectionChange = (event) => {
    const checked = event.syntheticEvent.target.checked;
    const newSelectedState = {};
    widgets.forEach((item) => {
      newSelectedState[idGetter(item)] = checked;
      setCheckedItems(checkedItems.set(idGetter(item), checked));
    });
    setIsSaveEnabled(checked);
    setSelectedState(newSelectedState);

    let checkedArr = [];
    let unCheckedArr = [];

    for (let [key, value] of checkedItems.entries()) {
      if (value === true) {
        checkedArr.push(key);
      } else {
        unCheckedArr.push(key);
      }
    }

    if (checkedArr.length >= 1) {
      setIsSaveEnabled(true);
    } else {
      setIsSaveEnabled(false);
    }
  };

  const handleExpandChangeOnTypeCell = (dataItem) => {
    let newData = widgets.map((item) => {
      if (item.widgetName === dataItem.widgetName) {
        item.expanded = !dataItem.expanded;
      }
      return item;
    });
    setWidgets(newData);
  };

  const checkValid = (_filterType, _selectCount) => {
    if (_filterType === 0) {
      return _selectCount >= 1 ? true : false;
    } else if (_filterType === 1) {
      return _selectCount >= 1 ? true : false;
    } else if (_filterType === 2) {
      return _selectCount >= 1 ? true : false;
    }
  };

  const setPeopleOptions = (_userFilterData) => {
    let peopleOptions = _userFilterData.userFilter.items.map((e) => e);
    let directReporteeArr = [];
    peopleOptions.slice(2).forEach((el) => {
      if (el.isDirectReportee === false) {
        directReporteeArr.push(el);
      }
    });

    if (directReporteeArr.length === peopleOptions.slice(2).length) {
      let updateArr = peopleOptions.filter((el) => el.key !== -2);
      setDropDownOptions(updateArr);
    } else {
      setDropDownOptions(peopleOptions);
    }
  };

  //Initial setup
  const setStates = (_filterData) => {
    setSubmitButtonState(0);
    setExpandPeople(0);
    // setPeopleMaskList([]);
    setFilterTypes([]);
    setFilterTypeId(0);
    setFilterType(FilterTypes[0]);
    let settingsData = {
      runPeriod: 3,
      timePeriod: 0,
      selectedStartTime: "00:00",
      selectedEndTime: "23:59",
    };
    setDate(settingsData.runPeriod);
    setTime(settingsData.timePeriod);
    setStartTime(
      settingsData.selectedStartTime !== ""
        ? settingsData.selectedStartTime
        : "00:00"
    );
    setEndTime(
      settingsData.selectedEndTime !== ""
        ? settingsData.selectedEndTime
        : "23:59"
    );
    setFilterTypes(FilterTypes);
    setDateItems(_filterData.dateFilter.items);
    setTimeItems(_filterData.timeFilter.items);

    // setLoaded(true);
  };

  const handleSubmit = () => {
    setSubmitButtonState(1);
    let widgetIdArr = [];
    let nameArr = [];
    Object.keys(selectedState).forEach((key) => {
      if (selectedState[key] === true) {
        let widgetId = Number(key);
        widgetIdArr.push(widgetId);
        nameArr.push(widgetNameMap.get(widgetId));
      }
    });

    let settingsData = {
      cardId: -1,
      Name: nameArr.toString(),
      dashboardId: dashboardId,
      widgetIdList: widgetIdArr.toString(),
      runPeriod: date,
      timePeriod: time,
      selectedStartTime: time === 2 ? startTime : "",
      selectedEndTime: time === 2 ? endTime : "",
      TeamFilter:
        filterTypeId === 1 ? teams.map((d) => d.value).toString() : "",
      teamIsGroup : filterTypeId === 1 ? teamIsGroup : false,
      GroupFilter:
        filterTypeId === 2 ? groups.map((d) => d.value).toString() : "",
      userFilter:
        filterTypeId === 0 ? users.map((d) => d.value).toString() : "",
        callQualityFilter: "0,1,2",
        sentimentFilter: "0,1,2",
        reactionFilter: "0,1,2,3,4,5,6",
        modalitiesFilter: "0,1,2",
      messagesFilter: false,
      xPosition: 0,
      yPosition: 0,
    };

    AuthHelper.getAccessToken(function (token) {
      FiltersApiService.AddCardWithMultipleFilters(settingsData, token)
        .then((response) => {
          if (response) {
            setSubmitButtonState(2);
            props.submitHandler();
          } else {
            setSubmitButtonState(3);
          }
        })
        .finally(() => {
          setTimeout(function () {
            setSubmitButtonState(0);
          }, 1000);
        });
    });
  };

  const handleFilterTypeChange = (value) => {
    setFilterTypeId(value);
    setFilterProperty(value === 0 ? "users" : value === 1 ? "teams" : "groups");
    if (value === 2) {
      setFilterProperty("groups");
      setFilterValue(groups.length === 2 ? 3 : groups.length > 1 ? 2 : 1);
      setIsValid(checkValid(2, groups.length));
    } else if (value === 1) {
      setFilterProperty("teams");
      setFilterValue(teams.length === 2 ? 3 : teams.length > 1 ? 2 : 1);
      setIsValid(checkValid(1, teams.length));
    } else {
      setFilterProperty("users");
      setFilterValue(
        users.some((v) => v.key < 0)
          ? 2
          : users.length === 2
          ? 3
          : users.length > 1
          ? 2
          : 1
      );
      setIsValid(checkValid(0, users.length));
    }
  };

  const handleTeamChange = (value) => {
    let checkTeamIsGroup = value.filter(el => el.teamIsGroup === false);
    if(checkTeamIsGroup.length === 0){
      setTeamIsGroupDisabled(false)
    }else{
      setTeamIsGroupDisabled(true)
      setTeamIsGroup(false)
    }
    setTeams(value);
    setFilterProperty("teams");
    setFilterValue(value.length === 2 ? 3 : value.length > 1 ? 2 : 1);
    setIsValid(checkValid(1, value.length));
  };

  const handleGroupChange = (value) => {
    setGroups(value);
    setFilterProperty("groups");
    setFilterValue(value.length === 2 ? 3 : value.length > 1 ? 2 : 1);
    setIsValid(checkValid(2, value.length));
  };
  
  const handleUserChange = (value) => {
    setUsers(value);
    setFilterProperty("users");
    setFilterValue(
      value.some((v) => v.key < 0)
        ? 2
        : value.length === 2
        ? 3
        : value.length > 1
        ? 2
        : 1
    );
    setIsValid(checkValid(0, value.length));
  };

  const onExpandCancel = () => {
    setExpandPeople(0);
  };

  const onExpandConfirm = () => {
    let currentSelection = users;
    currentSelection.splice(
      currentSelection.findIndex((cs) => cs.value === expandPeople),
      1
    );
    if (expandPeople === -1) {
      filterData.userFilter.items
        .filter((f) => f.key > 0)
        .forEach(function (obj, v) {
          if (currentSelection.findIndex((f) => f.value === obj.value) === -1) {
            currentSelection.push(obj);
          }
        });
      setPeopleMaskList([-1, -2]);
    } else if (expandPeople === -2) {
      filterData.userFilter.items
        .filter((f) => f.key > 0 && f.isDirectReportee === true)
        .forEach(function (obj, v) {
          if (currentSelection.findIndex((f) => f.value === obj.value) === -2) {
            currentSelection.push(obj);
          }
        });
      setPeopleMaskList([-2]);
    }
    setUsers(currentSelection);
    setExpandPeople(0);
  };

  const ViewCell = (props) => {
    return (
      <td className="text-center">
        <CellWithViewIcon
          {...props}
          handleExpandChange={(_prewiewObj) => {
            handleExpandChangeOnTypeCell(_prewiewObj);
          }}
        />
      </td>
    );
  };

  const onPrevious = () => {
    // setActiveIndex(activeIndex - 1);
    // setSelectedState({});
    if (isSaveEnabled === true) {
      setOpen(true);
    } else {
      setActiveIndex(activeIndex - 1);
      setSelectedState({});
    }
  };

  const onNext = () => { 
    let haveMyPeople = false;
    for(let i=0; i<users.length; i++){
      if(users[i].header === "My People" || users.length > 1)
        haveMyPeople = true;
    }
    let withoutMyPeople= [];
    if (filterValue === 3) {
      withoutMyPeople = WIDGETSWITHFILTERS.filter((f) => f[filterProperty == "teams" && teamIsGroup ? "groups" : filterProperty] !== 0);
      if(haveMyPeople){
        withoutMyPeople.splice(withoutMyPeople.findIndex((cs) => cs.cardWidgetID === 0), 1)
      } 
    } else {
      withoutMyPeople = WIDGETSWITHFILTERS.filter(((f) => f[filterProperty == "teams" && teamIsGroup ? "groups" : filterProperty] !== 0 
        && f[filterProperty == "teams" && teamIsGroup ? "groups" : filterProperty] !== 3));
      if(haveMyPeople){
        withoutMyPeople.splice(withoutMyPeople.findIndex((cs) => cs.cardWidgetID === 0), 1)
      } 
    }
    setWidgets(withoutMyPeople);
    console.log(withoutMyPeople)
    setIsSaveEnabled(false);
    setActiveIndex(activeIndex + 1);
  };

  const onConfirm = () => {
    setActiveIndex(activeIndex - 1);
    setOpen(false);
    setSelectedState({});
  };

  const onCancel = () => {
    setOpen(false);
  };

  return (
    <WindowContext.Consumer>
      {(context) => (
        <Fragment>
          <Form
            className="formCardSettings"
            style={{ overflow: "hidden", minHeight: "70vh" }}
          >
            <div
              className="multiCard-step-content-holder"
              style={{ transform: `translateX(-${activeIndex * 50}%)` }}
            >
              <div style={{ width: "100%", padding: "1em" }}>
                <div className="dropdownSection mb-3">
                  <Form.Dropdown
                    fluid
                    inline
                    label="Period"
                    items={dateItems}
                    defaultValue={
                      dateItems[
                        dateItems.map((e) => e.value).indexOf(date) === -1
                          ? 0
                          : dateItems.map((e) => e.value).indexOf(date)
                      ]
                    }
                    value={
                      dateItems[
                        dateItems.map((e) => e.value).indexOf(date) === -1
                          ? 0
                          : dateItems.map((e) => e.value).indexOf(date)
                      ]
                    }
                    onChange={(e, { value }) => {
                      setDate(value.value);
                    }}
                  />
                  <Form.Dropdown
                    fluid
                    inline
                    label="Time"
                    items={timeItems}
                    defaultValue={
                      timeItems[
                        timeItems.map((e) => e.value).indexOf(time) === -1
                          ? 0
                          : timeItems.map((e) => e.value).indexOf(time)
                      ]
                    }
                    value={
                      timeItems[
                        timeItems.map((e) => e.value).indexOf(time) === -1
                          ? 0
                          : timeItems.map((e) => e.value).indexOf(time)
                      ]
                    }
                    onChange={(e, { value }) => {
                      setTime(value.value);
                    }}
                  />
                  {time === 2 ? (
                    <>
                      <div></div>
                      <div className="custom-time-container">
                        <div>
                          <label>Start Time</label>
                          {
                            context.teams.hostClientType.web ||
                            context.teams.hostClientType.desktop ? (
                              <TeamsTimePicker
                                required={true}
                                value={startTime}
                                onChange={(value) => {
                                  setStartTime(value);
                                }}
                              />
                            ) : (
                              <NativeTimePicker
                                id="startTime"
                                value={startTime}
                                onChange={(value) => {
                                  setStartTime(value);
                                }}
                                required={true}
                              />
                            )
                            // <Form.Field
                            //   className="timePicker"
                            //   control = {{ as: Input, value: startTime, type: 'time',
                            //     onChange: (e)=>{setStartTime(e.target.value)}
                            //   }}
                            // />
                          }
                        </div>
                        <div>
                          <label>End Time</label>
                          {
                            context.teams.hostClientType.web ||
                            context.teams.hostClientType.desktop ? (
                              <TeamsTimePicker
                                required={true}
                                min={startTime}
                                value={endTime}
                                onChange={(value) => {
                                  setEndTime(value);
                                }}
                              />
                            ) : (
                              <NativeTimePicker
                                id="endTime"
                                min={startTime}
                                value={endTime}
                                onChange={(value) => {
                                  setEndTime(value);
                                }}
                                required={true}
                              />
                            )
                            // <Form.Field
                            //   className="timePicker"
                            //   control = {{ as: Input, value: endTime, type: 'time',
                            //     onChange: (e)=>{setEndTime(e.target.value)}
                            //   }}
                            // />
                          }
                        </div>
                      </div>
                    </>
                  ) : (
                    <></>
                  )}
                </div>
                <div className="dropdownSection mb-10 groupFilter">
                  <Form.Dropdown
                    fluid
                    inline
                    label="People/Team/Group"
                    items={filterTypes}
                    value={FilterTypes[filterTypeId]}
                    defaultValue={FilterTypes[filterTypeId]}
                    onChange={(e, { value }) => {
                      handleFilterTypeChange(value.value);
                    }}
                  />
                  {filterTypeId === 0 && (
                    <Form.Dropdown
                      fluid
                      inline
                      label="People"
                      search
                      multiple
                      autoSize
                      placeholder="Select People"
                      items={dropDownOptions.filter(
                        (uf) => !peopleMasKList.includes(uf.key)
                      )}
                      // items={filterData.userFilter.items.filter(
                      //   (uf) => !peopleMasKList.includes(uf.key)
                      // )}
                      defaultValue={[]}
                      value={users}
                      onChange={(e, { value }) => {
                        handleUserChange(value);
                      }}
                      noResultsMessage="We couldn't find any matches."
                      itemToValue={(obj) => {
                        return obj.key;
                      }}
                      renderSelectedItem={(Component, props) => {
                        if (props.key < 0) {
                          props.className = props.className + " _expandable";
                          props.onClick = (n, a) => {
                            if (
                              !n.currentTarget.classList.contains(
                                "ui-dropdown__selecteditem__icon"
                              )
                            ) {
                              setExpandPeople(props.key);
                            }
                          };
                        }
                        return <Component {...props} />;
                      }}
                    />
                  )}
                  {filterTypeId === 1 && (
                    <Form.Dropdown
                      fluid
                      inline
                      label="Teams"
                      search
                      multiple
                      autoSize
                      placeholder="Select Teams"
                      items={filterData.teamFilter.items}
                      defaultValue={teams}
                      value={teams}
                      itemToValue={(obj) => {
                        return obj.key;
                      }}
                      onChange={(e, { value }) => {
                        handleTeamChange(value);
                      }}
                      noResultsMessage="We couldn't find any matches."
                    />
                  )}
                  {filterTypeId === 2 && (
                    <Form.Dropdown
                      fluid
                      inline
                      label="Groups"
                      search
                      multiple
                      autoSize
                      placeholder="Select Groups"
                      items={filterData.groupFilter.items}
                      defaultValue={groups}
                      value={groups}
                      itemToValue={(obj) => {
                        return obj.key;
                      }}
                      onChange={(e, { value }) => {
                        handleGroupChange(value);
                      }}
                      noResultsMessage="We couldn't find any matches."
                    />
                  )}
                </div>
                <div className="dropdownSection mt-2 groupFilter">
                  <div className="mb-2">
                    {(filterTypeId === 1 && filterData.teamFilter.items.length === 0) ?
                      <Text>You do not have permission to any team. Please get team permissions in settings</Text>
                      : <></>
                    }
                    {(filterTypeId === 2 && filterData.groupFilter.items.length === 0) ?
                      <Text>You do not have permission to any group. Please get group permissions in settings</Text>
                      : <></>
                    }
                  </div>
                  <div className="mb-2">
                    {filterTypeId === 1 && 
                      <Form.Checkbox
                        label="Include reporting on activity outside selected Teams"
                        value={teamIsGroup}
                        checked={teamIsGroup}
                        disabled={teamIsGroupDisabled}
                        onChange={(e, { checked }) => {setTeamIsGroup(checked)}}
                      />
                    }
                  </div>
                </div>
              </div>
              {loaded && (
                <div style={{ width: "100%", padding: "1em" }}>
                  <Grid
                    data={
                      widgets != null
                        ? widgets.map((item) => ({
                            ...item,
                            [SELECTED_FIELD]: selectedState[idGetter(item)],
                          }))
                        : null
                    }
                    style={{
                      maxHeight: "100%",
                    }}
                    dataItemKey={DATA_ITEM_KEY}
                    selectedField={SELECTED_FIELD}
                    selectable={{
                      enabled: true,
                      drag: false,
                      cell: false,
                    }}
                    onSelectionChange={selectionChange}
                    onHeaderSelectionChange={headerSelectionChange}
                    detail={DetailComponent}
                    expandField="expanded"
                  >
                    <GridNoRecords>
                      {widgets != null ? (
                        "No cards found for the filters selected."
                      ) : (
                        <Loader />
                      )}
                    </GridNoRecords>
                    <Column
                      field="checked"
                      width="32px"
                      headerSelectionValue={
                        widgets.findIndex(
                          (item) => !selectedState[idGetter(item)]
                        ) === -1
                      }
                    />
                    <Column
                      field="widgetName"
                      title="Widget Name"
                      width={
                        context.mediaCategory.sm
                          ? "calc(100% - 172px)"
                          : "calc(100% - 302px)"
                      }
                    />
                    <Column
                      width="80px"
                      title="Category"
                      cell={CellWithCategoryIcon}
                      headerCell={CenteredHeader}
                    />
                    <Column
                      width="60px"
                      title="Preview"
                      cell={ViewCell}
                      headerCell={CenteredHeader}
                    />
                    {!context.mediaCategory.sm && (
                      <Column
                        width="130px"
                        title="Type"
                        cell={CellWithTypeIcon}
                      />
                    )}
                  </Grid>
                </div>
              )}
            </div>
            <Flex gap="gap.small" fill hAlign="end">
              {activeIndex === 0 ? (
                <Button
                  content="Cancel"
                  onClick={() => props.cancelHandler()}
                />
              ) : activeIndex !== 0 ? (
                <Button content="Back" onClick={onPrevious} />
              ) : (
                <></>
              )}
              {activeIndex === 0 ? (
                <Button
                  disabled={isValid === false}
                  primary
                  content="Next"
                  onClick={onNext}
                />
              ) : activeIndex === 1 ? (
                <Form.Button
                  disabled={isSaveEnabled === false || submitButtonState !== 0}
                  icon={submitButtonState === 2 && <AcceptIcon />}
                  loading={submitButtonState === 1}
                  content={
                    submitButtonState === 0
                      ? "Save"
                      : submitButtonState === 1
                      ? "Saving"
                      : submitButtonState === 2
                      ? "Saved"
                      : "Failed"
                  }
                  primary
                  onClick={() => handleSubmit()}
                />
              ) : (
                <></>
              )}
            </Flex>
          </Form>

          <FluentDialog
            style={{ maxWidth: "300px" }}
            backdrop={true}
            open={open}
            footer={{
              children: (Component, props) => {
                const { styles, ...rest } = props;
                return (
                  <Flex styles={styles} hAlign="center" gap="gap.small">
                    <Button size="small" content="Cancel" onClick={onCancel} />
                    <Button
                      size="small"
                      primary
                      content="Ok"
                      onClick={onConfirm}
                    />
                  </Flex>
                );
              },
            }}
            content={
              <>
                <Flex
                  fill
                  hAlign="center"
                  style={{ paddingBottom: "15px" }}
                  gap="gap.small"
                >
                  <ExclamationTriangleIcon
                    size="largest"
                    styles={{ color: "#fce100" }}
                  />
                  <Flex.Item>
                    <Text content="If you go back, your selection will be lost." />
                  </Flex.Item>
                </Flex>
              </>
            }
          />

          <FluentDialog
            style={{ maxWidth: "300px" }}
            backdrop={true}
            open={expandPeople < 0}
            footer={{
              children: (Component, props) => {
                const { styles, ...rest } = props;
                return (
                  <Flex styles={styles} hAlign="center" gap="gap.small">
                    <Button
                      size="small"
                      content="Cancel"
                      onClick={onExpandCancel}
                    />
                    <Button
                      size="small"
                      primary
                      content="Expand"
                      onClick={onExpandConfirm}
                    />
                  </Flex>
                );
              },
            }}
            content={
              <>
                <Flex
                  fill
                  hAlign="center"
                  style={{ paddingBottom: "15px" }}
                  gap="gap.small"
                >
                  <ExclamationTriangleIcon
                    size="largest"
                    styles={{ color: "#fce100" }}
                  />
                  <Flex.Item>
                    <Text content="If you expand the list, the list will be replaced by individual members. It is not possible to collapse the list again." />
                  </Flex.Item>
                </Flex>
              </>
            }
          />
          {openPreview && (
            <Dialog
              autoFocus={true}
              className="previewDialog"
              title={previewObj.widgetName}
              onClose={() => setOpenPreview(false)}
            >
              <WidgetPreview
                widgetID={previewObj.cardWidgetID}
                viewTypeID={previewObj.cardViewTypeID}
              />
            </Dialog>
          )}
        </Fragment>
      )}
    </WindowContext.Consumer>
  );
};

export default MultiCardSettings;
