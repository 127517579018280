import { useState, useEffect } from "react";
import MomentUtils from "@date-io/moment";
import { MuiPickersUtilsProvider, DatePicker } from "@material-ui/pickers";
import { CalendarIcon } from "@fluentui/react-icons-northstar";

import "./teamsdatepicker.scss";

const formatDate = (value) => {
  // API: yyyy-MM-dd
  // Display: dd/MMM/yyyy
  let _date = new Date(value);
  let _dateString = _date.toDateString();
  let _dateStringLength = _dateString.length;
  let _yearString = _dateString.substr(_dateStringLength - 4, 4);
  let _dayString =
    _date.getDate() < 10 ? "0" + _date.getDate() : String(_date.getDate());
  let _monthString =
    _date.getMonth() + 1 < 10
      ? "0" + (_date.getMonth() + 1)
      : String(_date.getMonth() + 1);
  let _monthDisplayString = _dateString.substr(4, 3);

  let returnDateValue = _yearString + "-" + _monthString + "-" + _dayString;
  let returnDateDisplayValue =
    _dayString + "/" + _monthDisplayString + "/" + _yearString;

  return {
    value: returnDateValue,
    displayValue: returnDateDisplayValue,
  };
};

const TeamsDatePicker = (props) => {
  const [value, setValue] = useState("");
  const [open, setOpen] = useState(false);
  useEffect(() => {
    let result = formatDate(props.value);
    setValue(result.value);
  }, [props.value]);

  return (
    <div className="teamsDatePicker">
      <MuiPickersUtilsProvider utils={MomentUtils}>
        <DatePicker
          open={open}
          onOpen={() => setOpen(true)}
          onClose={() => setOpen(false)}
          disableToolbar
          variant="inline"
          format="DD/MMM/YYYY"
          value={value}
          onChange={(e) => {
            props.onChange(formatDate(e._d).value);
          }}
          autoOk
        />
      </MuiPickersUtilsProvider>      
      <CalendarIcon className="calender-icon" onClick={() => setOpen(true)} />
    </div>
  );
};

export default TeamsDatePicker;
