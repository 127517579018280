import { useState, useEffect } from 'react';
import { ProgressBar } from '@progress/kendo-react-progressbars';
import './MeetingHabitsIndicator.scss';

export default function MeetingHabitsIndicator({ data }) {
    return (
        <div class="card-body p-t-10 p-b-0 p-r-10">
            <div class="activity-view">
                <div class="progressBox teamHabit scrollbar-box">
                    <div class="progressBoxRow">
                        <div class="progressBoxData">
                            <div class="progressBoxTxt">Invitations sent with at least a day's notice</div>
                            <div class="progressBoxVal">{data.atLeastDaysNotice}%</div>
                        </div>
                        <div>
                            <ProgressBar
                                value={data.atLeastDaysNotice}
                                style={{ height: 8 }}
                                label={props => {
                                    return <strong>{data.atLeastDaysNotice}%</strong>;
                                }}
                                labelVisible={true}
                                labelPlacement={'end'}
                            />
                        </div>
                    </div>
                    <div class="progressBoxRow">
                        <div class="progressBoxData">
                            <div class="progressBoxTxt">High attendance</div>
                            <div class="progressBoxVal">{data.attendance}%</div>
                        </div>
                        <div>
                            <ProgressBar
                                value={data.attendance}
                                style={{ height: 8 }}
                                label={props => {
                                    return <strong>{data.attendance}%</strong>;
                                }}
                                labelVisible={true}
                                labelPlacement={'end'}
                            />
                        </div>
                    </div>
                    <div class="progressBoxRow">
                        <div class="progressBoxData">
                            <div class="progressBoxTxt">No messages during the meeting</div>
                            <div class="progressBoxVal">{data.noMessage}%</div>
                        </div>
                        <div>
                            <ProgressBar
                                value={data.noMessage}
                                style={{ height: 8 }}
                                label={props => {
                                    return <strong>{data.noMessage}%</strong>;
                                }}
                                labelVisible={true}
                                labelPlacement={'end'}
                            />
                        </div>
                    </div>
                    <div class="progressBoxRow">
                        <div class="progressBoxData">
                            <div class="progressBoxTxt">Online meetings joined on time</div>
                            <div class="progressBoxVal">{data.joinedBeforeMeetingStarts}%</div>
                        </div>
                        <div>
                            <ProgressBar
                                value={data.joinedBeforeMeetingStarts}
                                style={{ height: 8 }}
                                label={props => {
                                    return <strong>{data.joinedBeforeMeetingStarts}%</strong>;
                                }}
                                labelVisible={true}
                                labelPlacement={'end'}
                            />
                        </div>
                    </div>
                    <div class="progressBoxRow">
                        <div class="progressBoxData">
                            <div class="progressBoxTxt">During working hours</div>
                            <div class="progressBoxVal">{data.duringWorkingHours}%</div>
                        </div>
                        <div>
                            <ProgressBar
                                value={data.duringWorkingHours}
                                style={{ height: 8 }}
                                label={props => {
                                    return <strong>{data.duringWorkingHours}%</strong>;
                                }}
                                labelVisible={true}
                                labelPlacement={'end'}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
