import { useState, useEffect } from "react";
import "./dashboard.scss";
import {
  AllWidgets,
  _cardCategoryType,
  _cardCollaborationType,
} from "./allwidgets";

import {
  Flex,
  Input,
  Button,
  Segment,
  List,
  Text,
  Loader,
  Divider,
} from "@fluentui/react-northstar";
import { AddIcon, SearchIcon } from "@fluentui/react-icons-northstar";
import { Dialog } from "@progress/kendo-react-dialogs";
import {
  Grid,
  GridColumn as Column,
  GridNoRecords,
} from "@progress/kendo-react-grid";
import SingleCardSettings from "../../filters/card/SingleCardSettings/SingleCardSettings";
import CenteredHeader from "../../Shared/Header/Header";
import QuickSetupCard from "./QuickSetupCard";
import CardCategories from "./CardCategories";
import { WidgetPreview } from "../../cards/Card/Card";
import WindowContext from "../../Shared/Context/Context";

const CellWithVisualizationIcon = (props) => {
  const data = props.dataItem;
  return (
    <td>
      <div className="widget_visualization_container">
        {data.Visualization === "summary" && (
          <i title="Summary" className={`analytics-${data.Visualization}`} />
        )}
        {data.Visualization === "barchart" && (
          <i title="Bar Chart" className={`analytics-${data.Visualization}`} />
        )}
        {data.Visualization === "piechart" && (
          <i title="Pie Chart" className={`analytics-${data.Visualization}`} />
        )}
        {data.Visualization === "list" && (
          <i title="List" className={`analytics-${data.Visualization}`} />
        )}
        {data.Visualization === "table" && (
          <i title="Table" className={`analytics-${data.Visualization}`} />
        )}
        {data.Visualization === "generalanalytics" && (
          <i
            title="General Analytics"
            className={`analytics-${data.Visualization}`}
          />
        )}
      </div>
    </td>
  );
};
const CellWithCategoryIcon = (props) => {
  const data = props.dataItem;
  return (
    <div className="widget_target_container">
      {
        data.isProductivity === 1 && (
          <i title="People Engagement" className={"analytics-productivity"} />
        )
        // data.isProductivity === 1 && <Avatar title="Productivity" size="smallest" image="/images/productivity.svg"  />
      }
      {data.isCustomerExperience === 1 && (
        <i
          title="Customer Engagement"
          className={"analytics-customerExperience"}
        />
      )}
      {data.isWellness === 1 && (
        <i title="Wellbeing" className={"analytics-wellbeing"} />
      )}
    </div>
  );
};
const CellWithViewIcon = (props) => {
  const data = props.dataItem;
  return (
    <Button
      // title="Preview"

      onClick={() => props.handlePreviewOpr(data)}
      className="widget_category_container"
      text
      iconOnly
      content={
        <>
          {data.categoryTypeID === 1 && (
            <i title="Summary" className={"analytics-summary"} />
          )}
          {data.categoryTypeID === 2 && (
            <i title="Activity" className={"analytics-activity2"} />
          )}
          {data.categoryTypeID === 3 && (
            <i title="Trends" className={"analytics-trends"} />
          )}
          {data.categoryTypeID === 4 && (
            <i title="Comparison" className={"analytics-comparison2"} />
          )}
          {data.categoryTypeID === 5 && (
            <i title="Insight" className={"analytics-insights"} />
          )}
        </>
      }
    />
  );
};
const CellWithTypeIcon = (props) => {
  const data = props.dataItem;
  return <Text content={_cardCollaborationType[data.collaborationTypeID]} />;
};
const CellWithAddIcon = (props) => {
  const data = props.dataItem;
  return (
    <td style={{ textAlign: "center" }}>
      <Button
        onClick={() => props.addCard({ ...data, cardID: -1 })}
        icon={<AddIcon />}
        content="Add"
        size="small"
        primary
      />
    </td>
  );
};

const DetailComponent = (props) => {
  const dataItem = props.dataItem;
  return (
    <section style={{ paddingRight: "32px" }}>
      <table>
        <thead>
          <tr>
            <td>Category:</td>
            <td className="text-right">
              <CellWithCategoryIcon {...props} />
            </td>
          </tr>
          <tr>
            <td>View:</td>
            <td className="text-right">
              <CellWithViewIcon {...props} />
            </td>
          </tr>
          <tr>
            <td>Type:</td>
            <td className="text-right">
              <CellWithTypeIcon {...props} />
            </td>
          </tr>
        </thead>
      </table>
    </section>
  );
};

const CardsList = (props) => {
  const [searchInput, setSearchInput] = useState("");
  const [cardlist, setCardlist] = useState(null);
  const [openPreview, setOpenPreview] = useState(false);
  const [previewObj, setPreviewObj] = useState(null);

  useEffect(() => {
    setSearchInput("");
    setCardlist(props.cards);
  }, [props.cards]);

  const expandChange = (event) => {
    let newData = cardlist.map((item) => {
      if (item.widgetName === event.dataItem.widgetName) {
        item.expanded = !event.dataItem.expanded;
      }

      return item;
    });
    setCardlist(newData);
  };

  const addCard = (cardData) => {
    props.onOpen(cardData);
  };
  const AddCardCell = (props) => (
    <CellWithAddIcon {...props} addCard={addCard} />
  );
  const CategoryCell = (props) => {
    return (
      <td>
        <CellWithCategoryIcon {...props} />
      </td>
    );
  };
  const ViewCell = (props) => {
    return (
      <td>
        <CellWithViewIcon
          {...props}
          handlePreviewOpr={(_prewiewObj) => {
            setPreviewObj(_prewiewObj);
            setOpenPreview(true);
          }}
        />
      </td>
    );
  };
  const TypeCell = (props) => {
    return (
      <td>
        <CellWithTypeIcon {...props} />
      </td>
    );
  };
  const DetailComponentTemplate = (props) => (
    <DetailComponent
      {...props}
      handlePreviewOpr={(_prewiewObj) => {
        setPreviewObj(_prewiewObj);
        setOpenPreview(true);
      }}
    />
  );
  const VisualizationHeaderCell = (props) => (
    <a className="k-link text-center" title="Visualization">
      <span className="visualisation-span">
        <i className="analytics-summary" />
      </span>
    </a>
  );
  return (
    <WindowContext.Consumer>
      {(context) => (
        <>
          <Flex gap="gap.small" padding="padding.medium" column fill>
            <Flex gap="gap.small" vAlign="center">
              <Input
                fluid
                icon={<SearchIcon />}
                clearable
                placeholder="Search..."
                value={searchInput}
                onChange={(e, { name, value }) => {
                  setSearchInput(value);
                }}
              />
              <Flex.Item push>
                <QuickSetupCard
                  // demo={props.demo}
                  //setupOpen={setupOpen}
                  // cards={cards}
                  dashboardId={props.dashboardId}
                  userFiltersData={props.userFiltersData}
                  handleOpen={() => props.handleOpen}
                />
              </Flex.Item>
            </Flex>

            <Flex.Item>
              <Grid
                style={{ maxHeight: "calc(100% - 32px)" }}
                detail={
                  context.mediaCategory.sm ? DetailComponentTemplate : null
                }
                expandField="expanded"
                onExpandChange={expandChange}
                data={
                  cardlist != null
                    ? cardlist.filter((card) =>
                        card.widgetName
                          .toLowerCase()
                          .includes(searchInput.toLowerCase())
                      )
                    : null
                }
              >
                <GridNoRecords>
                  {cardlist != null ? "No records available" : <Loader />}
                </GridNoRecords>
                <Column
                  width="34px"
                  headerCell={VisualizationHeaderCell}
                  cell={CellWithVisualizationIcon}
                />
                <Column
                  width="calc(100% - 154px)"
                  field="widgetName"
                  title="Name"
                />
                {!context.mediaCategory.sm && (
                  <Column width="80px" title="Category" cell={CategoryCell} />
                )}
                {!context.mediaCategory.sm && (
                  <Column width="60px" title="Preview" cell={ViewCell} />
                )}
                {!context.mediaCategory.sm && (
                  <Column width="130px" title="Type" cell={TypeCell} />
                )}
                <Column
                  width="88px"
                  title="Actions"
                  cell={AddCardCell}
                  headerCell={CenteredHeader}
                />
              </Grid>
            </Flex.Item>
          </Flex>
          {openPreview && (
            <Dialog
              autoFocus={true}
              className="previewDialog"
              title={previewObj.widgetName}
              onClose={() => setOpenPreview(false)}
            >
              <WidgetPreview
                widgetID={previewObj.cardWidgetID}
                viewTypeID={previewObj.cardViewTypeID}
              />
            </Dialog>
          )}
        </>
      )}
    </WindowContext.Consumer>
  );
};

const AddCard = (props) => {
  const [selectedCategory, setSelectedCategory] = useState(0);
  const [category, setCategory] = useState("isAll");
  const [categoryValue, setCategoryValue] = useState(1);
  const [card, setCard] = useState(null);
  const [open, setOpen] = useState(false);

  let cards = AllWidgets;

  const handleCategoryChange = (_categoryIndex, _category, _categoryValue) => {
    setSelectedCategory(_categoryIndex);
    setCategoryValue(_categoryValue);
    setCategory(_category);
  };
  const onCancel = () => {
    setOpen(false);
    setCard(null);
  };
  const onConfirm = () => {
    setOpen(false);
    setCard(null);
  };
  const onOpen = (cardData) => {
    setCard(cardData);
    setOpen(true);
  };

  return (
    <>
      <Flex
        className="addcardcontainer"
        gap="gap.small"
        padding="padding.medium"
        style={{
            minHeight: "100%",
            maxHeight: "100%"
        }}
      >
        <Flex.Item size="size.quarter">
          <Segment>
            <CardCategories
              selectedCategory={selectedCategory}
              handleCategoryChange={handleCategoryChange}
            />
          </Segment>
        </Flex.Item>

        <Flex.Item size="size.half" grow>
          <Segment>
            {props.userFiltersData ? (
              <>
                <CardsList
                  // demo={props.demo}
                  dashboardId={props.dashboardId}
                  userFiltersData={props.userFiltersData}
                  handleOpen={props.handleOpen}
                  onOpen={onOpen}
                  cards={
                    category === "isAll"
                      ? cards.sort((a, b) => b.isAvailable - a.isAvailable)
                      : cards
                          .filter((card) => card[category] === categoryValue)
                          .sort((a, b) => b.isAvailable - a.isAvailable)
                  }
                />
              </>
            ) : (
              <Loader style={{ height: "100%" }} />
            )}
          </Segment>
        </Flex.Item>
      </Flex>
      {open && (
        <Dialog
          autoFocus={true}
          className="settingsDialog"
          title={card.widgetName}
          onClose={() => onCancel()}
        >
          <SingleCardSettings
            // demo={props.demo}
            cardData={card}
            dashboardId={props.dashboardId}
            submitHandler={onConfirm}
            cancelHandler={onCancel}
            userFiltersData={props.userFiltersData}
          />
        </Dialog>
      )}
    </>
  );
};

export default AddCard;
