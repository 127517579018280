import React from "react";
import CircleChart from "../../Shared/uicomponents/circleChart";
import "./MeetingParticipation.scss";

const MeetingParticipation = ({ data }) => {
  return (
    <div className="meetingGraph-area">
      <CircleChart id="xx" value={data} size="big" />
    </div>
  );
};

export default MeetingParticipation;
