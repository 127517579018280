import {useState, useEffect} from 'react'
import { Input, Popup, List } from '@fluentui/react-northstar'
import { ChevronDownIcon } from '@fluentui/react-icons-northstar'
import './teamsTimePicker.scss'


const TimeList = [
  {key: "0000", header: "00:00"},
  {key: "0030", header: "00:30"},
  {key: "0100", header: "01:00"},
  {key: "0130", header: "01:30"},
  {key: "0200", header: "02:00"},
  {key: "0230", header: "02:30"},
  {key: "0300", header: "03:00"},
  {key: "0330", header: "03:30"},
  {key: "0400", header: "04:00"},
  {key: "0430", header: "04:30"},
  {key: "0500", header: "05:00"},
  {key: "0530", header: "05:30"},
  {key: "0600", header: "06:00"},
  {key: "0630", header: "06:30"},
  {key: "0700", header: "07:00"},
  {key: "0730", header: "07:30"},
  {key: "0800", header: "08:00"},
  {key: "0830", header: "08:30"},
  {key: "0900", header: "09:00"},
  {key: "0930", header: "09:30"},
  {key: "1000", header: "10:00"},
  {key: "1030", header: "10:30"},
  {key: "1100", header: "11:00"},
  {key: "1130", header: "11:30"},
  {key: "1200", header: "12:00"},
  {key: "1230", header: "12:30"},
  {key: "1300", header: "13:00"},
  {key: "1330", header: "13:30"},
  {key: "1400", header: "14:00"},
  {key: "1430", header: "14:30"},
  {key: "1500", header: "15:00"},
  {key: "1530", header: "15:30"},
  {key: "1600", header: "16:00"},
  {key: "1630", header: "16:30"},
  {key: "1700", header: "17:00"},
  {key: "1730", header: "17:30"},
  {key: "1800", header: "18:00"},
  {key: "1830", header: "18:30"},
  {key: "1900", header: "19:00"},
  {key: "1930", header: "19:30"},
  {key: "2000", header: "20:00"},
  {key: "2030", header: "20:30"},
  {key: "2100", header: "21:00"},
  {key: "2130", header: "21:30"},
  {key: "2200", header: "22:00"},
  {key: "2230", header: "22:30"},
  {key: "2300", header: "23:00"},
  {key: "2330", header: "23:30"},
]

const TeamsTimePicker = (props) => {
    const [open, setOpen] = useState(false);
    const [isInput, setIsInput] = useState(false);
    const [timeInput, setTimeInput] = useState("00:00");
    const [timeValue, setTimeValue] = useState("00:00");
    const [selectedTimeItemIndex, setSelectedTimeItemIndex] = useState(0);
    const [min, setMin] = useState("00:00");
    const [items, setItems] = useState([]);

    // useEffect(() => {
    //   debugger;
    //   let _initValue = props.value ? props.value : "00:00";
    //   setTimeInput(_initValue);
    //   setTimeValue(_initValue);
    //   let _min = props.min ? props.min : "00:00";
    //   setMin(_min);
    //   let filteredList = TimeList.filter(item => item.header >= _min);
    //   setItems(filteredList);
    //   let _initIndex = filteredList.findIndex(t => t.header === _initValue);
    //   setSelectedTimeItemIndex(_initIndex);
    // }, []);

    useEffect(() => {
      let _initValue = props.value ? props.value : "00:00";
      setTimeInput(_initValue);
      setTimeValue(_initValue);
    }, [props.value]);

    useEffect(() => {
      let _min = props.min ? props.min : "00:00";
      setMin(_min);
      if(props.value < _min){
        setTimeInput(_min);
        setTimeValue(_min);
        let filteredList = TimeList.filter(item => item.header >= _min);        
        setItems(filteredList);
        let _initIndex = filteredList.findIndex(t => t.header === _min);
        setSelectedTimeItemIndex(_initIndex);
        props.onChange(_min);
      } 
      else{
        let filteredList = TimeList.filter(item => item.header >= _min);        
        setItems(filteredList);
        let _initIndex = filteredList.findIndex(t => t.header === props.value);
        setSelectedTimeItemIndex(_initIndex);
      }   
    }, [props.min]);

    return (
      <div className="teams-time-picker-container">
        <Input 
          className="teams-time-picker-input" 
          required={props.required ? props.required : false}
          name={props.name ? props.name : ''}
          value={timeInput}
          onChange={(e, { name, value }) => {
            setTimeInput(value);
          }}
          onFocus={() => { 
            setIsInput(true);
            setOpen(true); 
          }}
          onBlur={() => { 
            let _timeInput = timeInput.replaceAll(/[\D]/g, '').substr(0,4);
            let l = 4 - _timeInput.length;
            while(l > 0){
              _timeInput = _timeInput + '0';
              l--;
            }
            let _listItem = "";
            if(_timeInput.match(/^([0-1]?[0-9]|2[0-3])[0-5][0-9]$/g)){
              //valid Time
              _timeInput = _timeInput.substr(0, 2) + ':' + _timeInput.substr(2, 2);
              setTimeInput(_timeInput);
              setTimeValue(_timeInput);
              _listItem = _timeInput;
              props.onChange(_timeInput);
            }
            else{
              setTimeInput(timeValue);
              _listItem = timeValue;
              props.onChange(timeValue);
            }
            let _newIndex = items.findIndex(t => t.header === _listItem);
            setSelectedTimeItemIndex(_newIndex);
            setIsInput(false);
          }} 
        />
        <Popup
          open={open}
          onOpenChange={(e, { open }) => { open ? setOpen(true) : isInput ? setOpen(true) : setOpen(false) }}
          content={
            <List 
              className="teams-time-picker-list-items"
              selectable
              items={items} 
              selectedIndex={selectedTimeItemIndex} 
              onSelectedIndexChange={(e, newProps) => {
                setTimeInput(items[newProps.selectedIndex].header);
                setTimeValue(items[newProps.selectedIndex].header);
                setSelectedTimeItemIndex(newProps.selectedIndex);
                setOpen(false);
                props.onChange(items[newProps.selectedIndex].header);
              }}
            />
          }
          offset={({ popper }) => [0, 1]}
          inline
          align="end"
          position={"below"}
          trigger={
              // <Button className="teams-time-picker-button" iconOnly icon={<ChevronDownIcon />} onClick={() => {setOpen(true)}} />
              <div className="teams-time-picker-button"><ChevronDownIcon /></div>
          }
        />
      </div>
    )
}

export default TeamsTimePicker