import axios, { AxiosInstance } from "axios";
import AuthHelper from "./auth-helper";
import { AuthenticationResult } from "@azure/msal-browser";
import * as constants from "../constants";

class FiltersApiService {
  static AuthenticatedApi = axios.create({
    baseURL: constants.Api.baseUri,
    // will inject auth header on-demand later as needed.
    headers: {
      "Content-Type": "application/json",
    },
  });

  // an api operation that calls Filter/GetOverviewData for a user api endpoint.
  static GetOverviewData(token) {
    return this.AuthenticatedApi.get("/Filter/GetOverviewData", {
      headers: {
        Authorization: "Bearer " + token,
      },
    })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        console.log(
          "FiltersApiService.GetOverviewData() - An error has occurred calling the web api: " +
            error
        );
        return null;
      });
  }

  // an api operation that calls Filter/GetUserData for a user api endpoint.
  static GetUserData(token) {
    return this.AuthenticatedApi.get("/Filter/GetUserData", {
      headers: {
        Authorization: "Bearer " + token,
      },
    })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        console.log(
          "FiltersApiService.GetUserData() - An error has occurred calling the web api: " +
            error
        );
        return null;
      });
  }

  static AddCardWithFilters(cardFilterData, token) {
    return this.AuthenticatedApi.post("/Filter/InsertCard/", cardFilterData, {
      headers: {
        Authorization: "Bearer " + token,
      },
    })
      .then((response) => {
        console.log("Card added successfully.");
        return "Success";
      })
      .catch((error) => {
        console.log(
          "FiltersApiService.AddCardWithFilters() - An error has occurred calling the web api: " +
            error
        );
        return null;
      });
  }
  static AddCardWithMultipleFilters(cardsFilterData, token) {
    return this.AuthenticatedApi.post(
      "/Filter/InsertMultiSelectCard/",
      cardsFilterData,
      {
        headers: {
          Authorization: "Bearer " + token,
        },
      }
    )
      .then((response) => {
        console.log("Cards added successfully.");
        return "Success";
      })
      .catch((error) => {
        console.log(
          "FiltersApiService.AddCardWithMultipleFilters() - An error has occurred calling the web api: " +
            error
        );
        return null;
      });
  }

  static UpdateCardWithFilters(cardFilterData, token) {
    return this.AuthenticatedApi.put("/Filter/UpdateCard/", cardFilterData, {
      headers: {
        Authorization: "Bearer " + token,
      },
    })
      .then((response) => {
        console.log("Card updated successfully.");
        return "Success";
      })
      .catch((error) => {
        console.log(
          "FiltersApiService.UpdateCardWithFilters() - An error has occurred calling the web api: " +
            error
        );
        return null;
      });
  }

  static UpdateMultiCardWithFilters(cardFilterData, token) {
    return this.AuthenticatedApi.put("/Filter/UpdateMultiCard/", cardFilterData, {
      headers: {
        Authorization: "Bearer " + token,
      },
    })
      .then((response) => {
        console.log("Cards updated successfully.");
        return "Success";
      })
      .catch((error) => {
        console.log(
          "FiltersApiService.UpdateMultiCardWithFilters() - An error has occurred calling the web api: " +
            error
        );
        return null;
      });
  }

  // an api operation that calls Filter/GetUserData for a user api endpoint.
  static GetCardData(cardId, selectedDate, token) {
    return this.AuthenticatedApi.get("/Filter/GetCard/" + cardId, {
      params: {
        cardId: cardId,
        // selectedDate: FiltersApiService.FormatDate(selectedDate),
        selectedDate: selectedDate,
      },
      headers: {
        Authorization: "Bearer " + token,
      },
    })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        console.log(
          "FiltersApiService.GetCardData() - An error has occurred calling the web api: " +
            error
        );
        return null;
      });
  }

  // static FormatDate(date) {
  //   //debugger;
  //   var d = date,
  //     month = "" + (d.getMonth() + 1),
  //     day = "" + d.getDate(),
  //     year = d.getFullYear();

  //   if (month.length < 2) month = "0" + month;
  //   if (day.length < 2) day = "0" + day;

  //   return [year, month, day].join("-");
  // }

  static DeleteCard(dashboardId, cardId, token) {
    return this.AuthenticatedApi.delete("/Filter/DeleteCard/" + cardId, {
      params: {
        cardId: cardId,
        dashboardId: dashboardId,
      },
      headers: {
        Authorization: "Bearer " + token,
      },
    })
      .then((response) => {
        return "Success";
      })
      .catch((error) => {
        console.log(
          "FiltersApiService.DeleteCard() - An error has occurred calling the web api: " +
            error
        );
        return null;
      });
  }
}

export default FiltersApiService;
