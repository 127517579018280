import {
    Chart,
    ChartSeries,
    ChartSeriesItem,
    ChartCategoryAxis,
    ChartCategoryAxisItem,
    ChartLegend,
    ChartTooltip,
    ChartValueAxis,
    ChartValueAxisItem
} from '@progress/kendo-react-charts';
import "hammerjs";
import './StackedBarChart.scss';

// const categories = ["01/12", "02/12", "03/12", "04/12", "05/12", "06/12", "07/12", "08/12", "09/12", "10/12", "11/12", "12/12", "13/12"];
// const series = [
//     {
//         name: "Good",
//         data: [300, 850, 500, 400, 600, 650, 800, 400, 300, 500, 450, 350, 250],
//         color: 'rgb(35, 123, 75)'
//     },
//     {
//         name: "Average",
//         data: [500, 450, 350, 650, 400, 500, 600, 650, 750, 200, 550, 350, 250],
//         color: 'rgb(248, 210, 42)'
//     },
//     {
//         name: "Poor",
//         data: [400, 250, 300, 350, 400, 600, 500, 400, 600, 400, 650, 550, 350],
//         color: 'rgb(196, 49, 75)'
//     }
// ];

export default function StackedBarChart({ data }) {
    const renderTooltip = ({
        point
    }) => <span>{point.category.toString()}: {point.value}</span>;

    return (
        <>
        {
            data && data.categories && data.categories.length > 0 &&
                <Chart style={{ height: '100%' }} pannable={{
                    lock: "y",
                }}
                    zoomable={{
                        mousewheel: {
                            lock: "y",
                        },
                        selection: {
                            lock: "y",
                        },
                    }}>
                    <ChartValueAxis>
                        <ChartValueAxisItem
                            min={0}
                        />
                    </ChartValueAxis>
                <ChartLegend position="top" orientation="horizontal" />
                <ChartCategoryAxis>
                        <ChartCategoryAxisItem categories={data.categories} labels={{
                            rotation: 'auto'
                        }} maxDivisions={10} />
                </ChartCategoryAxis>
                <ChartSeries>
                    {data.series.map((item, idx) => (
                        <ChartSeriesItem
                            key={idx}
                            color={item.color}
                            type="column" stack={true}
                            tooltip={{ visible: true }}
                            data={item.data}
                            name={item.name}
                        />
                    ))}
                    </ChartSeries>
                    <ChartTooltip render={renderTooltip} />
            </Chart>
        }
        </>
      );
}
