import React from 'react';
import {useState, useEffect } from 'react';
import { Flex, Segment, List, Divider, Header, Loader, Box, Text } from '@fluentui/react-northstar';
import '../Dashboard/dashboard.scss';
import UserConfig from './userconfig';
import BusinessHoursConfig from './businesshoursconfig';
import GroupsConfig from './groupsconfig';
import ConfigurationApiService from '../../../services/ConfigurationApiService';
import AuthHelper from '../../../services/auth-helper';
import '../../Auth/auth.scss';


import {
    ContactGroupIcon,
    SpeakerPersonIcon,
    MeetingTimeIcon,
    SettingsIcon
} from '@fluentui/react-icons-northstar'

const ConfigCategories = (props) => {
  const items = [
      {
          key: 'users',
          header: 'People',
          media: <SpeakerPersonIcon />,
          name: 'People'
      }, {
          key: 'groups',
          header: 'Groups',
          media: <ContactGroupIcon />,
          name: 'Group'
      }, {
          key: 'logins',
          header: 'Manage Logins',
          media: <SettingsIcon />,
          name: 'Manage Logins'
      }, {
          key: 'businesshours',
          header: 'Working Hour Profiles',
          media: <MeetingTimeIcon />,
          name: 'Working Hours'
      }
  ]

  const onSelectChange = (index, newProps) => {
    const category = newProps.items[newProps.selectedIndex].key
    const header = newProps.items[newProps.selectedIndex].header
    const name = newProps.items[newProps.selectedIndex].name
    props.handleCategoryChange(index, category, name, header);
  }

  return (<>
    <List
      className="categorylist"
      selectable
      selectedIndex={props.selectedCategory}
      onSelectedIndexChange={(e, newProps) => {
        onSelectChange(newProps.selectedIndex, newProps)
      }} 
      items = {
        items.slice(0, 2)
      }
      style={{ width: '100%' }}
    />
    <Divider content={<Text content="Working Hours" weight="bold" />} />
    <List
      className="categorylist"
      selectable
      selectedIndex={props.selectedCategory - 3}
      onSelectedIndexChange={(e, newProps) => {
        onSelectChange(newProps.selectedIndex + 3, newProps)
      }} 
          items={items.slice(3, 4)}
          style={{ width: '100%' }}
    /></>
  )
}


const Configuration = () => {
  const [loading, setLoading] = useState(false);
  const [isAdmin, setIsAdmin] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState(0);
  const [category, setCategory] = useState("users");
  const [name, setName] = useState("User");
  const [header, setHeader] = useState("Users");
  const [tenantDetails, setTenantDetails] = useState(null);
  const [loggedInUserEmail, setLoggedInUserEmail] = useState(null);

  useEffect(() => {
    setLoading(true)
    AuthHelper.getAccessToken(function(token){
      ConfigurationApiService.GetAdminUserData(token)
      .then((response) => {
        if(response != undefined && response != ""){
          setIsAdmin((response.administrativeAccess || response.administrativeAccessInherited === 1));
          setLoggedInUserEmail(response.email);
        }
        setLoading(false)
        loadTenantData()
      });   
    }) 
  }, [])

  const loadTenantData = () => {
    setLoading(true)
    AuthHelper.getAccessToken(function(token){
      ConfigurationApiService.GetTenantDetails(token)
      .then((response) => {
        if(response != undefined && response != ""){
          setTenantDetails(response)
        }
        setLoading(false)
      }); 
    }) 
  }

  const handleCategoryChange = (_categoryIndex, _category, _name, _header) => {
      setSelectedCategory(_categoryIndex);
      setCategory(_category);
      setName(_name);
      setHeader(_header);
      if (global.localStorage) {
          global.localStorage.setItem(
              'contentUrl_configuration',
              'configuration/' + _categoryIndex
          );
      }
  }

  return (
    (loading) ? <Loader label="Loading..." /> :
    <Box>
      { isAdmin ? 
        <Flex className="addcardcontainer" gap="gap.small" padding="padding.medium" style={{ minHeight: '100vh' }}>
          <Flex.Item className="leftSideBar" size="size.quarter">
          <Segment>
            <Divider content={<Text content="Edit People/Group" weight="bold" />} />
            <ConfigCategories selectedCategory={selectedCategory} handleCategoryChange={handleCategoryChange} />
          </Segment>
        </Flex.Item>

        <Flex.Item className="rightSideBar" size="size.half" grow>
          <Segment>
            {
                selectedCategory === 0 && <UserConfig tenantDetails={tenantDetails} loggedInUserEmail={loggedInUserEmail}/>
            }
            {
                selectedCategory === 1 && <GroupsConfig tenantDetails={tenantDetails} loggedInUserEmail={loggedInUserEmail}/>
            }
            {
                selectedCategory === 3 && <BusinessHoursConfig tenantDetails={tenantDetails} loggedInUserEmail={loggedInUserEmail}/>
            }
          </Segment>
        </Flex.Item>
      </Flex>
      : 
      <Flex fill className="default-segment-container" hAlign="center" vAlign="center">
        <Segment className="auth-form-segment default-segment">
            <Flex gap="gap.small" column hAlign="center" vAlign="center">
                <Text weight="semibold" size="large" content="Access Denied"/>
                <Text content="Access to settings is restricted to App Owners."/>
            </Flex>
        </Segment>
      </Flex>
      }
    </Box>
  )
}

export default Configuration;
