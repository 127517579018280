import React, { useState, Fragment } from "react";
import { TableAddIcon } from "@fluentui/react-icons-northstar";
import { Button } from "@fluentui/react-northstar";

import MultiCardSettings from "../../filters/card/MultiCardSettings/MultiCardSettings";
import { Dialog } from "@progress/kendo-react-dialogs";

import "./dashboard.scss";
import WindowContext from "../../Shared/Context/Context";

const QuickSetupCard = (props) => {
  const { dashboardId, handleOpen, userFiltersData } = props;
  const [open, setOpen] = useState(false);
  // const [load, setLoad] = useState(false);
  const onCancel = () => {
    setOpen(false);
    handleOpen(false);
  };
  const onConfirm = () => {
    setOpen(false);
    handleOpen(false);
  };

  return (
    <WindowContext.Consumer>
      {(context) => (
        <Fragment>
          <Button
            onClick={() => {
              handleOpen(true);
              setOpen(true);
            }}
            title="Quick Add"
            size="medium"
            primary
            icon={<TableAddIcon />}
            iconOnly={context.mediaCategory.sm}
            content={context.mediaCategory.sm ? "" : "Quick Add"}
            style={{
              maxHeight: "calc(100% - 1px)",
              minWidth: `${context.mediaCategory.sm ? "2rem" : "auto"}`,
            }}
          />

          {open && (
            <Dialog
              title="Quick Add"
              autoFocus={true}
              onClose={() => onCancel()}
              width={500}
              // height={380}
            >
              <MultiCardSettings
                dashboardId={dashboardId}
                submitHandler={onConfirm}
                cancelHandler={onCancel}
                userFiltersData={userFiltersData}
              />
            </Dialog>
          )}
        </Fragment>
      )}
    </WindowContext.Consumer>
  );
};

export default QuickSetupCard;
