import React, { useEffect, useState } from "react";
import FiltersApiService from "../../../services/FiltersApiService";
import AuthHelper from "../../../services/auth-helper";
import {
  Card,
  CardHeader,
  CardBody,
  Flex,
  Button,
  Text,
  Loader,
  TrashCanIcon,
  SettingsAudioIcon,
  Segment,
} from "@fluentui/react-northstar";
import {
  BarChart,
  BasicSummary,
  UserComparisonView,
  ComparisonView,
  DonutChart,
  GroupedGrid,
  MeetingHabitsIndicator,
  MeetingSummaryView,
  PieChart,
  ReactionsView,
  StackedBarChart,
  StandardGrid,
  TeamSummaryView,
  TopUsersGrid,
  MeetingParticipation,
  UserSummaryView,
  UserAnalytics,
} from "../../viewTypes/index";
import MockData from "../../viewTypes/mockData";

const WidgetPreview = (props) => {
  const data = MockData[props.widgetID].data;
  return (
    <Flex
      style={{ minHeight: "260px", height: "100%" }}
      column
      fluid="true"
      gap="gap.small"
      hAlign="stretch"
    >
      <Text content={MockData[props.widgetID].description} />
      <Flex.Item>
        <Segment style={{ overflow: "auto", height: "100%", padding: "0" }}>
          {
            {
              0: <StandardGrid data={data} widgetId={props.widgetID} />,
              2: <GroupedGrid data={data} widgetId={props.widgetID} />,
              3: <MeetingSummaryView data={data} />,
              4: <MeetingHabitsIndicator data={data} />,
              5: <DonutChart data={data} />,
              6: <ReactionsView data={data} />,
              7: <BarChart data={data} />,
              8: <DonutChart data={data} />,
              9: <TopUsersGrid data={data} widgetId={props.widgetID} />,
              10: <TeamSummaryView data={data} />,
              11: <MeetingParticipation data={data} />,
              12: <BasicSummary data={data} />,
              13: <UserSummaryView data={data} />,
              15: <UserComparisonView data={data} />,
              16: <ComparisonView data={data} />,
              18: <StackedBarChart data={data} />,
              19: <PieChart data={data} />,
              // 20: <UserAnalytics data={data} />,
            }[props.viewTypeID]
          }
        </Segment>
      </Flex.Item>
    </Flex>
  );
};

const CardWidget = (props) => {
  const [isLoaded, setIsLoaded] = useState(false);
  const [data, setData] = useState({});
  const [operating, setOperating] = useState(false);
  const [noData, setNoData] = useState(false);
  const [isDemo, setIsDemo] = useState(false);
  let mockData = MockData[props.card.widgetID].data;
  const [_selectedDate, setSelectedDate] = useState(props.selectedDate);
  let _date = props.selectedDate;
  let cardTimer;
  function getCardData(__date){
    AuthHelper.getAccessToken(function (token) {
      FiltersApiService.GetCardData(
        props.card.cardID,
        __date,
        token
        ).then((response) => {
          if (
            response === null ||
            response === "" ||
            (Array.isArray(response) && response.length === 0) ||
            (typeof response === "object" && response.categories === null)
          ) {
            setNoData(true);
            setIsLoaded(true);

            // setData(response);
          } else {
            setNoData(false);
            setData(response);
            setIsLoaded(true);
          }
        });
    })    
  }
  //useEffect(()=>{
  //  // cardTimer = setInterval(function(){ 
  //  //   if(_date){
  //  //     getCardData(_date);
  //  //   }
  //  // }, 1000);
  //  if(_date){
  //    getCardData(_date);
  //  }
  //  return () => clearInterval(cardTimer);
  //},[]);

  useEffect(()=>{
    _date = props.selectedDate;
      clearInterval(cardTimer)
      getCardData(_date);
    cardTimer = setInterval(function(){ 
      if(_date){
        getCardData(_date);
      }
    }, 900000);
    return () => clearInterval(cardTimer);
  }, [props.selectedDate]);

  useEffect(() => {
    setIsDemo(props.demo);
  }, [props.demo]);

  return (
    <Card compact>
      <CardHeader>
        <Flex
          vAlign="center"
          className="cardDesc"
          space="between"
          style={{ margin: "6px 0px 7px 10px", width: "100%" }}
        >
          <Text content={props.card.name} weight="bold" />
        </Flex>
        <Flex style={{ margin: "6px 2px 7px 0px" }} className="cardActions">
          {/* <Dialog
                        className="shareDialog"
                        closeOnOutsideClick={true}
                        cancelButton="Close"
                        trigger={<Button disabled={operating} icon={<DownloadIcon />} iconOnly text title="Download" />}
                        content={<Text size="large" content="Coming Soon: You will be able to download the report locally." />}
                    /> */}
          <Button
            disabled={operating}
            icon={<SettingsAudioIcon rotate={90} />}
            iconOnly
            text
            title="Edit"
            onClick={() => props.onCardEdit(props.card.cardID)}
          />
          <Button
            disabled={operating}
            icon={<TrashCanIcon />}
            iconOnly
            text
            title="Remove"
            onClick={() => {
              setOperating(true);
              props.onCardRemove(props.card.cardID);
            }}
          />
        </Flex>
      </CardHeader>
      <CardBody>
        {isDemo ? (
          {
            0: <StandardGrid data={mockData} widgetId={props.card.widgetID} />,
            2: <GroupedGrid data={mockData} widgetId={props.card.widgetID} />,
            3: <MeetingSummaryView data={mockData} />,
            4: <MeetingHabitsIndicator data={mockData} />,
            5: <DonutChart data={mockData} />,
            6: <ReactionsView data={mockData} />,
            7: <BarChart data={mockData} />,
            8: <DonutChart data={mockData} />,
            9: <TopUsersGrid data={mockData} widgetId={props.card.widgetID} />,
            10: <TeamSummaryView data={mockData} />,
            11: <MeetingParticipation data={mockData} />,
            12: <BasicSummary data={mockData} />,
            13: <UserSummaryView data={mockData} />,
            15: <UserComparisonView data={mockData} />,
            16: <ComparisonView data={mockData} />,
            18: <StackedBarChart data={mockData} />,
            19: <PieChart data={mockData} />,
            // 20: <UserAnalytics data={data} />,
          }[props.card.viewTypeID]
        ) : isLoaded ? (
          !noData ? (
            {
              0: <StandardGrid data={data} widgetId={props.card.widgetID} />,
              2: <GroupedGrid data={data} widgetId={props.card.widgetID} />,
              3: <MeetingSummaryView data={data} />,
              4: <MeetingHabitsIndicator data={data} />,
              5: <DonutChart data={data} />,
              6: <ReactionsView data={data} />,
              7: <BarChart data={data} />,
              8: <DonutChart data={data} />,
              9: <TopUsersGrid data={data} widgetId={props.card.widgetID} />,
              10: <TeamSummaryView data={data} />,
              11: <MeetingParticipation data={data} />,
              12: <BasicSummary data={data} />,
              13: <UserSummaryView data={data} />,
              15: <UserComparisonView data={data} />,
              16: <ComparisonView data={data} />,
              18: <StackedBarChart data={data} />,
              19: <PieChart data={data} />,
              // 20: <UserAnalytics data={data} />,
            }[props.card.viewTypeID]
          ) : (
            <Flex vAlign="center" fill hAlign="center">
              <Text size="large" content="No data to display." />
            </Flex>
          )
        ) : (
          <Loader style={{ height: "100%" }} />
        )}
      </CardBody>
    </Card>
  );
};

export { CardWidget, WidgetPreview };
