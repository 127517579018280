import { useState, useEffect } from "react";
import _ from "lodash";
import "./dashboard.scss";

import "../../../../node_modules/react-grid-layout/css/styles.css";
import "../../../../node_modules/react-resizable/css/styles.css";
import { Responsive, WidthProvider } from "react-grid-layout";
import DashboardApiService from "../../../services/DashboardApiService";
import FiltersApiService from "../../../services/FiltersApiService";
import AuthHelper from "../../../services//auth-helper";
import { Button, Flex, Text, Loader, Segment } from "@fluentui/react-northstar";
import { AddIcon } from "@fluentui/react-icons-northstar";
import { CardWidget } from "../../cards/Card/Card";

const saveToLS = (key, value) => {
  if (global.localStorage) {
    global.localStorage.setItem("layout" + key, JSON.stringify(value));
  }
};

const ResponsiveGridLayout = WidthProvider(Responsive);
// const originalLayouts = getFromLS(dashboardID) || generateLayout(dashboardID);
const GridComponent = (props) => {
  const defaultProps = {
    className: "layout",
    rowHeight: 315,
    breakPoints: { lg: 1200, md: 996, sm: 768, xs: 480, xxs: 0 },
    cols: { lg: 12, md: 12, sm: 6, xs: 4, xxs: 2 },
    margin: [8, 8, 8, 8],
    containerPadding: [20, 20],
  };
  const [loaded, setLoaded] = useState(false);
  const [isDraggable, setIsDraggable] = useState(false);
  const [currentBreakpoint, setCurrentBreakPoint] = useState(null);
  const [mounted, setMounted] = useState(false);
  const [layouts, setLayouts] = useState({
    lg: [],
    md: [],
    sm: [],
    xxs: [],
  });
  const [cards, setCards] = useState([]);
  const [dashboardID, setDashboardID] = useState(props.dashboardId);
  const generateLayout = (key, cardList) => {
    let layout = {
      lg: [],
      md: [],
      sm: [],
      xxs: [],
    };

    AuthHelper.getAccessToken(function (token) {
      DashboardApiService.GetDashboardLayout(key, token).then((response) => {
        if (response && response != "") {
          layout = response;
        }
        setLayouts(JSON.parse(JSON.stringify(layout)));
        setCards(cardList);
        setLoaded(true);
      });
    });
  };

  useEffect(() => {
    setLoaded(false);
    setMounted(false);
    setCards([]);
    onWidthChange(window.innerWidth);
    AuthHelper.getAccessToken(function (token) {
      DashboardApiService.GetDashboardCards(props.dashboardId, token).then(
        (response) => {
          if (response) {
            let _cards = response;
            if (_cards && _cards.length > 0) {
              generateLayout(props.dashboardId, _cards);
            } else {
              setLoaded(true);
            }
          }
        }
      );
    });
    setMounted(true);
    return () => {
      setMounted(false);
    };
  }, [props.dashboardId]);

  const onWidthChange = (width) => {
    //alert(width);
    //alert(currentBreakpoint);

    if (currentBreakpoint === null) {
      // The very first time.
      // Compute the initial breakpoint.
      const breakpoints = defaultProps.breakPoints;
      const sortedBreakpoints = Object.keys(breakpoints).sort(
        (breakpoint1, breakpoint2) =>
          breakpoints[breakpoint1] - breakpoints[breakpoint2]
      );
      let breakpoint = sortedBreakpoints[0];
      for (let i = 0; i < sortedBreakpoints.length; i++) {
        const currentBreakpoint = sortedBreakpoints[i];
        const nextBreakpoint = sortedBreakpoints[i + 1];
        if (
          typeof nextBreakpoint === "undefined" ||
          (breakpoints[currentBreakpoint] <= width &&
            width <= breakpoints[nextBreakpoint])
        ) {
          breakpoint = currentBreakpoint;
          break;
        }
      }
      //console.log((breakpoint === 'sm' || breakpoint === 'xs' || breakpoint === 'xxs') ? false : true)
      setIsDraggable(
        breakpoint === "sm" || breakpoint === "xs" || breakpoint === "xxs"
          ? false
          : true
      );
      setCurrentBreakPoint(breakpoint);
      //alert(breakpoint);

      // Call your method.
      //this.props.updateBreakpointKey(this.initialBreakpoint)
    }
  };

  const onLayoutChange = (layout, layouts) => {
    saveToLS(props.dashboardId, layouts, cards);
    setLayouts(layouts);
    var _layout = {};
    Object.keys(layouts).map(function (layoutEntity) {
      return (_layout[layoutEntity] = layouts[layoutEntity].map(function (l) {
        return (({ i, x, y, w, h, isResizable, isDraggable }) => ({
          i,
          x,
          y,
          w,
          h,
          isResizable,
          isDraggable,
        }))(l);
      }));
    });
    // let _layout = layout.map(function(layoutEntity) {
    //   return (({ i, x, y, w, h, isResizable }) => ({ i, x, y, w, h, isResizable }))(layoutEntity);
    // })
    //alert(currentBreakpoint);
    //alert(currentBreakpoint === 'lg' || currentBreakpoint === 'md');

    if (currentBreakpoint !== null) {
      if (currentBreakpoint === "lg" || currentBreakpoint === "md") {
        AuthHelper.getAccessToken(function (token) {
          DashboardApiService.UpdateDashboardLayout(
            props.dashboardId,
            JSON.stringify(_layout),
            token
          ).then((response) => {});
        });
      }
    }
  };

  const onBreakpointChange = (breakpoint) => {
    setIsDraggable(
      breakpoint === "sm" || breakpoint === "xs" || breakpoint === "xxs"
        ? false
        : true
    );
    setCurrentBreakPoint(breakpoint);
  };

  const onCardRemove = (_cardID) => {
    AuthHelper.getAccessToken(function (token) {
      FiltersApiService.DeleteCard(dashboardID, _cardID, token).then(
        (response) => {
          if (response) {
            let _cards = _.filter(cards, (card) => card.cardID !== _cardID);
            setCards(_cards);
          }
        }
      );
    });
  };

  return loaded ? (
    cards.length > 0 ? (
      <ResponsiveGridLayout
        {...defaultProps}
        layouts={layouts}
        onBreakpointChange={onBreakpointChange}
        onLayoutChange={onLayoutChange}
        measureBeforeMount={true}
        useCSSTransforms={mounted}
        compactType="vertical"
        isBounded={true}
        onWidthChange={onWidthChange}
        isDraggable={isDraggable}
        draggableCancel=".cardActions, .k-chart"
      >
        {_.map(cards, function (card, i) {
          return (
            <div key={card.cardID}>
              {
                <CardWidget
                  demo={props.demo}
                  card={card}
                  onCardRemove={onCardRemove}
                  onCardEdit={props.onCardEdit}
                  selectedDate={props.selectedDate}
                />
              }
            </div>
          );
        })}
      </ResponsiveGridLayout>
    ) : (
      <Flex
        fill
        className="default-segment-container"
        hAlign="center"
        vAlign="center"
      >
        <Segment className="default-segment">
          <Flex gap="gap.small" column hAlign="center" vAlign="center">
            <Text content="You have not yet added any cards" />
            <Button
              inverted
              icon={<AddIcon />}
              content="Add Cards"
              onClick={() => props.addHandler("add")}
            />
          </Flex>
        </Segment>
      </Flex>
    )
  ) : (
    <Loader />
  );
};
export default GridComponent;
