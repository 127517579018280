import React, { useState, useEffect } from "react";
import { Form } from "@fluentui/react-northstar";
import { TimePicker } from "@progress/kendo-react-dateinputs";

import "./SingleCardSettings.scss";

import {
  Loader,
  Flex,
  Segment,
  Header,
  Dialog,
  Button,
  Text,
  Input,
} from "@fluentui/react-northstar";
import {
  AcceptIcon,
  ExclamationTriangleIcon,
} from "@fluentui/react-icons-northstar";

import FiltersApiService from "../../../../services/FiltersApiService";
import AuthHelper from "../../../../services/auth-helper";
import {
  FilterTypes,
  cardFilters as card_filters,
  sentimentTypes,
  reactionTypes,
  callQualityTypes,
  modalityTypes,
} from "../CardFiltersData/CardFiltersData";
import TeamsTimePicker from "../../../Shared/uicomponents/TeamsTimePicker";
import WindowContext from "../../../Shared/Context/Context";
import NativeTimePicker from "../../../Shared/uicomponents/NativeTimePicker";

const SingleCardSettings = (props) => {
  const [dashboardId, setDashboardId] = useState(props.dashboardId);
  const [loaded, setLoaded] = useState(false);
  const [cardData, setCardData] = useState(props.cardData);
  const [filterData, setFilterData] = useState(null);
  const [submitButtonState, setSubmitButtonState] = useState(0);

  const [cardNameInput, setCardNameInput] = useState("");
  const [cardName, setCardName] = useState("");
  const [date, setDate] = useState(1);
  const [time, setTime] = useState(0);
  const [startTime, setStartTime] = useState("");
  const [endTime, setEndTime] = useState("");
  const [teams, setTeams] = useState([]);
  const [teamsDisabled, setTeamsDisabled] = useState(false);
  const [groups, setGroups] = useState([]);
  const [groupsDisabled, setGroupsDisabled] = useState(false);
  const [users, setUsers] = useState([]);
  const [dateItems, setDateItems] = useState({});
  const [timeItems, setTimeItems] = useState({});
  const [usersDisabled, setUsersDisabled] = useState(false);
  const [modalities, setModalities] = useState({});
  const [callQualities, setCallQualities] = useState({});
  const [sentiments, setSentiments] = useState({});
  const [reactions, setReactions] = useState({});
  const [messages, setMessages] = useState(false);
  const [isUpdate, setIsUpdate] = useState(false);
  const [filterTypes, setFilterTypes] = useState([]);
  const [filterTypeId, setFilterTypeId] = useState(0);
  const [filterType, setFilterType] = useState(FilterTypes[0]);
  const [teamIsGroup, setTeamIsGroup] = useState(false);
  const [teamIsGroupDisabled, setTeamIsGroupDisabled] = useState(false);

  const [isValid, setIsValid] = useState(false);
  const [expandPeople, setExpandPeople] = useState(0);
  const [peopleMasKList, setPeopleMaskList] = useState([]);

  const checkValid = (_filterType, _filterOption, _selectCount) => {
    if (_filterType === 0 && card_filters[cardData.cardFilterID].people !== 0) {
      if (_filterOption === 3) {
        return _selectCount === 2 ? true : false;
      } else {
        return _selectCount >= 1 ? true : false;
      }
    } else if (
      _filterType === 1 &&
      card_filters[cardData.cardFilterID].teams !== 0
    ) {
      if (_filterOption === 3) {
        return _selectCount === 2 ? true : false;
      } else {
        return _selectCount >= 1 ? true : false;
      }
    } else if (
      _filterType === 2 &&
      card_filters[cardData.cardFilterID].groups !== 0
    ) {
      if (_filterOption === 3) {
        return _selectCount === 2 ? true : false;
      } else {
        return _selectCount >= 1 ? true : false;
      }
    } else {
      return true;
    }
  };

    const setDisableState = (_filterType, _filterOption, _selectCount) => {
        let _shouldDisable = false;
        if (_filterOption === 3) {
            _shouldDisable = _selectCount === 2 ? true : false;
        }
        else if (_filterOption === 1) {
            _shouldDisable = _selectCount === 1 ? true : false;
        }

        switch (_filterType) {
            case 0:
                setUsersDisabled(_shouldDisable);
                break;
            case 1:
                setTeamsDisabled(_shouldDisable);
                break;
            case 3:
                setGroupsDisabled(_shouldDisable);
                break;
        }
    };


  const setStates = (_filterData, _cardData) => {
    setSubmitButtonState(0);
    setExpandPeople(0);
    setPeopleMaskList([]);
    setFilterTypes([]);
    setFilterTypeId(0);
    setFilterType(FilterTypes[0]);
    setCardData(_cardData);
    let settingsData = {};
    let _isUpdate = false;
    if (_cardData && _cardData.cardID !== -1) {
      settingsData = _cardData;
      setIsUpdate(true);
      _isUpdate = true;
    } else {
        let modalitie = [0, 1, 2];
        if (cardData.cardWidgetID === 5) {
            modalitie = [0, 1];
        }
        
      settingsData = {
        name: _cardData.widgetName,
        runPeriod: 3,
        timePeriod: 2,
        selectedStartTime: "00:00",
        selectedEndTime: "23:59",
        groups: [],
        teams: [],
        teamIsGroup: false,
        users: [],
          modalities: modalitie,
        sentiment: [0, 1, 2],
        callQuality: [0, 1, 2],
        reactions: [0, 1, 2, 3, 4, 5, 6],
        messages: true,
      };
    }

    let _filterTypes = JSON.parse(JSON.stringify(FilterTypes));
    if (card_filters[_cardData.cardFilterID].users !== 0) {
      _filterTypes[0].isApplicable = true;
    }
    if (card_filters[_cardData.cardFilterID].teams !== 0) {
      _filterTypes[1].isApplicable = true;
    }
    if (card_filters[_cardData.cardFilterID].groups !== 0) {
      _filterTypes[2].isApplicable = true;
    }

    setFilterTypes(_filterTypes);
    let _selectedFilterTypeId = 0;

    if (settingsData.users && settingsData.users.length > 0) {
      _selectedFilterTypeId = 0;
    } else if (settingsData.teams && settingsData.teams.length > 0) {
      _selectedFilterTypeId = 1;
    } else if (settingsData.groups && settingsData.groups.length > 0) {
      _selectedFilterTypeId = 2;
    } else {
      _selectedFilterTypeId =
        _filterTypes.filter((ft) => ft.isApplicable === true).length > 0
          ? _filterTypes.filter((ft) => ft.isApplicable === true)[0].value
          : 0;
    }
    setFilterTypeId(_selectedFilterTypeId);
    setFilterType(FilterTypes[_selectedFilterTypeId]);

    setTeamIsGroup(settingsData.teamIsGroup);
    setCardNameInput(settingsData.name);
    setCardName(settingsData.name);
    setDate(settingsData.runPeriod);
    setTime(settingsData.timePeriod);
    setStartTime(
      settingsData.selectedStartTime != ""
        ? settingsData.selectedStartTime
        : "00:00"
    );
    setEndTime(
      settingsData.selectedEndTime != ""
        ? settingsData.selectedEndTime
        : "23:59"
    );
    let _teams = _filterData.teamFilter.items.filter((d) =>
      settingsData.teams.includes(d.value)
    );
    if(_isUpdate && settingsData.expiredFilterType === 1 && settingsData.expiredFilterValues){
      _teams = [..._teams, ...settingsData.expiredFilterValues];
    }
    setTeams(_teams);
    let isAllTeamAreGroup = _teams.filter(el => !el.teamIsGroup).length === 0;
    if(isAllTeamAreGroup){
      setTeamIsGroupDisabled(false);
    }else{
      setTeamIsGroupDisabled(true);
      setTeamIsGroup(false);
    }

    let _groups = _filterData.groupFilter.items.filter((d) =>
      settingsData.groups.includes(d.value)
    );
    if(_isUpdate && settingsData.expiredFilterType === 2 && settingsData.expiredFilterValues){
      _groups = [..._groups, ...settingsData.expiredFilterValues];
    }
    setGroups(_groups);
    let _users = _filterData.userFilter.items.filter((d) =>
      settingsData.users.includes(d.value)
    );
    if(_isUpdate && settingsData.expiredFilterType === 0 && settingsData.expiredFilterValues){
      _users = [..._users, ...settingsData.expiredFilterValues];
    }
    setUsers(_users);

    setDateItems(_filterData.dateFilter.items);
    setTimeItems(_filterData.timeFilter.items);
    let m = {};
    settingsData.modalities.map((d) => (m[d] = true));
    setModalities(m);
    let cq = {};
    settingsData.callQuality.map((d) => (cq[d] = true));
    setCallQualities(cq);
    let r = {};
    settingsData.reactions.map((d) => (r[d] = true));
    setReactions(r);
    let s = {};
    settingsData.sentiment.map((d) => (s[d] = true));
    setSentiments(s);
    setMessages(settingsData.messages);

    let _selectCount = 0,
      _filterType = -1,
      _filterOption = 0;
    if (_filterTypes.filter((ft) => ft.isApplicable === true).length > 0) {
      if (_selectedFilterTypeId === 0) {
        _filterType = 0;
        _filterOption = card_filters[_cardData.cardFilterID].users;
        _selectCount = _filterData.userFilter.items.filter((d) =>
          settingsData.users.includes(d.value)
        ).length;
      } else if (_selectedFilterTypeId === 1) {
        _filterType = 1;
        _filterOption = card_filters[_cardData.cardFilterID].teams;
        _selectCount = _filterData.teamFilter.items.filter((d) =>
          settingsData.teams.includes(d.value)
        ).length;
      } else if (_selectedFilterTypeId === 2) {
        _filterType = 2;
        _filterOption = card_filters[_cardData.cardFilterID].groups;
        _selectCount = _filterData.groupFilter.items.filter((d) =>
          settingsData.groups.includes(d.value)
        ).length;
      }
    } else {
      _filterType = -1;
    }
    setIsValid(checkValid(_filterType, _filterOption, _selectCount));
    setDisableState(_filterType, _filterOption, _selectCount);

    setLoaded(true);
    setTimeout(function () {
      document
        .querySelectorAll(".k-dialog .ui-input__input")
        .forEach((item) => {
          item.addEventListener("keydown", function (e) {
            if (e.keyCode === 32) {
              e.stopPropagation();
            }
          });
        });
    }, 1000);
  };
  useEffect(() => {
    setLoaded(false);
    let data = props.userFiltersData;
    setFilterData(data);
    setStates(data, props.cardData);
  }, []);

  useEffect(() => {
    //assign default stored values
    if (filterData && props.cardData) {
      setLoaded(false);
      setStates(filterData, props.cardData);
    }
  }, [props.cardData]);

  const handleTeamChange = (value, filterOption) => {
    var isAllTeamAreGroup = value.filter(el => !el.teamIsGroup).length === 0;
    if(isAllTeamAreGroup){
      setTeamIsGroupDisabled(false);
    }else{
      setTeamIsGroupDisabled(true);
      setTeamIsGroup(false);
    }
    if (filterOption !== 1) {
      setTeams(value);
    } else {
      setTeams([value]);
    }

      if (filterOption === 3) {
          setTeamsDisabled(value.length === 2 ? true : false);
      }
      else if (filterOption === 1) {
          setTeamsDisabled(value.length === 1 ? true : false);
      }


    setIsValid(checkValid(1, filterOption, filterOption !== 1 ? value.length : 1));
  };

  const handleGroupChange = (value, filterOption) => {
    if (filterOption !== 1) {
      setGroups(value);
    } else {
      setGroups([value]);
    }

      if (filterOption === 3) {
          setGroupsDisabled(value.length === 2 ? true : false);
      }
      else if (filterOption === 1) {
          setGroupsDisabled(value.length === 1 ? true : false);
      }


    setIsValid(checkValid(2, filterOption, filterOption !== 1 ? value.length : 1));
  };
  const handleUserChange = (value, filterOption) => {
    // if (filterOption !== 1) {
    //   setUsers(value);
    // } else {
    //   setUsers([value]);
    // }
    setUsers(value);

    if (filterOption === 3) {
      setUsersDisabled(value.length === 2 ? true : false);
    }
    if (filterOption === 1) {
      setUsersDisabled(value.length === 1 ? true : false);
    }

    setIsValid(checkValid(0, filterOption, filterOption !== 1 ? value.length : 1));
  };

  const handleSubmit = (dataItem) => {
    setSubmitButtonState(1);

    let settingsData = {
      cardId: cardData.cardID,
      dashboardId: dashboardId,
      Name: cardNameInput,
      widgetId: cardData.cardWidgetID,
      runPeriod: date,
      timePeriod: time,
      selectedStartTime: time === 2 ? startTime : "",
      selectedEndTime: time === 2 ? endTime : "",
      TeamFilter:
        filterTypeId === 1 ? (isUpdate ? teams.filter(f=> f.isExpired != true).map((d) => d.value).toString() : teams.map((d) => d.value).toString()) : "",
      teamIsGroup : filterTypeId === 1 ? teamIsGroup : false,
      GroupFilter:
        filterTypeId === 2 ? (isUpdate ? groups.filter(f=> f.isExpired != true).map((d) => d.value).toString() : groups.map((d) => d.value).toString()) : "",
      userFilter:
        filterTypeId === 0 ? (isUpdate ? users.filter(f=> f.isExpired != true).map((d) => d.value).toString(): users.map((d) => d.value).toString()) : "",
      callQualityFilter: Object.keys(callQualities)
        .filter((i) => callQualities[i] === true)
        .toString(),
      sentimentFilter: Object.keys(sentiments)
        .filter((i) => sentiments[i] === true)
        .toString(),
      reactionFilter: Object.keys(reactions)
        .filter((i) => reactions[i] === true)
        .toString(),
      modalitiesFilter: Object.keys(modalities)
        .filter((i) => modalities[i] === true)
        .toString(),
      messagesFilter: card_filters[cardData.cardFilterID].messages === 1,
      xPosition: 0,
      yPosition: 0,
      // isDemo: isDemo,
    };
    if (isUpdate) {
      AuthHelper.getAccessToken(function (token) {
        FiltersApiService.UpdateCardWithFilters(settingsData, token)
          .then((response) => {
            if (response) {
                setSubmitButtonState(2);
                if (filterTypeId === 1) {
                    setTeams(teams.filter(f => f.isExpired != true));
                }
                else if (filterTypeId === 2) {
                    setGroups(groups.filter(f => f.isExpired != true));
                }
                else if (filterTypeId === 0) {
                    setUsers(users.filter(f => f.isExpired != true))
                }
              props.submitHandler(settingsData);
            } else {
              setSubmitButtonState(3);
            }
          })
          .finally(() => {
            setTimeout(function () {
              setSubmitButtonState(0);
            }, 1000);
          });
      });
    } else {
      AuthHelper.getAccessToken(function (token) {
        FiltersApiService.AddCardWithFilters(settingsData, token)
          .then((response) => {
            if (response) {
              setSubmitButtonState(2);
              props.submitHandler();
            } else {
              setSubmitButtonState(3);
            }
          })
          .finally(() => {
            setTimeout(function () {
              setSubmitButtonState(0);
            }, 1000);
          });
      });
    }
  };

  const handleKeyDown = (e) => {
    if (e.keyCode === 32) {
      e.stopPropagation();
    }
  };

  const onExpandCancel = () => {
    setExpandPeople(0);
  };
  const onExpandConfirm = () => {
    let currentSelection = users;
    currentSelection.splice(
      currentSelection.findIndex((cs) => cs.value === expandPeople),
      1
    );
    if (expandPeople === -1) {
      filterData.userFilter.items
        .filter((f) => f.key > 0)
        .forEach(function (obj, v) {
          if (currentSelection.findIndex((f) => f.value === obj.value) === -1) {
            currentSelection.push(obj);
          }
        });
      setPeopleMaskList([-1, -2]);
    } else if (expandPeople === -2) {
      filterData.userFilter.items
        .filter((f) => f.key > 0 && f.isDirectReportee === true)
        .forEach(function (obj, v) {
          if (currentSelection.findIndex((f) => f.value === obj.value) === -1) {
            currentSelection.push(obj);
          }
        });
      setPeopleMaskList([-2]);
    }
    setUsers(currentSelection);
    setExpandPeople(0);
  };

  const handleCardNameInput = (value) => {
    setCardNameInput(value);
  };

  return (
    <WindowContext.Consumer>
      {(context) =>
        loaded && cardData ? (
          <>
            <Form
              className="formCardSettings"
              onSubmit={(dataItem) => handleSubmit(dataItem)}
              styles={{ fontSize: "0.9rem" }}
            >
              <Segment styles={{ width: "100%", marginBottom: "0.6rem" }}>
                <Form.Input
                  label="Card Name"
                  name="cardName"
                  required
                  inline
                  fluid
                  defaultValue={cardName}
                  value={cardNameInput}
                  onChange={(e, { name, value }) => {
                    handleCardNameInput(value);
                  }}
                  className="mb-3"
                  maxLength="50"
                />

                <div className="dropdownSection mb-3">
                  {card_filters[cardData.cardFilterID].date === 1 ? (
                    <Form.Dropdown
                      fluid
                      inline
                      label="Period"
                      items={dateItems}
                      defaultValue={
                        dateItems[
                          dateItems
                            .map(function (e) {
                              return e.value;
                            })
                            .indexOf(date) === -1
                            ? 0
                            : dateItems
                                .map(function (e) {
                                  return e.value;
                                })
                                .indexOf(date)
                        ]
                      }
                      value={
                        dateItems[
                          dateItems
                            .map(function (e) {
                              return e.value;
                            })
                            .indexOf(date) === -1
                            ? 0
                            : dateItems
                                .map(function (e) {
                                  return e.value;
                                })
                                .indexOf(date)
                        ]
                      }
                      onChange={(e, { value }) => {
                        setDate(value.value);
                      }}
                    />
                  ) : (
                    <></>
                  )}
                  {card_filters[cardData.cardFilterID].time === 1 ? (
                    <>
                      <Form.Dropdown
                        fluid
                        inline
                        label="Time"
                        items={timeItems}
                        defaultValue={
                          timeItems[
                            timeItems
                              .map(function (e) {
                                return e.value;
                              })
                              .indexOf(time) === -1
                              ? 0
                              : timeItems
                                  .map(function (e) {
                                    return e.value;
                                  })
                                  .indexOf(time)
                          ]
                        }
                        value={
                          timeItems[
                            timeItems
                              .map(function (e) {
                                return e.value;
                              })
                              .indexOf(time) === -1
                              ? 0
                              : timeItems
                                  .map(function (e) {
                                    return e.value;
                                  })
                                  .indexOf(time)
                          ]
                        }
                        onChange={(e, { value }) => {
                          setTime(value.value);
                        }}
                      />
                    </>
                  ) : (
                    <></>
                  )}

                  {time === 2 ? (
                    <>
                      <div></div>
                      <div className="custom-time-container">
                        <div>
                          <lable>Start Time</lable>
                          {
                            context.teams.hostClientType.web ||
                            context.teams.hostClientType.desktop ? (
                              <TeamsTimePicker
                                required={true}
                                value={startTime}
                                onChange={(value) => {
                                  setStartTime(value);
                                }}
                              />
                            ) : (
                              <NativeTimePicker
                                id="startTime"
                                value={startTime}
                                onChange={(value) => {
                                  setStartTime(value);
                                }}
                                required={true}
                              />
                            )
                            // <Form.Field
                            //   className="timePicker"
                            //   control = {{ as: Input, value: startTime, type: 'time',
                            //     onChange: (e)=>{setStartTime(e.target.value)}
                            //   }}
                            // />
                          }
                        </div>
                        <div>
                          <lable>End Time</lable>
                          {
                            context.teams.hostClientType.web ||
                                                          context.teams.hostClientType.desktop ? (
                                                          <TeamsTimePicker
                                                              required={true}
                                                              min={startTime}
                                                              value={endTime}
                                                              onChange={(value) => {
                                                                  setEndTime(value);
                                                              }}
                                                          />
                                                      ) : (
                                                          <NativeTimePicker
                                                              id="endTime"
                                                              min={startTime}
                                                              value={endTime}
                                                              onChange={(value) => {
                                                                  setEndTime(value);
                                                              }}
                                                              required={true}
                                                          />
                                                      )
                            // <Form.Field
                            //   className="timePicker"
                            //   control = {{ as: Input, value: endTime, type: 'time',
                            //     onChange: (e)=>{setEndTime(e.target.value)}
                            //   }}
                            // />
                          }
                        </div>
                      </div>
                    </>
                  ) : (
                    <></>
                  )}
                </div>
                <div className="dropdownSection mb-10 groupFilter">
                  {filterTypes.filter((ft) => ft.isApplicable === true).length >
                  0 ? (
                    <>
                      <Form.Dropdown
                        fluid
                        inline
                        label="People/Team/Group"
                        items={filterTypes.filter(
                          (ft) => ft.isApplicable === true
                        )}
                        value={FilterTypes[filterTypeId]}
                        defaultValue={FilterTypes[filterTypeId]}
                        getA11ySelectionMessage={{
                          onAdd: (item) => {
                            setFilterTypeId(item.value);
                            let _selectCount = 0,
                              _filterOption = 0;
                            if (item.value === 0) {
                              _filterOption =
                                card_filters[cardData.cardFilterID].users;
                              _selectCount = users.length;
                            } else if (item.value === 1) {
                              _filterOption =
                                card_filters[cardData.cardFilterID].teams;
                              _selectCount = teams.length;
                            } else if (item.value === 2) {
                              _filterOption =
                                card_filters[cardData.cardFilterID].groups;
                              _selectCount = groups.length;
                            }
                            setIsValid(
                              checkValid(
                                item.value,
                                _filterOption,
                                _selectCount
                              )
                            );
                          },
                        }}
                      />
                      {filterTypeId === 2 &&
                      card_filters[cardData.cardFilterID].groups !== 0 ? (
                        <Form.Dropdown
                          fluid
                          inline
                          label={`Group${
                            card_filters[cardData.cardFilterID].groups !== 1
                              ? "s"
                              : ""
                          } ${
                            card_filters[cardData.cardFilterID].groups === 3
                              ? "(only 2)"
                              : ""
                          }`}
                          search={
                            card_filters[cardData.cardFilterID].groups !== 1
                          }
                          multiple={
                            card_filters[cardData.cardFilterID].groups !== 1
                          }
                          disabled={groupsDisabled}
                          placeholder={
                            card_filters[cardData.cardFilterID].groups !== 1
                              ? "Start typing a name"
                              : "Select Group"
                          }
                          items={filterData.groupFilter.items}
                          defaultValue={groups}
                          value={groups}
                          onChange={(e, { value }) => {
                            handleGroupChange(
                              value,
                              card_filters[cardData.cardFilterID].groups
                            );
                          }}
                          noResultsMessage={
                            card_filters[cardData.cardFilterID].groups !== 1
                              ? "We couldn't find any matches."
                              : "No groups found."
                          }
                          itemToValue={(obj) => {
                            return obj.key;
                        }}
                        renderSelectedItem={(Component, props) => {
                            if (props.isExpired) {
                                props.className =
                                    props.className + " _unauthorized";
                            }
                            return <Component {...props}></Component>;
                        }}
                        />
                      ) : (
                        <></>
                      )}
                      {filterTypeId === 1 &&
                      card_filters[cardData.cardFilterID].teams !== 0 ? (
                        <Form.Dropdown
                          fluid
                          inline
                          label={`Team${
                            card_filters[cardData.cardFilterID].teams !== 1
                              ? "s"
                              : ""
                          } ${
                            card_filters[cardData.cardFilterID].teams === 3
                              ? "(only 2)"
                              : ""
                          }`}
                          search={
                            card_filters[cardData.cardFilterID].teams !== 1
                          }
                          multiple={
                            card_filters[cardData.cardFilterID].teams !== 1
                          }
                          disabled={teamsDisabled}
                          placeholder={
                            card_filters[cardData.cardFilterID].teams !== 1
                              ? "Start typing a name"
                              : "Select Team"
                          }
                          items={filterData.teamFilter.items}
                          defaultValue={teams}
                          value={teams}
                          onChange={(e, { value }) => {
                            handleTeamChange(
                              value,
                              card_filters[cardData.cardFilterID].teams
                            );
                          }}
                          noResultsMessage={
                            card_filters[cardData.cardFilterID].teams !== 1
                              ? "We couldn't find any matches."
                              : "No teams found."
                          }
                          itemToValue={(obj) => {
                            return obj.key;
                        }}
                        renderSelectedItem={(Component, props) => {
                            if (props.isExpired) {
                                props.className =
                                    props.className + " _unauthorized";
                            }
                            return <Component {...props}></Component>;
                        }}
                        />
                      ) : (
                        <></>
                      )}
                      {filterTypeId === 0 &&
                      card_filters[cardData.cardFilterID].users !== 0 ? (
                        <Form.Dropdown
                          fluid
                          inline
                          label={`People${
                            card_filters[cardData.cardFilterID].users !== 1
                              ? ""
                              : "(only 1)"
                          } ${
                            card_filters[cardData.cardFilterID].users === 3
                              ? "(only 2)"
                              : ""
                          }`}
                          search
                          multiple
                          disabled={usersDisabled}
                          placeholder={
                            card_filters[cardData.cardFilterID].users !== 1
                              ? "Start typing a name"
                              : "Select People"
                          }
                          items={
                            card_filters[cardData.cardFilterID].users === 2
                              ? filterData.userFilter.items.filter(
                                  (uf) => !peopleMasKList.includes(uf.key)
                                )
                              : filterData.userFilter.items.filter(
                                  (uf) => uf.key > 0
                                )
                          }
                          defaultValue={users}
                          value={users}
                          onChange={(e, { value }) => {
                            handleUserChange(
                              value,
                              card_filters[cardData.cardFilterID].users
                            );
                          }}
                          noResultsMessage={
                            card_filters[cardData.cardFilterID].users !== 1
                              ? "We couldn't find any matches."
                              : "No users found."
                          }
                          itemToValue={(obj) => {
                            return obj.key;
                          }}
                          renderSelectedItem={(Component, props) => {
                            if (props.key < 0) {
                              props.className =
                                props.className + " _expandable";
                              props.onClick = (n, a) => {
                                if (
                                  !n.currentTarget.classList.contains(
                                    "ui-dropdown__selecteditem__icon"
                                  )
                                ) {
                                  setExpandPeople(props.key);
                                }
                              };
                            }
                            else if(props.isExpired){
                              props.className =
                                props.className + " _unauthorized";
                            }
                            return <Component {...props}></Component>;
                          }}
                        />
                      ) : (
                        <></>
                      )}
                    </>
                  ) : (
                    <></>
                  )}
                </div>
                <div className="dropdownSection mt-2 groupFilter">
                  <div className="mb-2">
                    {(filterTypeId === 1 && filterData.teamFilter.items.length === 0) ?
                      <Text>You do not have permission to any team. Please get team permissions in settings</Text>
                      : <></>
                    }
                    {(filterTypeId === 2 && filterData.groupFilter.items.length === 0) ?
                      <Text>You do not have permission to any group. Please get group permissions in settings</Text>
                      : <></>
                    }
                  </div>
                  <div className="mb-2">
                    {filterTypeId === 1 && (card_filters[cardData.cardFilterID].teams !== 0 && card_filters[cardData.cardFilterID].groups !== 0) ?
                      <Form.Checkbox
                        label="Include reporting on activity outside selected Teams"
                        value={teamIsGroup}
                        checked={teamIsGroup}
                        disabled={teamIsGroupDisabled}
                        onChange={(e, { checked }) => {setTeamIsGroup(checked)}}
                      />
                      : <></>
                    }
                  </div>
                </div>
              </Segment>
              <Flex fill gap="gap.small" styles={{ marginBottom: "0.6rem" }}>
                {card_filters[cardData.cardFilterID].modalities === 1 ||
                card_filters[cardData.cardFilterID].callQuality === 1 ? (
                  <Flex.Item>
                    <Segment styles={{ width: "100%" }}>
                      <Header as="h6" content={`Calls & Meetings`} />
                      <Flex
                        fill
                        column
                        vAlign="stretch"
                        styles={{
                          height: "calc(100% - 2em)",
                        }}
                      >
                        {card_filters[cardData.cardFilterID].modalities ===
                        1 ? (
                          <Flex wrap column className="mb-1">
                            <label className="mb-0">Type</label>
                            <Flex.Item>
                              <Flex wrap>
                                {modalityTypes.map((modalityType) => {
                                  return (
                                    <Form.Checkbox
                                      key={modalityType.modalityID}
                                      checked={
                                        modalities[modalityType.modalityID] ===
                                        true
                                      }
                                      label={modalityType.modalityName}
                                          value={modalityType.modalityID}
                                          style={{ display: cardData.cardWidgetID === 5 && modalityType.modalityID === 2 ? 'none' : 'block' }}
                                      onChange={(e, { checked }) => {
                                        if (checked) {
                                          //add
                                          setModalities({
                                            ...modalities,
                                            [modalityType.modalityID]: true,
                                          });
                                        } else {
                                          //remove
                                          setModalities({
                                            ...modalities,
                                            [modalityType.modalityID]: false,
                                          });
                                        }
                                      }}
                                    />
                                  );
                                })
                              }
                              </Flex>
                            </Flex.Item>
                          </Flex>
                        ) : (
                          <></>
                        )}
                        {card_filters[cardData.cardFilterID].callQuality ===
                        1 ? (
                          <Flex wrap column className="mb-1">
                            <label className="mb-0">Quality</label>
                            <Flex.Item>
                              <Flex wrap>
                                {callQualityTypes.map((callQualityType) => {
                                  return (
                                    <Form.Checkbox
                                      key={callQualityType.callQualityID}
                                      label={callQualityType.Description}
                                      value={callQualityType.callQualityID}
                                      checked={
                                        callQualities[
                                          callQualityType.callQualityID
                                        ] === true
                                      }
                                      onChange={(e, { checked }) => {
                                        if (checked) {
                                          //add
                                          setCallQualities({
                                            ...callQualities,
                                            [callQualityType.callQualityID]: true,
                                          });
                                        } else {
                                          //remove
                                          setCallQualities({
                                            ...callQualities,
                                            [callQualityType.callQualityID]: false,
                                          });
                                        }
                                      }}
                                    />
                                  );
                                })}
                              </Flex>
                            </Flex.Item>
                          </Flex>
                        ) : (
                          <></>
                        )}
                      </Flex>
                    </Segment>
                  </Flex.Item>
                ) : (
                  <></>
                )}
                {card_filters[cardData.cardFilterID].messages === 1 ||
                card_filters[cardData.cardFilterID].reactions === 1 ||
                card_filters[cardData.cardFilterID].sentiment === 1 ? (
                  <Flex.Item>
                    <Segment styles={{ width: "100%" }}>
                      <Header as="h6" content={`Chat`} />
                      {/* {
                              card_filters[cardData.cardFilterID].messages === 1 ? 
                              <Flex  className="mb-1">
                                  <Form.Checkbox checked={messages} label="Include Chat" toggle onChange={(e, {checked}) => {setMessages(checked)}} />
                              </Flex>
                              :<></>
                          }     */}

                      {card_filters[cardData.cardFilterID].reactions === 1 ? (
                        <Flex wrap column className="mb-1">
                          <label className="mb-0">Reaction</label>
                          <Flex.Item>
                            <Flex wrap>
                              {reactionTypes.map((reactionType) => {
                                return (
                                  <Form.Checkbox
                                    key={reactionType.reactionTypeID}
                                    label={reactionType.Description}
                                    value={reactionType.reactionTypeID}
                                    checked={
                                      reactions[reactionType.reactionTypeID] ===
                                      true
                                    }
                                    onChange={(e, { checked }) => {
                                      if (checked) {
                                        //add
                                        setReactions({
                                          ...reactions,
                                          [reactionType.reactionTypeID]: true,
                                        });
                                      } else {
                                        //remove
                                        setReactions({
                                          ...reactions,
                                          [reactionType.reactionTypeID]: false,
                                        });
                                      }
                                    }}
                                  />
                                );
                              })}
                            </Flex>
                          </Flex.Item>
                        </Flex>
                      ) : (
                        <></>
                      )}
                      {card_filters[cardData.cardFilterID].sentiment === 1 ? (
                        <Flex wrap column className="mb-1">
                          <label className="mb-0">Sentiment</label>
                          <Flex.Item>
                            <Flex wrap>
                              {sentimentTypes.map((sentimentType) => {
                                return (
                                  <Form.Checkbox
                                    key={sentimentType.sentimentAnalysisTypeID}
                                    label={sentimentType.description}
                                    value={
                                      sentimentType.sentimentAnalysisTypeID
                                    }
                                    checked={
                                      sentiments[
                                        sentimentType.sentimentAnalysisTypeID
                                      ] === true
                                    }
                                    onChange={(e, { checked }) => {
                                      if (checked) {
                                        //add
                                        setSentiments({
                                          ...sentiments,
                                          [sentimentType.sentimentAnalysisTypeID]: true,
                                        });
                                      } else {
                                        //remove
                                        setSentiments({
                                          ...sentiments,
                                          [sentimentType.sentimentAnalysisTypeID]: false,
                                        });
                                      }
                                    }}
                                  />
                                );
                              })}
                            </Flex>
                          </Flex.Item>
                        </Flex>
                      ) : (
                        <></>
                      )}
                    </Segment>
                  </Flex.Item>
                ) : (
                  <></>
                )}
              </Flex>

              <Flex
                gap="gap.small"
                fill
                hAlign="end"
                styles={{ paddingBottom: "0.5rem", marginTop: "0.5rem" }}
              >
                {!isUpdate && (
                  <Form.Button
                    content="Cancel"
                    onClick={() => props.cancelHandler()}
                  />
                )}
                <Form.Button
                  disabled={isValid === false || submitButtonState !== 0}
                  icon={submitButtonState === 2 && <AcceptIcon />}
                  loading={submitButtonState === 1}
                  content={
                    submitButtonState === 0
                      ? "Save"
                      : submitButtonState === 1
                      ? "Saving"
                      : submitButtonState === 2
                      ? "Saved"
                      : "Failed"
                  }
                  primary
                />
              </Flex>
            </Form>

            <Dialog
              style={{ maxWidth: "300px" }}
              backdrop={true}
              open={expandPeople < 0}
              footer={{
                children: (Component, props) => {
                  const { styles, ...rest } = props;
                  return (
                    <Flex styles={styles} hAlign="center" gap="gap.small">
                      <Button
                        size="small"
                        content="Cancel"
                        onClick={onExpandCancel}
                      />
                      <Button
                        size="small"
                        primary
                        content="Expand"
                        onClick={onExpandConfirm}
                      />
                    </Flex>
                  );
                },
              }}
              content={
                <>
                  <Flex
                    fill
                    hAlign="center"
                    style={{ paddingBottom: "15px" }}
                    gap="gap.small"
                  >
                    <ExclamationTriangleIcon
                      size="largest"
                      styles={{ color: "#fce100" }}
                    />
                    <Flex.Item>
                      <Text content="If you expand the list, the list will be replaced by individual members. It is not possible to collapse the list again." />
                    </Flex.Item>
                  </Flex>
                </>
              }
            />
          </>
        ) : (
          <Loader />
        )
      }
    </WindowContext.Consumer>
  );
};

export default SingleCardSettings;
