import { useState, useEffect } from "react";
import _ from "lodash";
import "./dashboard.scss";

import "../../../../node_modules/react-grid-layout/css/styles.css";
import "../../../../node_modules/react-resizable/css/styles.css";
import { useHistory, Switch } from "react-router-dom";

import DashboardApiService from "../../../services/DashboardApiService";
import FiltersApiService from "../../../services/FiltersApiService";
import AuthHelper from "../../../services//auth-helper";
import AuthenticatedRoute from "../../RouteHelper";
import {
  Dropdown,
  Tooltip,
  Button,
  Flex,
  Text,
  Loader,
  SettingsAudioIcon,
  operating,
  } from "@fluentui/react-northstar";
import {
  AddIcon,
  BulletsIcon,
  ArrowLeftIcon,
} from "@fluentui/react-icons-northstar";
import AddCard from "./addcard";
import EditCard from "./editcard";
import GridComponent from "./DashGridLayout";

import WindowContext from "../../Shared/Context/Context";
import NativeDatePicker from "../../Shared/uicomponents/NativeDatePicker";
import TeamsDatePicker from "../../Shared/uicomponents/TeamsDatePicker";
import { Dialog } from "@progress/kendo-react-dialogs";
import MultiCardSettings from "../../filters/card/MultiCardSettings/MultiCardSettingsEdit";

const DashGrid = (props) => {
  const history = useHistory();

  const handleNavigationClick = (param, isDemo) => {
    history.push("/dashboards/" + param);
    if (global.localStorage) {
      global.localStorage.setItem(
        "contentUrl_dashboards",
        "/dashboards/" + param
      );
      global.localStorage.setItem("demo", isDemo);
    }
  };
  const [dashboardList, setDashboardList] = useState([]);
  const [demoDashboardList, setDemoDashboardList] = useState([]);
  const [userDashboardList, setUserDashboardList] = useState([]);
  const [isDemo, setIsDemo] = useState(false);

const [open, setOpen] = useState(false);
const onCancel = () => {
setOpen(false);
};
const onConfirm = () => {
setOpen(false);
window.location.reload();
};



  const [dashboardID, setDashboardID] = useState(
    Number(props.match.params.dashboardId)
  );
  const [operation, setOperation] = useState(props.match.params.operation);
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [editCardID, setEditCardID] = useState(-1);
  const [userFiltersData, setUserFiltersData] = useState(null);

  useEffect(() => {
    setOperation(props.match.params.operation);
  }, [props.match.params.operation]);

  useEffect(() => {
    //debugger;
    AuthHelper.getAccessToken(function (token) {
      DashboardApiService.GetDashboards(token).then((response) => {
        if (response != null) {
          if (response.length > 0) {
            if (
              response.findIndex((d) => d.userDashboardsID === dashboardID) ===
              -1
            ) {
              let dId = response[0].userDashboardsID;
              setDashboardID(dId);
              setSelectedDate(
                response.find((d) => d.value === String(dId))
                  ? response.find((d) => d.value === String(dId)).dateSelected
                  : new Date()
              );
              history.push(`/dashboards/${operation}/${dId}`);
              if (global.localStorage) {
                global.localStorage.setItem(
                  "contentUrl_dashboards",
                  `/dashboards/${operation}/${dId}`
                );
              }
            } else {
              setSelectedDate(
                response.find((d) => d.userDashboardsID === dashboardID) &&
                  response.find((d) => d.userDashboardsID === dashboardID)
                    .dateSelected
                  ? convertDate(
                      new Date(
                        response.find(
                          (d) => d.userDashboardsID === dashboardID
                        ).dateSelected
                      )
                    )
                  : new Date()
              );
            }

            setUserDashboardList(
              response.map((d) => {
                return {
                  key: d.userDashboardsID,
                  value: String(d.userDashboardsID),
                  header: d.name,
                  dateSelected: d.dateSelected
                    ? new Date(d.dateSelected)
                    : new Date(),
                };
              })
            );

            let demoDashboards = [];
            let dashboards = [];

            response.forEach((e) => {
              if (e.isDemo === true) {
                demoDashboards.push(e);
              }
              if (e.isDemo === false) {
                dashboards.push(e);
              }
            });

            setDemoDashboardList(
              demoDashboards.map((d) => {
                return {
                  key: d.userDashboardsID,
                  value: String(d.userDashboardsID),
                  header: d.name,
                  dateSelected: d.dateSelected
                    ? new Date(d.dateSelected)
                    : new Date(),
                };
              })
            );

            setDashboardList(
              dashboards.map((d) => {
                return {
                  key: d.userDashboardsID,
                  value: String(d.userDashboardsID),
                  header: d.name,
                  dateSelected: d.dateSelected
                    ? new Date(d.dateSelected)
                    : new Date(),
                };
              })
            );
          } else {
            history.replace("/dashboards");
          }
        }
      });
      FiltersApiService.GetUserData(token).then((response) => {
        let data = response;
        data.teamFilter.items = data.teamFilter.items.map((obj) => ({
          ...obj,
          key: obj.value,
        }));
        data.groupFilter.items = data.groupFilter.items.map((obj) => ({
          ...obj,
          key: obj.value,
        }));
        data.userFilter.items = data.userFilter.items.map((obj) => ({
          ...obj,
          key: obj.value,
        }));
        setUserFiltersData(data);
      });
    });
  }, [dashboardID]);

  useEffect(() => {
    if (demoDashboardList.some((e) => e.key === dashboardID)) {
      setIsDemo(true);
    } else {
      setIsDemo(false);
    }
  }, [dashboardID, demoDashboardList]);

  const convertDate = (str) => {
    var date = new Date(str),
      month = "" + (date.getMonth() + 1),
      day = "" + date.getDate(),
      year = date.getFullYear();

    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;

    return [year, month, day].join("-");
  };

  const handleDashboardChange = (dId) => {
    if (dId !== dashboardID) {
      setEditCardID(-1);
      setDashboardID(dId);
      setSelectedDate(
        dashboardList.find((d) => d.value === String(dId))
          ? dashboardList.find((d) => d.value === String(dId)).dateSelected
          : new Date()
      );
      history.push(`/dashboards/${operation}/${dId}`);
      if (global.localStorage) {
        global.localStorage.setItem(
          "contentUrl_dashboards",
          `/dashboards/${operation}/${dId}`
        );
      }
    }
  };
  const toggleOperation = (opr) => {
    setOperation(opr);
    handleNavigationClick(`${opr}/${dashboardID}`);
  };
  const onCardEdit = (cardID) => {
    setEditCardID(cardID);
    setOperation("edit");
    handleNavigationClick(`edit/${dashboardID}`);
  };

  const updateSelectedDate = (selectedValue) => {
    if (isDemo) {
      setSelectedDate(selectedValue);
    } else {
      AuthHelper.getAccessToken((token) => {
        DashboardApiService.UpdateDashboardSelectedDate(
          dashboardID,
          (new Date).toDateString() == (new Date(selectedValue)).toDateString() ? null : selectedValue,
          token
        ).then((response) => {
          if (response === "Success") {
            dashboardList.find(
              (d) => d.value === String(dashboardID)
            ).dateSelected = selectedValue;
            setSelectedDate(selectedValue);
          } else {
            setSelectedDate(
              dashboardList.find((d) => d.value === String(dashboardID))
                .dateSelected
                ? dashboardList.find((d) => d.value === String(dashboardID))
                    .dateSelected
                : new Date()
            );
          }
        });
      });
    }
  };

  return (
    <WindowContext.Consumer>
      {(context) =>
        (dashboardList.length || demoDashboardList.length) > 0 ? (
          <>
            <Flex
              space="between"
              wrap
              className="dashboardheader mb-1"
              styles={{ padding: "0 8px" }}
            >
              {operation === "view" ? (
                <>
                  <Dropdown
                    className="mb-1 dashboard-selectbox"
                    styles={{ width: 200 }}
                    items={isDemo ? demoDashboardList : dashboardList}
                    placeholder="Select your dashboard"
                    checkable
                    defaultValue={
                      userDashboardList.find(
                        (d) => d.value === String(dashboardID)
                      )
                      //dashboardList.find((d) => d.value === String(dashboardID))
                    }
                    onChange={(e, selectedOption) =>
                      handleDashboardChange(Number(selectedOption.value.value))
                    }
                    itemToValue={(obj) => {
                      return obj.key;
                    }}
                  />
                  <Flex
                    gap="gap.small"
                    vAlign="center"
                    styles={{ position: "relative" }}
                  >
                    <Flex gap="gap.smaller" className="mb-1" vAlign="center">
                      <Text content="Anchor Date:" />
                      {context.teams.hostClientType.web ||
                      context.teams.hostClientType.desktop ? (
                        <TeamsDatePicker
                          value={selectedDate}
                          onChange={(value) => updateSelectedDate(value)}
                        />
                      ) : (
                        <NativeDatePicker
                          id="anchor-date"
                          value={selectedDate}
                          onChange={(value) => updateSelectedDate(value)}
                        />
                      )}
                    </Flex>
                  <Tooltip
                  trigger={
                  <Button
                  disabled={operating}
                  icon={<SettingsAudioIcon rotate={90} />}
                  iconOnly
                  text
                  onClick={() => setOpen(true)}
                  />
                  }
                  content="Edit existing Card"
                  />
                  {open && (
                  <Dialog
                  title="Quick Edit"
                  autoFocus={true}
                  onClose={() => onCancel()}
                  width={500}
                  // height={380}
                  >
                  <MultiCardSettings
                  dashboardId={dashboardID}
                  submitHandler={onConfirm}
                  cancelHandler={onCancel}
                  userFiltersData={userFiltersData}
                  quickEdit={true}
                  onE
                  />
                  </Dialog>
                  )}


                    <Tooltip
                      trigger={
                        <Button
                          className="mb-1"
                          icon={<AddIcon size="large" />}
                          text
                          iconOnly
                          onClick={() => toggleOperation("add")}
                        />
                      }
                      content="Add New Card"
                    />

                    <Tooltip
                      trigger={
                        <Button
                          // className="mb-1"
                          icon={<BulletsIcon />}
                          iconOnly
                          onClick={() => handleNavigationClick("", isDemo)}
                          primary
                        />
                      }
                      content="View My Dashboards"
                    />
                  </Flex>
                </>
              ) : operation === "edit" ? (
                <>
                  <Flex
                    gap="gap.small"
                    vAlign="center"
                    styles={{
                      position: "relative",
                      justifyContent: "space-between",
                    }}
                    fill
                  >
                    <Text size="medium" weight="regular">
                      Edit Card |{" "}
                      <Dropdown
                        inline
                        // className="mb-1 dashboard-selectbox"
                        // styles={{ width: 200 }}
                        items={isDemo ? demoDashboardList : dashboardList}
                        placeholder="Select your dashboard"
                        checkable
                        defaultValue={userDashboardList.find(
                          (d) => d.value === String(dashboardID)
                        )}
                        onChange={(e, selectedOption) =>
                          handleDashboardChange(
                            Number(selectedOption.value.value)
                          )
                        }
                        itemToValue={(obj) => {
                          return obj.key;
                        }}
                      />
                    </Text>
                    <Flex gap="gap.smaller">
                      <Tooltip
                        trigger={
                          <Button
                            className="mb-1"
                            icon={<ArrowLeftIcon />}
                            content="Dashboard"
                            onClick={() => {
                              toggleOperation("view");
                            }}
                          />
                        }
                        content="Back to Dashboard"
                      />
                      <Tooltip
                        trigger={
                          <Button
                            className="mb-1"
                            icon={<BulletsIcon />}
                            iconOnly
                            onClick={() => handleNavigationClick("", isDemo)}
                            primary
                          />
                        }
                        content="View My Dashboards"
                      />
                    </Flex>
                  </Flex>
                </>
              ) : (
                <>
                  {" "}
                  <Flex
                    gap="gap.small"
                    vAlign="center"
                    styles={{
                      position: "relative",
                      justifyContent: "space-between",
                    }}
                    fill
                  >
                    <Text size="medium" weight="regular">
                      Add Card |{" "}
                      <Dropdown
                        inline
                        // className="mb-1 dashboard-selectbox"
                        // styles={{ width: 200 }}
                        items={isDemo ? demoDashboardList : dashboardList}
                        placeholder="Select your dashboard"
                        checkable
                        defaultValue={userDashboardList.find(
                          (d) => d.value === String(dashboardID)
                        )}
                        onChange={(e, selectedOption) =>
                          handleDashboardChange(
                            Number(selectedOption.value.value)
                          )
                        }
                        itemToValue={(obj) => {
                          return obj.key;
                        }}
                      />
                    </Text>
                    <Flex gap="gap.smaller">
                      <Tooltip
                        trigger={
                          <Button
                            className="mb-1"
                            icon={<ArrowLeftIcon />}
                            content="Dashboard"
                            onClick={() => {
                              toggleOperation("view");
                            }}
                          />
                        }
                        content="Back to Dashboard"
                      />
                      <Tooltip
                        trigger={
                          <Button
                            className="mb-1"
                            icon={<BulletsIcon />}
                            iconOnly
                            onClick={() => handleNavigationClick("", isDemo)}
                            primary
                          />
                        }
                        content="View My Dashboards"
                      />
                    </Flex>
                  </Flex>
                </>
              )}
            </Flex>
            <div style={{ height: "calc(100vh - 70px)", overflowY:"auto" }}>
            <Switch>
              <AuthenticatedRoute exact path="/dashboards/view/:dashboardId">
                <GridComponent
                  demo={isDemo}
                  dashboardId={dashboardID}
                  addHandler={toggleOperation}
                  onCardEdit={onCardEdit}
                  selectedDate={selectedDate}
                />
              </AuthenticatedRoute>

              <AuthenticatedRoute path="/dashboards/add/:dashboardId">
                <AddCard
                  dashboardId={dashboardID}
                  userFiltersData={userFiltersData}
                  // demo={isDemo}
                />
              </AuthenticatedRoute>
              <AuthenticatedRoute path="/dashboards/edit/:dashboardId">
                <EditCard
                  dashboardId={dashboardID}
                  editCardID={editCardID}
                  userFiltersData={userFiltersData}
                />
              </AuthenticatedRoute>
            </Switch>
            </div>
        </>
        ) : (
          <Loader />
        )
      }
    </WindowContext.Consumer>
  );
};
export default DashGrid;

// {/* <Dialog
//         className="shareDialog"
//         closeOnOutsideClick={true}
//         cancelButton="Close"
//         trigger={<Button className="mb-1" icon={<ShareAltIcon size="larger" />} text iconOnly/>}
//         content={<Text size="large" content="Coming Soon: You will be able to send a link to your dashboard for others users to see this information." />}
//     /> */}
