import {
    Chart,
    ChartSeries,
    ChartSeriesItem,
    ChartSeriesLabels,
    ChartLegend
} from '@progress/kendo-react-charts';
import './DonutChart.scss';

// const donutData = [
//     {
//         name: "Positive",
//         share: 0.6
//     },
//     {
//         name: "Neutral",
//         share: 0.3
//     },
//     {
//         name: "Negative",
//         share: 0.1
//     } 
// ];


export default function DonutChart({data}){
    return (
        <Chart style={{height: '100%'}}>
                <ChartSeries>
                <ChartSeriesItem type="donut" data={data} categoryField="name" field="share">
                    <ChartSeriesLabels color="#fff" background="none" />
                </ChartSeriesItem>
                </ChartSeries>
                <ChartLegend position="top" orientation="horizontal" />
            </Chart>
      );
}
