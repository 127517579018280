import React, { useState, useEffect } from "react";
import {
    Flex,
    Input,
    Checkbox,
    Button,
    Text,
    Tooltip,
    Avatar,
    Loader,
    Label,
    Box,
} from "@fluentui/react-northstar";
import {
    SearchIcon,
    EditIcon,
    ApprovalsAppbarIcon,
    ExclamationTriangleIcon,
    ShiftActivityIcon,
    PresenceAvailableIcon,
    CloseIcon,
} from "@fluentui/react-icons-northstar";
import "./config.scss";


const GroupInheritanceIcon = (props) => {
    return(
        <Tooltip
            className="tooltipTxt"
            trigger={
                <PresenceAvailableIcon
                    size="large"
                    styles={{ marginRight: "10px", color: props.colorValue !== undefined ? props.colorValue : "green" }}
                />
            }
            align="center"
            position="below"
            content={"Via Group Policy"}
        />
    )
}


const TrialExpiredIcon = (props) => {
    return(
        <Tooltip
            trigger={
              <ShiftActivityIcon
                size="large"
                styles={{ marginRight: "10px", color: "grey" }}
              />
            }
            align="center"
            position="below"
            content={"Trial completed"}
        />
    )
}


const LicenseWarningIcon = (props) => {
    return(
        <Tooltip
            trigger={
                <ExclamationTriangleIcon
                    size="large"
                    styles={{ marginRight: "10px", color: "orange" }}
                />
            }
            align="center"
            position="below"
            content={"Licenses not available for Group Policy Inheritance"}
        />
    )
}





export {GroupInheritanceIcon, TrialExpiredIcon, LicenseWarningIcon}

