import React from 'react';
import { Redirect, useLocation } from "react-router-dom";
import { Flex, Button, Text, Segment } from '@fluentui/react-northstar'
import './auth.scss';
import './notprovisioned.scss';

const AccessDenied = () => {
    const { state } = useLocation();
    const { from } = state || { from: { pathname: "/" } };
    const referToreturnUrl = (pathName) => {
        window.location.replace(window.location.origin + pathName);
    }
    return (
        <div class="login-container-msg datatrkMain">
        <div class="innerlogin-msg">

            <div class="signUp-data">
                <div class="signUp-datatrk datatrkL">
                    <div class="signUp-datatrkInr">
                        <div class="datatrk-logo">
                            <a class="navbar-logo" title="Logo">
                                <img src="/logo365.png" alt="Logo" />
                            </a>
                        </div>
                        <div class="datatrk-h">
                            Analytics for Customer Engagement, Collaboration and Wellbeing 
                        </div>
                        <div class="datatrk-p">
                            Analytics 365 is a native app for Microsoft Teams that provides actionable insights into internal and external collaboration to improve business performance.
                            Intuitive dashboards display collaboration trends, participation, activity, sentiment and quality issues. 
                            Company data protection policies and individual privacy is maintained through tight integration with Microsoft 365 admin permissions.
                            Analytics 365 data insights provide a holistic view of people, team and group collaboration. Detailed individual interactions and personal data (such as chat messages and call data) are not made accessible to other subscribers.
                        </div>
                    </div>
                </div>
                <div class="signUp-datatrk datatrkR">
                    <div class="signUp-datatrkInr">
                        <div class="datatrk-h">
                            Access Denied
                        </div>
                        <div class="datatrk-p">
                            Please contact your Office 365 administrator to gain permission to access.
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    );
}

export default AccessDenied;