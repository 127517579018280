export const AllWidgets = [
  {
    cardWidgetID: 5,
    widgetName: "Call Summary",
    cardViewTypeID: 2,
    Visualization: "table",
    cardFilterID: 14,
    isProductivity: 1,
    isCustomerExperience: 1,
    isWellness: 0,
    categoryTypeID: 1,
    collaborationTypeID: 2,
    isAvailable: 1,
  },
  {
    cardWidgetID: 6,
    widgetName: "Chat Summary",
    cardViewTypeID: 0,
    Visualization: "table",
    cardFilterID: 3,
    isProductivity: 1,
    isCustomerExperience: 1,
    isWellness: 0,
    categoryTypeID: 1,
    collaborationTypeID: 3,
    isAvailable: 1,
  },
  {
    cardWidgetID: 7,
    widgetName: "Call Quality",
    cardViewTypeID: 5,
    Visualization: "piechart",
    cardFilterID: 2,
    isProductivity: 0,
    isCustomerExperience: 1,
    isWellness: 1,
    categoryTypeID: 5,
    collaborationTypeID: 2,
    isAvailable: 1,
  },
  {
    cardWidgetID: 8,
    widgetName: "Chat Sentiment",
    cardViewTypeID: 5,
    Visualization: "piechart",
    cardFilterID: 3,
    isProductivity: 0,
    isCustomerExperience: 0,
    isWellness: 1,
    categoryTypeID: 5,
    collaborationTypeID: 3,
    isAvailable: 1,
  },
  {
    cardWidgetID: 9,
    widgetName: "Reaction",
    cardViewTypeID: 6,
    Visualization: "generalanalytics",
    cardFilterID: 15,
    isProductivity: 0,
    isCustomerExperience: 0,
    isWellness: 1,
    categoryTypeID: 5,
    collaborationTypeID: 3,
    isAvailable: 1,
  },
  {
    cardWidgetID: 10,
    widgetName: "Collaboration Trends",
    cardViewTypeID: 7,
    Visualization: "barchart",
    cardFilterID: 1,
    isProductivity: 1,
    isCustomerExperience: 1,
    isWellness: 0,
    categoryTypeID: 3,
    collaborationTypeID: 4,
    isAvailable: 1,
  },
  {
    cardWidgetID: 11,
    widgetName: "Call Quality Trends",
    cardViewTypeID: 7,
    Visualization: "barchart",
    cardFilterID: 2,
    isProductivity: 0,
    isCustomerExperience: 1,
    isWellness: 1,
    categoryTypeID: 3,
    collaborationTypeID: 2,
    isAvailable: 1,
  },
  {
    cardWidgetID: 13,
    widgetName: "Chat Trends",
    cardViewTypeID: 7,
    Visualization: "barchart",
    cardFilterID: 3,
    isProductivity: 1,
    isCustomerExperience: 0,
    isWellness: 0,
    categoryTypeID: 3,
    collaborationTypeID: 3,
    isAvailable: 1,
  },
  {
    cardWidgetID: 33,
    widgetName: "Call Trends",
    cardViewTypeID: 7,
    Visualization: "barchart",
    cardFilterID: 2,
    isProductivity: 1,
    isCustomerExperience: 0,
    isWellness: 0,
    categoryTypeID: 3,
    collaborationTypeID: 2,
    isAvailable: 1,
  },
  {
    cardWidgetID: 15,
    widgetName: "Collaboration by People",
    cardViewTypeID: 2,
    Visualization: "table",
    cardFilterID: 1,
    isProductivity: 1,
    isCustomerExperience: 0,
    isWellness: 1,
    categoryTypeID: 2,
    collaborationTypeID: 4,
    isAvailable: 1,
  },
  {
    cardWidgetID: 27,
    widgetName: "Collaboration by Team",
    cardViewTypeID: 0,
    Visualization: "table",
    cardFilterID: 12,
    isProductivity: 1,
    isCustomerExperience: 1,
    isWellness: 0,
    categoryTypeID: 2,
    collaborationTypeID: 4,
    isAvailable: 1,
  },

  {
    cardWidgetID: 30,
    widgetName: "Team Chat Insights",
    cardViewTypeID: 12,
    Visualization: "generalanalytics",
    cardFilterID: 11,
    isProductivity: 1,
    isCustomerExperience: 1,
    isWellness: 1,
    categoryTypeID: 5,
    collaborationTypeID: 3,
    isAvailable: 1,
  },
  {
    cardWidgetID: 31,
    widgetName: "Chat Comparison by People",
    cardViewTypeID: 18,
    Visualization: "barchart",
    cardFilterID: 3,
    isProductivity: 1,
    isCustomerExperience: 0,
    isWellness: 1,
    categoryTypeID: 4,
    collaborationTypeID: 3,
    isAvailable: 1,
  },
  {
    cardWidgetID: 32,
    widgetName: "Communication by People",
    cardViewTypeID: 18,
    Visualization: "barchart",
    cardFilterID: 2,
    isProductivity: 1,
    isCustomerExperience: 0,
    isWellness: 1,
    categoryTypeID: 2,
    collaborationTypeID: 2,
    isAvailable: 1,
  },
  {
    cardWidgetID: 25,
    widgetName: "Most Active Teams",
    cardViewTypeID: 9,
    Visualization: "generalanalytics",
    cardFilterID: 10,
    isProductivity: 1,
    isCustomerExperience: 0,
    isWellness: 1,
    categoryTypeID: 4,
    collaborationTypeID: 4,
    isAvailable: 1,
  },
  {
    cardWidgetID: 2,
    widgetName: "Team Overview",
    cardViewTypeID: 10,
    Visualization: "summary",
    cardFilterID: 12,
    isProductivity: 1,
    isCustomerExperience: 1,
    isWellness: 0,
    categoryTypeID: 1,
    collaborationTypeID: 4,
    isAvailable: 1,
  },
  {
    cardWidgetID: 3,
    widgetName: "Meeting Overview",
    cardViewTypeID: 3,
    Visualization: "summary",
    cardFilterID: 2,
    isProductivity: 1,
    isCustomerExperience: 1,
    isWellness: 0,
    categoryTypeID: 1,
    collaborationTypeID: 1,
    isAvailable: 1,
  },
  {
    cardWidgetID: 4,
    widgetName: "Meeting Habits",
    cardViewTypeID: 4,
    Visualization: "list",
    cardFilterID: 2,
    isProductivity: 1,
    isCustomerExperience: 1,
    isWellness: 1,
    categoryTypeID: 5,
    collaborationTypeID: 1,
    isAvailable: 1,
  },
  {
    cardWidgetID: 14,
    widgetName: "Most Active People",
    cardViewTypeID: 9,
    Visualization: "generalanalytics",
    cardFilterID: 1,
    isProductivity: 1,
    isCustomerExperience: 0,
    isWellness: 1,
    categoryTypeID: 4,
    collaborationTypeID: 4,
    isAvailable: 1,
  },
  {
    cardWidgetID: 19,
    widgetName: "People Comparison",
    cardViewTypeID: 15,
    Visualization: "generalanalytics",
    cardFilterID: 4,
    isProductivity: 1,
    isCustomerExperience: 0,
    isWellness: 1,
    categoryTypeID: 4,
    collaborationTypeID: 4,
    isAvailable: 0,
  },
  {
    cardWidgetID: 16,
    widgetName: "Meeting Participation",
    cardViewTypeID: 11,
    Visualization: "piechart",
    cardFilterID: 2,
    isProductivity: 1,
    isCustomerExperience: 0,
    isWellness: 1,
    categoryTypeID: 5,
    collaborationTypeID: 1,
    isAvailable: 1,
  },
  {
    cardWidgetID: 28,
    widgetName: "Meeting Habits in Teams",
    cardViewTypeID: 0,
    Visualization: "table",
    cardFilterID: 16,
    isProductivity: 1,
    isCustomerExperience: 1,
    isWellness: 1,
    categoryTypeID: 4,
    collaborationTypeID: 1,
    isAvailable: 1,
  },
  {
    cardWidgetID: 34,
    widgetName: "Chat Comparison",
    cardViewTypeID: 2,
    Visualization: "table",
    cardFilterID: 3,
    isProductivity: 1,
    isCustomerExperience: 1,
    isWellness: 1,
    categoryTypeID: 4,
    collaborationTypeID: 3,
    isAvailable: 1,
  },
  {
    cardWidgetID: 35,
    widgetName: "Team Activity",
    cardViewTypeID: 0,
    Visualization: "table",
    cardFilterID: 17,
    isProductivity: 1,
    isCustomerExperience: 1,
    isWellness: 1,
    categoryTypeID: 2,
    collaborationTypeID: 4,
    isAvailable: 1,
  },
  {
    cardWidgetID: 36,
    widgetName: "Team Summary",
    cardViewTypeID: 0,
    Visualization: "table",
    cardFilterID: 18,
    isProductivity: 1,
    isCustomerExperience: 1,
    isWellness: 1,
    categoryTypeID: 1,
    collaborationTypeID: 4,
    isAvailable: 1,
  },
  {
    cardWidgetID: 37,
    widgetName: "Group Summary",
    cardViewTypeID: 2,
    Visualization: "table",
    cardFilterID: 19,
    isProductivity: 1,
    isCustomerExperience: 1,
    isWellness: 1,
    categoryTypeID: 1,
    collaborationTypeID: 3,
    isAvailable: 1,
  },
  {
    cardWidgetID: 40,
    widgetName: "Call Summary by People",
    cardViewTypeID: 2,
    Visualization: "table",
    cardFilterID: 2,
    isProductivity: 1,
    isCustomerExperience: 1,
    isWellness: 0,
    categoryTypeID: 1,
    collaborationTypeID: 2,
    isAvailable: 1,
  },
  {
    cardWidgetID: 0,
    widgetName: "People Summary",
    cardViewTypeID: 13,
    Visualization: "summary",
    cardFilterID: 21,
    isProductivity: 1,
    isCustomerExperience: 1,
    isWellness: 1,
    categoryTypeID: 1,
    collaborationTypeID: 4,
    isAvailable: 1,
  },
  {
    cardWidgetID: 26,
    widgetName: "Team Comparison",
    cardViewTypeID: 16,
    Visualization: "generalanalytics",
    cardFilterID: 5,
    isProductivity: 1,
    isCustomerExperience: 0,
    isWellness: 1,
    categoryTypeID: 4,
    collaborationTypeID: 4,
    isAvailable: 1,
  },
  {
    cardWidgetID: 29,
    widgetName: "Collaboration Network",
    cardViewTypeID: 0,
    Visualization: "table",
    cardFilterID: 14,
    isProductivity: 1,
    isCustomerExperience: 1,
    isWellness: 1,
    categoryTypeID: 3,
    collaborationTypeID: 2,
    isAvailable: 1,
  },
  {
    cardWidgetID: 41,
    widgetName: "Call Quality by People",
    cardViewTypeID: 18,
    Visualization: "barchart",
    cardFilterID: 14,
    isProductivity: 1,
    isCustomerExperience: 0,
    isWellness: 1,
    categoryTypeID: 4,
    collaborationTypeID: 2,
    isAvailable: 1,
  },
  {
    cardWidgetID: 42,
    widgetName: "Sentiment by People",
    cardViewTypeID: 18,
    Visualization: "barchart",
    cardFilterID: 3,
    isProductivity: 1,
    isCustomerExperience: 0,
    isWellness: 1,
    categoryTypeID: 4,
    collaborationTypeID: 3,
    isAvailable: 1,
  },
  {
    cardWidgetID: 43,
    widgetName: "Platform by People",
    cardViewTypeID: 18,
    Visualization: "barchart",
    cardFilterID: 22,
    isProductivity: 1,
    isCustomerExperience: 0,
    isWellness: 1,
    categoryTypeID: 2,
    collaborationTypeID: 2,
    isAvailable: 1,
  }
];
export const _cardCategoryType = {
  1: "Summary",
  2: "Activity",
  3: "Trends",
  4: "Comparisons",
  5: "Insights",
};
export const _cardCollaborationType = {
  1: "Meetings",
  2: "Calls",
  3: "Messages",
  4: "Overall Collaboration",
  5: "Platform",
};
