import "./TeamSummaryView.scss";
import { WindowMaximizeIcon } from "@fluentui/react-icons-northstar";

const TeamSummaryView = ({ data }) => {
  return (
    <div className="activity-view">
      <div className="smryDiv">
        <div className="smrL">
          <div className="smrL-label">
            <div className="mainLabelRow">
              <div className="mainLabel">
                <div className="mainLabel-p">
                  <WindowMaximizeIcon /> Total Channels
                </div>
                <div className="mainLabel-val">{data.totalChannels}</div>
              </div>
            </div>
            <div className="mainLabelRow">
              <div className="mainLabel">
                <div className="mainLabel-p">
                  <WindowMaximizeIcon /> Total Users
                </div>
                <div className="mainLabel-val">{data.totalUsers}</div>
              </div>
              <div className="subLabel">
                <div className="subLabelRow">
                  <div className="mainLabel-p">
                    <WindowMaximizeIcon /> Members
                  </div>
                  <div className="mainLabel-val">{data.memberGuests}</div>
                </div>
                <div className="subLabelRow">
                  <div className="mainLabel-p">
                    <WindowMaximizeIcon /> Owners
                  </div>
                  <div className="mainLabel-val">{data.owners}</div>
                </div>
              </div>
            </div>
            <div className="mainLabelRow">
              <div className="mainLabel">
                <div className="mainLabel-p">
                  <WindowMaximizeIcon /> Active Users
                </div>
                <div className="mainLabel-val">{data.activeUsers}</div>
              </div>
            </div>
          </div>
        </div>
        <div className="smrR">
          <div className="smrR-top">
            <div className="topTile">
              <div className="topTile-p">Total Calls</div>
              <div className="topTile-val">{data.totalMeetings}</div>
            </div>
            <div className="topTile">
              <div className="topTile-p">Total Messages</div>
              <div className="topTile-val">{data.totalMessages}</div>
            </div>
          </div>
          <div className="smrBott">
            <div className="smrR-latestAct">
              <div className="latestActTitle">Latest Activity</div>
                <div className="latestActData">
                <div className="latestActDataTime">
                  {data.latestActivityTime}
                </div>
                   <div>&nbsp;</div>
                <div className="latestActDataH">
                  {data.latestActivitySubject}
                </div>
              </div>
            </div>
            <div className="smrR-nextAct">
              <div className="nextActTitle">Next Meeting</div>
              <div className="nextActData">
                <div className="nextActDataRow">
                  <div className="nextActDataRow-p">Subject</div>
                  <div className="nextActDataRow-h">
                    {data.nextMeetingSubject}
                  </div>
                </div>
                <div className="nextActDataRow">
                  <div className="nextActDataRow-p">Time</div>
                  <div className="nextActDataRow-h">{data.nextMeetingTime}</div>
                </div>
                <div className="nextActDataRow">
                  <div className="nextActDataRow-p">Organiser</div>
                  <div className="nextActDataRow-h">
                    {data.nextMeetingOrganiser}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TeamSummaryView;
