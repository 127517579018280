import {
    Chart,
    ChartSeries,
    ChartSeriesItem,
    ChartCategoryAxis,
    ChartCategoryAxisItem,
    ChartLegend,
    ChartTooltip
} from "@progress/kendo-react-charts";
import "hammerjs";
import './BarChart.scss';

// const categories = ["Jan", "Feb", "March", "April"];
// const series = [
//     {
//         name: "Jatin",
//         data: [300, 350, 500, 400]
//     },
//     {
//         name: "Neeraj",
//         data: [500, 450, 350, 250]
//     },
//     {
//         name: "Prabhat",
//         data: [400, 250, 300, 350]
//     }
// ];

export default function BarChart({ data }) {
    const renderTooltip = ({
        point
    }) => <span>{point.category.toString()}: {point.value}</span>;

    return (
        <>
        {
            data && data.categories && data.categories.length > 0 &&
                <Chart style={{ height: '100%' }} pannable={{
                    lock: "y",
                }}
                    zoomable={{
                        mousewheel: {
                            lock: "y",
                        },
                        selection: {
                            lock: "y",
                        },
                    }}>
                <ChartLegend position="top" orientation="horizontal" />
                <ChartCategoryAxis>
                        <ChartCategoryAxisItem categories={data.categories} labels={{
                            rotation: 'auto'
                        }} maxDivisions={10} />
                </ChartCategoryAxis>
                <ChartSeries>
                    {data.series.map((item, idx) => (
                        <ChartSeriesItem
                            key={idx}
                            type="column" 
                            stack={true}
                            color={item.color}
                            tooltip={{ visible: true }}
                            data={item.data}
                            name={item.name}
                        />
                    ))}
                    </ChartSeries>
                    <ChartTooltip render={renderTooltip} />
            </Chart>
        }
        </>
      );
}
