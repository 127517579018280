import React from "react";
import { List, Divider } from "@fluentui/react-northstar";
import { HorizontalRuleIcon } from "@fluentui/react-icons-northstar";

import "./dashboard.scss";

const CardCategories = (props) => {
  const items = [
    {
      key: "isAll",
      media: <HorizontalRuleIcon size="smaller" />,
      header: "All",
    },
    {
      key: "isProductivity",
      media: <i className={"analytics-productivity"} />,
      header: "People Engagement",
    },
    {
      key: "isCustomerExperience",
      media: <i className={"analytics-customerExperience"} />,
      header: "Customer Engagement",
    },
    {
      key: "isWellness",
      media: <i className={"analytics-wellbeing"} />,
      header: "Wellbeing",
    },
    {
      key: "1",
      media: <i className={"analytics-summary"} />,
      header: "Summary",
    },
    {
      key: "2",
      media: <i className={"analytics-activity2"} />,
      header: "Activity",
    },
    {
      key: "3",
      media: <i className={"analytics-trends"} />,
      header: "Trends",
    },
    {
      key: "4",
      media: <i className={"analytics-comparison2"} />,
      header: "Comparison",
    },
    {
      key: "5",
      media: <i className={"analytics-insights"} />,
      header: "Insights",
    },
  ];

  return (
    <>
      <Divider important content="Categories" size={2} />
      <List
        className="categorylist"
        selectable
        selectedIndex={props.selectedCategory}
        onSelectedIndexChange={(e, newProps) => {
          props.handleCategoryChange(
            newProps.selectedIndex,
            newProps.items[newProps.selectedIndex].key,
            1
          );
        }}
        items={items.slice(0, 4)}
        style={{ width: "100%" }}
      />
      <Divider important content="Views" size={2} />
      <List
        className="categorylist"
        selectable
        selectedIndex={props.selectedCategory - 4}
        onSelectedIndexChange={(e, newProps) => {
          props.handleCategoryChange(
            newProps.selectedIndex + 4,
            "categoryTypeID",
            Number(newProps.items[newProps.selectedIndex].key)
          );
        }}
        items={items.slice(4, 9)}
        style={{ width: "100%" }}
      />
    </>
  );
};

export default CardCategories;
