import React, { useState, useEffect } from "react";
import {
  Flex,
  Input,
  Checkbox,
  Button,
  Text,
  Tooltip,
  Avatar,
  Loader,
  Label,
  Box,
} from "@fluentui/react-northstar";
import { Dialog } from "@progress/kendo-react-dialogs";
import {
  Grid,
  GridColumn as Column,
  GridNoRecords,
} from "@progress/kendo-react-grid";
import { getter } from "@progress/kendo-react-common";
import {
  SearchIcon,
  EditIcon,
  ApprovalsAppbarIcon,
  ExclamationTriangleIcon,
  ShiftActivityIcon,
  PresenceAvailableIcon,
  CloseIcon,
} from "@fluentui/react-icons-northstar";
import "../Dashboard/dashboard.scss";
import "./config.scss";
import EditUser from "./edituser";
import ConfigurationApiService from "../../../services/ConfigurationApiService";
import AuthHelper from "../../../services/auth-helper";
import CenteredHeader from "../../Shared/Header/Header";
import useMediaQuery from "../../Shared/CustomHooks/useMediaQuery";
import WindowContext from "../../Shared/Context/Context";
import {GroupInheritanceIcon, TrialExpiredIcon, LicenseWarningIcon} from "./commonIcons";

const DATA_ITEM_KEY = "userGUID";
const SELECTED_FIELD = "checked";
const idGetter = getter(DATA_ITEM_KEY);
const initialDataState = { skip: 0, take: 10 };

const CellWithUserImage = (props) => {
  const user = props.dataItem;
  return (
    <WindowContext.Consumer>
      {
        context => (
          <td colSpan={props.colspan}>
            <Avatar
              name={user.userName}
              image={user.userImage}
              size={context.mediaCategory.sm ? "small" : "large"}
            />
          </td>
        )
      }
    </WindowContext.Consumer>
  );
};

const CellWithUserName = (props) => {
  const user = props.dataItem;
  return (
    <td>
      <Box>
        <Text content={user.userName} size="medium" />
      </Box>
      <Text content={user.jobTitle} size="small" timestamp />
    </td>
  );
};

const CellWithAdminAcess = (props) => {  
  const user = props.dataItem;
  const [adminAccessLoading, setAdminAccessLoading] = useState(false);
  const handleAdminAccess = (isChecked) => {
    setAdminAccessLoading(true);
    var data = {
      userGUIDList: [user.userGUID],
      AccessGranted: isChecked.checked,
    };
    AuthHelper.getAccessToken(function (token) {
      ConfigurationApiService.UpdateUserAdminAccess(data, token).then(
        (response) => {
          if (response !== undefined && response !== "") {
            props.handleUserAdminAccess(isChecked.checked, user);
          }
          setAdminAccessLoading(false);
        }
      );
    });
  };
  return (
    <td style={{ textAlign: "center" }}>
      {adminAccessLoading ? (
        <Loader size="small" />
      ) : (
        !user.administrativeAccess && user.administrativeAccessInherited !== 0 ? (
          user.administrativeAccessInherited === 1 ? 
          <GroupInheritanceIcon colorValue={props.selectedState[user.userGUID] ? "grey" : "green"}/> 
          : <LicenseWarningIcon />
        ) : (
          <Tooltip
            trigger={
              <Box>
                <Checkbox
                  toggle
                  disabled={(user.email === props.loggedInUserEmail) || (!user.isSubscribed && user.isSubscribedInherited !== 1)}
                  defaultChecked={user.administrativeAccess}
                  onClick={(event, isChecked) => {
                    handleAdminAccess(isChecked);
                  }}
                />
              </Box>
            }
            align="center"
            position="below"
            content={user.email === props.loggedInUserEmail && "Edit permission disabled for own account"}
          />
        )
      )}
    </td>
  );
};

const CellWithReportingAcess = (props) => {
  const user = props.dataItem;
  const [reportingAccessLoading, setReportingAccessLoading] = useState(false);

  const handleReportingAccess = (isChecked) => {
    setReportingAccessLoading(true);
    var data = {
      userGUIDList: [user.userGUID],
      AccessGranted: isChecked.checked,
    };
    AuthHelper.getAccessToken(function (token) {
      ConfigurationApiService.UpdateUserReportingAccess(data, token).then(
        (response) => {
          if (response !== undefined && response !== "") {
            props.handleUserReportingAccess(isChecked.checked, user);
          }
          setReportingAccessLoading(false);
        }
      );
    });
  };

  return (
    <td style={{ textAlign: "center" }}>
      {reportingAccessLoading ? (
        <Loader size="small" />
      ) : (
        !user.reportingAccess && user.reportingAccessInherited !== 0 ? (
          user.reportingAccessInherited === 1 ? 
          <GroupInheritanceIcon colorValue={props.selectedState[user.userGUID] ? "grey" : "green"}/> 
          : <LicenseWarningIcon />
        ) : (
          <Checkbox
            toggle
            disabled={!user.isSubscribed && user.isSubscribedInherited !== 1}
            defaultChecked={user.reportingAccess}
            onClick={(event, isChecked) => {
              handleReportingAccess(isChecked);
            }}
          />
        )
      )}
    </td>
  );
};

const CellWithSubscription = (props) => {
  const user = props.dataItem;
  const [subscriptionLoading, setSubscriptionLoading] = useState(false);

  const handleUserSubscription = (isChecked) => {
    setSubscriptionLoading(true);
    if (
      props.subscribedUser >= props.tenantDetails.maxUsers &&
      isChecked.checked
    ) {
      setSubscriptionLoading(false);
      return;
    } else {
      var data = {
        userGUIDList: [user.userGUID],
        isSubscribed: isChecked.checked,
      };
      AuthHelper.getAccessToken(function (token) {
        ConfigurationApiService.UpdateUserSubscription(data, token).then(
          (response) => {
            if (response !== undefined && response !== "") {
              props.handleUserSubscription(isChecked.checked, user);
            }
            setSubscriptionLoading(false);
          }
        );
      });
    }
  };

  return (
    <td style={{ textAlign: "center" }}>
      {subscriptionLoading ? (
        <Loader size="small" />
      ) : (
        !user.isSubscribed && user.isSubscribedInherited !== 0 ? (
          user.isSubscribedInherited === 1 ? 
          <GroupInheritanceIcon colorValue={props.selectedState[user.userGUID] ? "grey" : "green"}/> 
          : <LicenseWarningIcon />
        ) : (
          <Tooltip
            trigger={
              <Box>
                <Checkbox
                  toggle
                  defaultChecked={user.isSubscribed}
                  disabled={ user.email == props.loggedInUserEmail || (
                    props.subscribedUser >= props.tenantDetails.maxUsers &&
                    !user.isSubscribed)
                  }
                  onClick={(event, isChecked) => {
                    handleUserSubscription(isChecked);
                  }}
                />
              </Box>
            }
            align="center"
            position="below"
            content={
              user.email == props.loggedInUserEmail ? "Edit permission disabled for own account" : 
              (props.subscribedUser >= props.tenantDetails.maxUsers &&
              !user.isSubscribed
                && "A maximum of " +
                  props.tenantDetails.maxUsers +
                  " people can have Data Enabled")
            }
          />
        )
      )}
    </td>
  );
};

const CellWithTrialSubscription = (props) => {
  const user = props.dataItem;
  const [trialSubscriptionLoading, setTrialSubscriptionLoading] =
    useState(false);

  const handleTrialSubscription = (isChecked) => {
    setTrialSubscriptionLoading(true);
    var data = {
      userGUIDList: [user.userGUID],
      isSubscribed: isChecked.checked,
    };
    AuthHelper.getAccessToken(function (token) {
      ConfigurationApiService.UpdateUserTrialSubscription(data, token).then(
        (response) => {
          if (response !== undefined && response !== "") {
            props.handleTrialSubscription(isChecked.checked, user);
          }
          setTrialSubscriptionLoading(false);
        }
      );
    });
  };

  return (
    <td style={{ textAlign: "center" }}>
      {user.isTrialExpired ? (
        <Box> 
          <TrialExpiredIcon /> 
        </Box>
      ) : (
        <Box>
          {trialSubscriptionLoading ? (
            <Loader size="small" />
          ) : (
            !user.isTrialSubscribed && user.isTrialSubscribedInherited !== 0 ? (
              user.isTrialSubscribedInherited === 1 ? 
              <GroupInheritanceIcon colorValue={props.selectedState[user.userGUID] ? "grey" : "green"}/> 
              : <LicenseWarningIcon />
            ) : (
              <Checkbox
                toggle
                defaultChecked={user.isTrialSubscribed}
                onClick={(event, isChecked) => {
                  handleTrialSubscription(isChecked);
                }}
              />
            )
          )}
        </Box>
      )}
    </td>
  );
};

const CellWithAction = (props) => {
  const user = props.dataItem;
  return (
    <td style={{ textAlign: "center" }}>
      <Button
        onClick={() => props.onOpen(user)}
        icon={<EditIcon />}
        iconOnly
        title="Edit"
        size="small"
        primary
      />
    </td>
  );
};

const DetailComponent = (props) => {
  const user = props.dataItem;
  const [adminAccessLoading, setAdminAccessLoading] = useState(false);
  const [reportingAccessLoading, setReportingAccessLoading] = useState(false);
  const [trialSubscriptionLoading, setTrialSubscriptionLoading] =
    useState(false);

  const handleAdminAccess = (isChecked) => {
    setAdminAccessLoading(true);
    var data = {
      userGUIDList: [user.userGUID],
      AccessGranted: isChecked.checked,
    };
    AuthHelper.getAccessToken(function (token) {
      ConfigurationApiService.UpdateUserAdminAccess(data, token).then(
        (response) => {
          if (response !== undefined && response !== "") {
            props.handleUserAdminAccess(isChecked.checked, user);
          }
          setAdminAccessLoading(false);
        }
      );
    });
  };

  const handleReportingAccess = (isChecked) => {
    setReportingAccessLoading(true);
    var data = {
      userGUIDList: [user.userGUID],
      AccessGranted: isChecked.checked,
    };
    AuthHelper.getAccessToken(function (token) {
      ConfigurationApiService.UpdateUserReportingAccess(data, token).then(
        (response) => {
          if (response !== undefined && response !== "") {
            props.handleUserReportingAccess(isChecked.checked, user);
          }
          setReportingAccessLoading(false);
        }
      );
    });
  };

  const handleTrialSubscription = (isChecked) => {
    setTrialSubscriptionLoading(true);
    var data = {
      userGUIDList: [user.userGUID],
      isSubscribed: isChecked.checked,
    };
    AuthHelper.getAccessToken(function (token) {
      ConfigurationApiService.UpdateUserTrialSubscription(data, token).then(
        (response) => {
          if (response !== undefined && response !== "") {
            props.handleTrialSubscription(isChecked.checked, user);
          }
          setTrialSubscriptionLoading(false);
        }
      );
    });
  };

  return (
    <section style={{ paddingRight: "32px" }}>
      <table>
        <tr>
          <CellWithUserImage {...props} colspan={2} />
        </tr>
        <tr>
          <td>App Owner:</td>
          <td class="text-right">
            {adminAccessLoading ? (
              <Loader size="small" />
            ) : (
              !user.administrativeAccess && user.administrativeAccessInherited !== 0 ? (
                user.administrativeAccessInherited === 1 ? 
                <GroupInheritanceIcon colorValue={props.selectedState[user.userGUID] ? "grey" : "green"}/> 
                : <LicenseWarningIcon />
              ) : (
                <Tooltip
                trigger={
                  <Box>
                    <Checkbox
                      className="chkTrial-mobile"
                      toggle
                      disabled={(user.email == props.loggedInUserEmail) || (!user.isSubscribed && user.isSubscribedInherited !== 1)}
                      defaultChecked={user.administrativeAccess}
                      onClick={(event, isChecked) => {
                        handleAdminAccess(isChecked);
                      }}
                    />
                  </Box>
                }
                align="center"
                position="below"
                content={(user.email == props.loggedInUserEmail) && "Edit permission disabled for own account"}
              />
              )
            )}
          </td>
        </tr>
        <tr>
          <td>Reporting Access:</td>
          <td class="text-right">
            {reportingAccessLoading ? (
              <Loader size="small" />
            ) : (
              !user.reportingAccess && user.reportingAccessInherited !== 0 ? (
                user.reportingAccessInherited === 1 ? 
                <GroupInheritanceIcon colorValue={props.selectedState[user.userGUID] ? "grey" : "green"}/> 
                : <LicenseWarningIcon />
              ) : (
                <Checkbox
                  className="chkTrial-mobile"
                  toggle
                  disabled={!user.isSubscribed && user.isSubscribedInherited !== 1}
                  defaultChecked={user.reportingAccess}
                  onClick={(event, isChecked) => {
                    handleReportingAccess(isChecked);
                  }}
                />
              )
            )}
          </td>
        </tr>
        <tr>
          <td>Trial :</td>
          <td class="text-right">
            {user.isTrialExpired ? (
              <Box>
                <TrialExpiredIcon />
              </Box>
            ) : (
              <Box>
                {trialSubscriptionLoading ? (
                  <Loader size="small" />
                ) : (
                  !user.isTrialSubscribed && user.isTrialSubscribedInherited !== 0 ? (
                    user.isTrialSubscribedInherited === 1 ? 
                    <GroupInheritanceIcon colorValue={props.selectedState[user.userGUID] ? "grey" : "green"}/> 
                    : <LicenseWarningIcon />
                  ) : (
                    <Checkbox
                      className="chkTrial-mobile"
                      toggle
                      defaultChecked={user.isTrialSubscribed}
                      onClick={(event, isChecked) => {
                        handleTrialSubscription(isChecked);
                      }}
                    />
                  )
                )}
              </Box>
            )}
          </td>
        </tr>
      </table>
    </section>
  );
};

const UserConfig = (props) => {
  const [loading, setLoading] = useState(false);
  const [searchInput, setSearchInput] = useState("");
  const [userData, setUserData] = useState(null);
  const [subscribedUser, setSubscribedUser] = useState(0);
  const [tenantDetails, setTenantDetails] = useState(null);
  const [selectedState, setSelectedState] = useState({});
  const [page, setPage] = React.useState(initialDataState);

  const [selectedAppOwner, setSelectedAppOwner] = useState(false);
  const [selectedReportingAccess, setSelectedReportingAccess] = useState(false);
  const [selectedSubscription, setSelectedSubscription] = useState(false);
  const [selectedTrialSubscription, setSelectedTrialSubscription] = useState(false);
  const [canSelectedSubscribe, setCanSelectedSubscribe] = useState(false);
  const [selectedTrialExpired, setSelectedTrialExpired] = useState(false);

  const [open, setOpen] = useState(false);
  const [selectedUser, setSelectedUser] = useState(null);
  const [loggedInUserEmail, setLoggedInUserEmail] = useState(null);

  useEffect(() => {
    setSearchInput("");
    setTenantDetails(props.tenantDetails);
    setLoggedInUserEmail(props.loggedInUserEmail);
    loadUserData();
  }, []);

  const onOpen = (userDetails) => {
    setSelectedUser(userDetails);
    setOpen(true);
  };

  const onCancel = () => {
    setOpen(false);
    setSelectedUser(null);
  };

  const handleAccessChange = (isAnalytics, isAdmin) => {
    var userDetails = selectedUser;
    userDetails.reportingAccess = isAnalytics;
    userDetails.administrativeAccess = isAdmin;
    setSelectedUser(userDetails);
  };

  const handleCloseAction = () => {
    setOpen(false);
    var tempUserdata = userData;
    var indexId = tempUserdata.findIndex(
      (x) => x.userGUID === selectedUser.userGUID
    );

    tempUserdata[indexId].reportingAccess = selectedUser.reportingAccess;
    tempUserdata[indexId].administrativeAccess = selectedUser.administrativeAccess;
    tempUserdata[indexId].canLogin = selectedUser.administrativeAccess || selectedUser.reportingAccess ||
      selectedUser.administrativeAccessInherited === 1 || selectedUser.reportingAccessInherited === 1;
    setUserData(tempUserdata);
    setSelectedUser(null);
  };

  const expandChange = (event) => {
    let newData = userData.map((item) => {
      if (item.userName === event.dataItem.userName) {
        item.expanded = !event.dataItem.expanded;
      }
      return item;
    });
    setUserData(newData);
  };

  const loadUserData = () => {
    setUserData(null);
    setSelectedState({});
    AuthHelper.getAccessToken(function (token) {
      ConfigurationApiService.GetUsers(token).then((response) => {
        if (response !== undefined && response !== "") {
          var count = response.filter((element) => {
            return element.isSubscribed || element.isSubscribedInherited===1;
          }).length;
          setSubscribedUser(count);
          setUserData(response);
        } else {
          setUserData([]);
        }
      });
    });
  };

  const pageChange = (event) => {
    setPage(event.page);
  };

  const selectionChange = (event) => {
    const checked = event.syntheticEvent.target.checked;
    const dataItemKey = idGetter(event.dataItem);
    const newSelectedState = { ...selectedState, [dataItemKey]: checked };
    setSelectedState(newSelectedState);
    handleValidations(newSelectedState, userData);
  };

  const headerSelectionChange = (event) => {
    const checked = event.syntheticEvent.target.checked;
    const newSelectedState = {};
    userData.forEach((item) => {
      newSelectedState[idGetter(item)] = checked;
    });
    setSelectedState(newSelectedState);
    handleValidations(newSelectedState, userData);
  };

  const handleUserAdminAccess = (checked, user) => {
    var tempUserdata = [...userData];
    var indexId = tempUserdata.findIndex((x) => x.userGUID === user.userGUID);

    tempUserdata[indexId].administrativeAccess = checked;
    tempUserdata[indexId].canLogin = checked || tempUserdata[indexId].reportingAccess ||
      tempUserdata[indexId].administrativeAccessInherited === 1 || tempUserdata[indexId].reportingAccessInherited === 1;
    handleValidations(selectedState, tempUserdata);
    setUserData(tempUserdata);
  };

  const handleUserReportingAccess = (checked, user) => {
    var tempUserdata = [...userData];
    var indexId = tempUserdata.findIndex((x) => x.userGUID === user.userGUID);

    tempUserdata[indexId].reportingAccess = checked;
    tempUserdata[indexId].canLogin = checked || tempUserdata[indexId].administrativeAccess ||
      tempUserdata[indexId].administrativeAccessInherited === 1 || tempUserdata[indexId].reportingAccessInherited === 1;
    handleValidations(selectedState, tempUserdata);
    setUserData(tempUserdata);
  };

  const handleUserSubscription = (checked, user) => {
    var tempUserdata = [...userData];
    var indexId = tempUserdata.findIndex((x) => x.userGUID === user.userGUID);
    tempUserdata[indexId].isSubscribed = checked;
    if (!checked) {
      tempUserdata[indexId].administrativeAccess = tempUserdata[indexId].isSubscribedInherited === 1 ? tempUserdata[indexId].administrativeAccess : checked;
      tempUserdata[indexId].reportingAccess = tempUserdata[indexId].isSubscribedInherited === 1 ? tempUserdata[indexId].reportingAccess : checked;
      tempUserdata[indexId].canLogin = tempUserdata[indexId].isSubscribedInherited === 1 ? tempUserdata[indexId].canLogin : checked;
    }
    handleValidations(selectedState, tempUserdata);
    var count = tempUserdata.filter((element) => {
      return element.isSubscribed || element.isSubscribedInherited===1;
    }).length;
    setSubscribedUser(count);
    setUserData(tempUserdata);
  };

  const handleTrialSubscription = (checked, user) => {
    var tempUserdata = [...userData];
    var indexId = tempUserdata.findIndex((x) => x.userGUID === user.userGUID);
    tempUserdata[indexId].isTrialSubscribed = checked;
    handleValidations(selectedState, tempUserdata);
    setUserData(tempUserdata);
  };

  const handleValidations = (selectedUsers, userArray) => {
    var nonAdminUser = userArray.filter(
      (e) => selectedUsers[e.userGUID] && !e.administrativeAccess && e.administrativeAccessInherited !== 1
    ).length;
    setSelectedAppOwner(nonAdminUser === 0);

    var nonReportingUser = userArray.filter(
      (e) => selectedUsers[e.userGUID] && !e.reportingAccess && e.reportingAccessInherited !== 1
    ).length;
    setSelectedReportingAccess(nonReportingUser === 0);

    var nonSubscribedUser = userArray.filter(
      (e) => selectedUsers[e.userGUID] && !e.isSubscribed && e.isSubscribedInherited !== 1
    ).length;
    setSelectedSubscription(nonSubscribedUser === 0);

    var availableUserCount = userArray.filter(
      (e) => selectedUsers[e.userGUID] && e.isSubscribedInherited !== 1 && !e.isSubscribed
    ).length;

    var totalSubscribedUsersCount = userArray.filter(
      (e) => e.isSubscribed || e.isSubscribedInherited===1
    ).length;

    if (availableUserCount <= tenantDetails.maxUsers - totalSubscribedUsersCount) {
      setCanSelectedSubscribe(true);
    } else {
      setCanSelectedSubscribe(false);
    }

    var nonTrialSubscribedCount = userArray.filter(
      (e) =>
        selectedUsers[e.userGUID] && !e.isTrialExpired && !e.isTrialSubscribed && e.isTrialSubscribedInherited !== 1
    ).length;
    setSelectedTrialSubscription(nonTrialSubscribedCount === 0);

    setSelectedTrialExpired(
      userArray.filter((e) => selectedUsers[e.userGUID] && !e.isTrialExpired).length === 0
    );

  };

  const handleSelectedAppOwner = (isChecked) => {
    setLoading(true);
    var data = {};
    if (isChecked.checked) {
      data = {
        userGUIDList: userData
          .filter((e) => !e.administrativeAccess && e.administrativeAccessInherited !== 1 && selectedState[e.userGUID])
          .map((ele) => {
            return ele.userGUID;
          }),
        AccessGranted: isChecked.checked,
      };
    } else {
      data = {
        userGUIDList: userData
          .filter((e) => e.email != loggedInUserEmail && (e.administrativeAccess && selectedState[e.userGUID]))
          .map((ele) => {
            return ele.userGUID;
          }),
        AccessGranted: isChecked.checked,
      };
    }

    AuthHelper.getAccessToken(function (token) {
      ConfigurationApiService.UpdateUserAdminAccess(data, token).then(
        (response) => {
          if (response !== undefined && response !== "") {
            var tempUserArray = userData.map((ele) => {
              if (selectedState[ele.userGUID]) {
                ele.administrativeAccess = (ele.email == loggedInUserEmail) || (!ele.administrativeAccess 
                  && ele.administrativeAccessInherited === 1 ? ele.administrativeAccess : isChecked.checked);
                ele.canLogin = ele.reportingAccess || ele.administrativeAccess || ele.administrativeAccessInherited === 1
                  || ele.reportingAccessInherited === 1;
              }
              return ele;
            });
            handleValidations(selectedState, tempUserArray);
            setUserData(tempUserArray);
          }
          setLoading(false);
        }
      );
    });
  };

  const handleSelectedReportingAccess = (isChecked) => {
    setLoading(true);
    var data = {};
    if (isChecked.checked) {
      data = {
        userGUIDList: userData
          .filter((e) => !e.reportingAccess && e.reportingAccessInherited !== 1 && selectedState[e.userGUID])
          .map((ele) => {
            return ele.userGUID;
          }),
        AccessGranted: isChecked.checked,
      };
    } else {
      data = {
        userGUIDList: userData
          .filter((e) => e.reportingAccess && selectedState[e.userGUID])
          .map((ele) => {
            return ele.userGUID;
          }),
        AccessGranted: isChecked.checked,
      };
    }

    AuthHelper.getAccessToken(function (token) {
      ConfigurationApiService.UpdateUserReportingAccess(data, token).then(
        (response) => {
          if (response !== undefined && response !== "") {
            var tempUserArray = userData.map((ele) => {
              if (selectedState[ele.userGUID]) {
                ele.reportingAccess = !ele.reportingAccess && ele.reportingAccessInherited === 1 
                  ? ele.reportingAccess : isChecked.checked;
                ele.canLogin = ele.administrativeAccess || ele.reportingAccess || ele.administrativeAccessInherited === 1
                  || ele.reportingAccessInherited === 1;
              }
              return ele;
            });
            handleValidations(selectedState, tempUserArray);
            setUserData(tempUserArray);
          }
          setLoading(false);
        }
      );
    });
  };

  const handleSelectedSubscription = (isChecked) => {
    setLoading(true);
    var data = {};
    if (isChecked.checked) {
      data = {
        userGUIDList: userData
          .filter((e) => !e.isSubscribed && e.isSubscribedInherited !== 1 && selectedState[e.userGUID])
          .map((ele) => {
            return ele.userGUID;
          }),
        isSubscribed: isChecked.checked,
      };
    } else {
      data = {
        userGUIDList: userData
          .filter((e) => (e.email !== loggedInUserEmail) && (e.isSubscribed && selectedState[e.userGUID]))
          .map((ele) => {
            return ele.userGUID;
          }),
        isSubscribed: isChecked.checked,
      };
    }
    AuthHelper.getAccessToken(function (token) {
      ConfigurationApiService.UpdateUserSubscription(data, token).then(
        (response) => {
          if (response !== undefined && response !== "") {
            var tempUserArray = userData.map((ele) => {
              if (selectedState[ele.userGUID]) {
                ele.isSubscribed = ele.email == loggedInUserEmail || (!ele.isSubscribed 
                  && ele.isSubscribedInherited === 1 ? ele.isSubscribed : isChecked.checked);
                if (!ele.isSubscribed) {
                  ele.administrativeAccess = ele.email == loggedInUserEmail || (ele.isSubscribedInherited === 1 ? ele.administrativeAccess : ele.isSubscribed);
                  ele.reportingAccess = ele.isSubscribedInherited === 1 ? ele.reportingAccess : ele.isSubscribed;
                  ele.canLogin = ele.email == loggedInUserEmail || (ele.isSubscribedInherited === 1 ? ele.canLogin : ele.isSubscribed);
                }
              }
              return ele;
            });
            var count = tempUserArray.filter((e) => e.isSubscribed || e.isSubscribedInherited===1).length;
            setSubscribedUser(count);
            handleValidations(selectedState, tempUserArray);
            setUserData(tempUserArray);
          }
          setLoading(false);
        }
      );
    });
  };

  const handleSelectedTrialSubscription = (isChecked) => {
    setLoading(true);
    var data = {};
    if (isChecked.checked) {
      data = {
        userGUIDList: userData
          .filter(
            (e) => !e.isTrialExpired && e.isTrialSubscribedInherited !== 1 && !e.isTrialSubscribed && selectedState[e.userGUID]
          )
          .map((ele) => {
            return ele.userGUID;
          }),
        isSubscribed: isChecked.checked,
      };
    } else {
      data = {
        userGUIDList: userData
          .filter(
            (e) => !e.isTrialExpired && e.isTrialSubscribed && selectedState[e.userGUID]
          )
          .map((ele) => {
            return ele.userGUID;
          }),
        isSubscribed: isChecked.checked,
      };
    }
    AuthHelper.getAccessToken(function (token) {
      ConfigurationApiService.UpdateUserTrialSubscription(data, token).then(
        (response) => {
          if (response !== undefined && response !== "") {
            var tempUserArray = userData.map((ele) => {
              if (selectedState[ele.userGUID] && !ele.isTrialExpired) {
                ele.isTrialSubscribed = !ele.isTrialSubscribed 
                  && ele.isTrialSubscribedInherited === 1 ? ele.isTrialSubscribed : isChecked.checked;
              }
              return ele;
            });
            handleValidations(selectedState, tempUserArray);
            setUserData(tempUserArray);
          }
          setLoading(false);
        }
      );
    });
  };

  const CustomCellWithUserImage = (props) => (
    <CellWithUserImage {...props} colspan={1} />
  );

  const CustomCellWithAdminAcess = (props) => (
    <CellWithAdminAcess
      {...props}
      selectedState = {selectedState}
      loggedInUserEmail = {loggedInUserEmail}
      handleUserAdminAccess={(checked, user) => {
        handleUserAdminAccess(checked, user);
      }}
    />
  );

  const CustomCellWithReportingAcess = (props) => (
    <CellWithReportingAcess
      {...props}
      selectedState = {selectedState}
      handleUserReportingAccess={(checked, user) => {
        handleUserReportingAccess(checked, user);
      }}
    />
  );

  const CustomCellWithSubscription = (props) => (
    <CellWithSubscription
      {...props}
      selectedState = {selectedState}
      tenantDetails={tenantDetails}
      subscribedUser={subscribedUser}
      loggedInUserEmail = {loggedInUserEmail}
      handleUserSubscription={(checked, user) => {
        handleUserSubscription(checked, user);
      }}
    />
  );

  const CustomCellWithTrialSubscription = (props) => (
    <CellWithTrialSubscription
      {...props}
      selectedState = {selectedState}
      handleTrialSubscription={(checked, user) => {
        handleTrialSubscription(checked, user);
      }}
    />
  );

  const CustomCellWithAction = (props) => (
    <CellWithAction
      {...props}
      onOpen={(user) => {
        onOpen(user);
      }}
    />
  );

  const CustomDetailedComponent = (props) => (
    <DetailComponent
      {...props}
      selectedState = {selectedState}
      handleUserAdminAccess={(checked, user) => {
        handleUserAdminAccess(checked, user);
      }}
      handleUserReportingAccess={(checked, user) => {
        handleUserReportingAccess(checked, user);
      }}
      handleTrialSubscription={(checked, user) => {
        handleTrialSubscription(checked, user);
      }}
    />
  );

  return (
    <WindowContext.Consumer>
      {
        context => (
        <>
          {userData != null &&
            userData.filter((e) => selectedState[e.userGUID]).length != 0 && (
              <Box>
                <Box style={{ maxHeight: "60px", overflow: "auto" }}>
                  {userData != null &&
                    userData
                      .filter((e) => selectedState[e.userGUID])
                      .map((user) => {
                        return (
                          <Label
                            color="brand"
                            key={user.userId}
                            content={user.userName}
                            icon={
                              <CloseIcon
                                style={{ cursor: "pointer" }}
                                onClick={() => {
                                  let newSelectedState = {...selectedState, [user.userGUID]: false};
                                  setSelectedState(newSelectedState);
                                  handleValidations(newSelectedState, userData);
                                }}
                              />
                            }
                            style={{ margin: "3px" }}
                          />
                        );
                      })}
                </Box>
                {loading ? (
                  <Loader size="small" />
                ) : (
                  <Flex
                    gap="gap.small"
                    column={context.mediaCategory.sm}
                    style={{ marginTop: "10px" }}
                  >
                    <Box>
                      <Checkbox
                        toggle
                        label="App Owner : "
                        labelPosition="start"
                        defaultChecked={selectedAppOwner}
                        disabled={!selectedSubscription}
                        checked={selectedAppOwner}
                        onClick={(event, isChecked) => {
                          handleSelectedAppOwner(isChecked);
                        }}
                      />
                    </Box>
                    <Box>
                      <Checkbox
                        toggle
                        label="Reporting Access : "
                        labelPosition="start"
                        defaultChecked={selectedReportingAccess}
                        disabled={!selectedSubscription}
                        checked={selectedReportingAccess}
                        onClick={(event, isChecked) => {
                          handleSelectedReportingAccess(isChecked);
                        }}
                      />
                    </Box>
                    <Tooltip
                      trigger={
                        <Box>
                          <Checkbox
                            toggle
                            label="Data Enabled : "
                            labelPosition="start"
                            defaultChecked={selectedSubscription}
                            checked={selectedSubscription}
                            disabled={
                              !canSelectedSubscribe && selectedSubscription
                                ? false
                                : !canSelectedSubscribe
                            }
                            onClick={(event, isChecked) => {
                              handleSelectedSubscription(isChecked);
                            }}
                          />
                        </Box>
                      }
                      align="center"
                      position="below"
                      content={
                        !canSelectedSubscribe && !selectedSubscription
                          && "A maximum of " + tenantDetails.maxUsers + " people can have Data Enabled"
                      }
                    />
                    {selectedTrialExpired ? (
                      <Tooltip
                        trigger={
                          <Box styles={{ marginTop: "3px", marginLeft: "5px"}}>
                            Trial : 
                            <ShiftActivityIcon
                              size="large"
                              styles={{ marginLeft: "10px", color: "grey" }}
                            />
                          </Box>
                        }
                        align="center"
                        position="below"
                        content={"Trial completed"}
                      />
                    ) : (
                      <Box>
                        <Checkbox
                          toggle
                          label="Trial : "
                          labelPosition="start"
                          defaultChecked={selectedTrialSubscription}
                          checked={selectedTrialSubscription}
                          onClick={(event, isChecked) => {
                            handleSelectedTrialSubscription(isChecked);
                          }}
                        />
                      </Box>
                    )}
                  </Flex>
                )}
              </Box>
            )}
          <Flex gap="gap.small" padding="padding.medium" column fill>
            <Flex.Item>
              <Input
                fluid
                icon={<SearchIcon />}
                clearable
                value={searchInput}
                placeholder="Search..."
                onChange={(e, { name, value }) => {
                  setPage(initialDataState);
                  setSearchInput(value);
                }}
              />
            </Flex.Item>
            <Flex.Item>
              <Grid
                style={{ maxHeight: "calc(100vh - 115px)" }}
                detail={context.mediaCategory.sm ? CustomDetailedComponent : null}
                expandField="expanded"
                onExpandChange={expandChange}
                data={
                  userData != null
                    ? userData
                        .filter((user) =>
                          user.userName
                            .toLowerCase()
                            .includes(searchInput.toLowerCase())
                        )
                        .slice(page.skip, page.take + page.skip)
                        .map((item) => ({
                          ...item,
                          [SELECTED_FIELD]: selectedState[idGetter(item)],
                        }))
                    : null
                }
                skip={page.skip}
                take={page.take}
                total={userData != null ? userData.length : 0}
                pageable={{
                  buttonCount: context.mediaCategory.sm ? 2 : 10,
                  info: true,
                }}
                onPageChange={pageChange}
                dataItemKey={DATA_ITEM_KEY}
                selectedField={SELECTED_FIELD}
                selectable={{ enabled: true, drag: false, cell: false }}
                onSelectionChange={selectionChange}
                onHeaderSelectionChange={headerSelectionChange}
              >
                <GridNoRecords>
                  {userData != null ? "No records available" : <Loader />}
                </GridNoRecords>
                <Column
                  width="32px"
                  field="checked"
                  headerSelectionValue={
                    userData != null
                      ? userData.findIndex(
                          (item) => !selectedState[idGetter(item)]
                        ) === -1
                      : false
                  }
                />
                {!context.mediaCategory.sm && (
                  <Column
                    width={context.mediaCategory.sm ? "40px" : "56px"}
                    title=""
                    cell={CustomCellWithUserImage}
                  />
                )}
                <Column
                  width={context.mediaCategory.sm ? "calc(100% - 192px)" : "calc(100% - 265px)"}
                  title="Name"
                  cell={CellWithUserName}
                />
                {!context.mediaCategory.sm && (
                  <Column
                    width="110px"
                    title="App Owner"
                    cell={CustomCellWithAdminAcess}
                    headerCell={CenteredHeader}
                  />
                )}
                {!context.mediaCategory.sm && (
                  <Column
                    width="140px"
                    title="Reporting Access"
                    cell={CustomCellWithReportingAcess}
                    headerCell={CenteredHeader}
                  />
                )}
                <Column
                  width="135px"
                  title="Data Enabled"
                  cell={CustomCellWithSubscription}
                  headerCell={CenteredHeader}
                />
                {!context.mediaCategory.sm && (
                  <Column
                    width="100px"
                    title="Trial"
                    cell={CustomCellWithTrialSubscription}
                    headerCell={CenteredHeader}
                  />
                )}
                <Column
                  width={context.mediaCategory.sm ? "60px" : "80px"}
                  title="Actions"
                  cell={CustomCellWithAction}
                  headerCell={CenteredHeader}
                />
              </Grid>
            </Flex.Item>
          </Flex>

          {(open && selectedUser != null) && (
            <Dialog
              className="settingsDialog"
              title={"Edit"}
              onClose={() => handleCloseAction()}
            >
              <EditUser
                user={selectedUser}
                loggedInUserEmail={loggedInUserEmail}
                handleAccessChange={(isAnalytics, isAdmin) => {
                  handleAccessChange(isAnalytics, isAdmin);
                }}
              />
            </Dialog>
          )}
        </>
        )
      }
    </WindowContext.Consumer>
  );
};

export default UserConfig;
