import axios, { AxiosInstance } from "axios";
import AuthHelper from "./auth-helper";
import { AuthenticationResult } from "@azure/msal-browser";
import * as constants from "../constants";

class DashboardApiService {
  static AuthenticatedApi = axios.create({
    baseURL: constants.Api.baseUri,
    // will inject auth header on-demand later as needed.
    headers: {
      "Content-Type": "application/json",
    },
  });

  // an api operation that calls GetDashboards for a user api endpoint.
  static GetDashboards(token) {
    //debugger;
    return this.AuthenticatedApi.get("/Dashboard/User", {
      headers: {
        Authorization: "Bearer " + token,
      },
    })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        console.log(
          "DashboardApiService.GetDashboards() - An error has occurred calling the web api: " +
            error
        );
        return null;
      });
  }

  static GetDashboardCards(dashboardId, token) {
    return this.AuthenticatedApi.get("/Dashboard/" + dashboardId, {
      headers: {
        Authorization: "Bearer " + token,
      },
    })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        console.log(
          "DashboardApiService.GetDashboardCards() - An error has occurred calling the web api: " +
            error
        );
        return null;
      });
  }

  static GetDashboardCardsWithFilters(dashboardId, token) {
    return this.AuthenticatedApi.get("/Dashboard/Filters/" + dashboardId, {
      headers: {
        Authorization: "Bearer " + token,
      },
    })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        console.log(
          "DashboardApiService.GetDashboardCardsWithFilters() - An error has occurred calling the web api: " +
            error
        );
        return null;
      });
  }

  static GetDashboardLayout(dashboardId, token) {
    return this.AuthenticatedApi.get("/Dashboard/Layout/" + dashboardId, {
      headers: {
        Authorization: "Bearer " + token,
      },
    })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        console.log(
          "DashboardApiService.GetDashboardLayout() - An error has occurred calling the web api: " +
            error
        );
        return null;
      });
  }

  static UpdateDashboardLayout(userDashboardId, fieldValue, token) {
    return this.AuthenticatedApi.put(
      "/Dashboard/Layout/" + userDashboardId,
      fieldValue,
      {
        params: {
          dashboardId: userDashboardId,
        },
        headers: {
          Authorization: "Bearer " + token,
          // ContentType: 'application/json'
        },
      }
    )
      .then((response) => {
        //debugger;
        return response.data;
      })
      .catch((error) => {
        //debugger;
        console.log(
          "DashboardApiService.UpdateDashboardLayout() - An error has occurred calling the web api: " +
            error
        );
      });
  }

  static AddDefaultDashboards(token) {
    return this.AuthenticatedApi.post("/Dashboard/AddDefault/", null, {
      headers: {
        Authorization: "Bearer " + token,
      },
    })
      .then((response) => {
        return true;
      })
      .catch((error) => {
        console.log(
          "DashboardApiService.AddDefaultDashboards() - An error has occurred calling the web api: " +
            error
        );
        return false;
      });
  }

  static AddDashboard(newDashboardName, token, isDemo = false) {
    return this.AuthenticatedApi.post("/Dashboard/Add/", null, {
      params: {
        dashboardName: newDashboardName,
        isDemo: isDemo,
      },
      headers: {
        Authorization: "Bearer " + token,
      },
    })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        console.log(
          "DashboardApiService.AddDashboard() - An error has occurred calling the web api: " +
            error
        );
      });
  }

  static UpdateDashboard(userDashboardId, dashboardName, token) {
    return this.AuthenticatedApi.put(
      "/Dashboard/Update/" + userDashboardId,
      null,
      {
        params: {
          dashboardName: dashboardName,
        },
        headers: {
          Authorization: "Bearer " + token,
        },
      }
    )
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        console.log(
          "DashboardApiService.UpdateDashboard() - An error has occurred calling the web api: " +
            error
        );
      });
  }

  static UpdateDashboardSelectedDate(userDashboardId, selectedDate, token) {
    return this.AuthenticatedApi.put(
      "/Dashboard/UpdateSelectedDate/" + userDashboardId,
      null,
      {
        params: {
          selectedDate: selectedDate,
        },
        headers: {
          Authorization: "Bearer " + token,
        },
      }
    )
      .then((response) => {
        return "Success";
      })
      .catch((error) => {
        console.log(
          "DashboardApiService.UpdateDashboardSelectedDate() - An error has occurred calling the web api: " +
            error
        );
        return "Failure";
      });
  }

  // static FormatDate(date) {
  //     //debugger;
  //     var d = date,
  //         month = '' + (d.getMonth() + 1),
  //         day = '' + d.getDate(),
  //         year = d.getFullYear();

  //     if (month.length < 2)
  //         month = '0' + month;
  //     if (day.length < 2)
  //         day = '0' + day;

  //     return [year, month, day].join('-');
  // }

  static DeleteDashboard(userDashboardId, token) {
    return this.AuthenticatedApi.delete(
      "/Dashboard/Delete/" + userDashboardId,
      {
        headers: {
          Authorization: "Bearer " + token,
        },
      }
    )
      .then((response) => {
        return response.status === 200;
      })
      .catch((error) => {
        console.log(
          "DashboardApiService.DeleteDashboard() - An error has occurred calling the web api: " +
            error
        );
      });
  }
}

export default DashboardApiService;
