import { useEffect, useState } from "react";
import { Input } from "@fluentui/react-northstar";
import { ShiftActivityIcon, CheckmarkCircleIcon } from "@fluentui/react-icons-northstar";
import './nativetimepicker.scss';

const NativeTimePicker = (props) => {
    const [value, setValue] = useState("");
    const [min, setMin] = useState("");
    const [isValid, setIsValid] = useState(false);

    useEffect(() => {
        setValue(props.value);
        setIsValid(props.value != "");
    }, [props.value]);

    useEffect(() => {
        let _min = props.min ? props.min : "00:00";
        setMin(_min);
        if(props.value < _min){
            setValue(_min);
            props.onChange(_min);
            setIsValid(_min != ""); 
        }
        else{
            setIsValid(props.value != ""); 
        }  
    }, [props.min]);

    return(
        <div className="timePickerContainer">
            <label className="timePickerLabel" for={props.id}>{value}{isValid && <CheckmarkCircleIcon outline className="validIndicator" />}<ShiftActivityIcon /></label>
            <Input
                required={props.required}
                className="timePicker"
                type="time"
                min={min}
                value={value}
                defaultValue={value}
                onChange={ (e) => props.onChange(e.target.value)}
                id={props.id}
            />
        </div>
    )
}

export default NativeTimePicker;