import { useState, useEffect } from "react";
import "./dashboard.scss";
import { AllWidgets } from "./allwidgets";
import {
  Flex,
  Segment,
  List,
  Loader,
  Divider,
  Header,
} from "@fluentui/react-northstar";
import DashboardApiService from "../../../services/DashboardApiService";
import AuthHelper from "../../../services/auth-helper";
import SingleCardSettings from "../../filters/card/SingleCardSettings/SingleCardSettings";

const MyCards = (props) => {
  const [myCards, setMyCards] = useState([]);
  useEffect(() => {
    setMyCards(
      props.cards.map((card) => {
        return { key: card.cardID, header: card.name };
      })
    );
  }, []);

  return (
    <>
      <Divider content="My Cards" />
          <List
              styles={{ maxHeight: 'calc(100% - 23px)', overflowY : 'auto'}}
        className="categorylist editCard_list"
        selectable
        selectedIndex={props.selectedCardIndex}
        onSelectedIndexChange={(e, newProps) => {
          props.handleCardChange(
            newProps.selectedIndex,
            newProps.items[newProps.selectedIndex].key
          );
        }}
        items={myCards}
      />
    </>
  );
};

const EditCard = (props) => {
  const [loaded, setLoaded] = useState(false);
  const [dashboardId, setDashboardId] = useState(props.dashboardId);
  const [cards, setCards] = useState([]);
  const [selectedCard, setSelectedCard] = useState(-1);
  const [selectedCardIndex, setSelectedCardIndex] = useState(0);

  useEffect(() => {
    //console.log(props.dashboardId)
    setDashboardId(props.dashboardId);
    setLoaded(false);

    setSelectedCardIndex(0);
    setSelectedCard(-1);

    AuthHelper.getAccessToken(function (token) {
      DashboardApiService.GetDashboardCardsWithFilters(
        props.dashboardId,
        token
      ).then((response) => {
        if (response && response.length > 0) {
          setCards(response);
          if (props.editCardID >= 0) {
            setSelectedCardIndex(
              response.findIndex((res) => res.cardID === props.editCardID)
            );
            setSelectedCard(props.editCardID);
          } else {
            setSelectedCardIndex(0);
            setSelectedCard(response[0].cardID);
          }
        } else {
          setSelectedCardIndex(-1);
          setSelectedCard(-1);
        }
        setLoaded(true);
      });
    });
  }, [props.dashboardId]);
  const handleCardChange = (_cardIndex, _cardId) => {
    setSelectedCardIndex(_cardIndex);
    setSelectedCard(_cardId);
  };

  const onCancel = () => {
    console.log("cancelled");
  };
    const onConfirm = (updatedCardData) => {
    let _cardIndex = cards.findIndex(
      (card) => card.cardID === updatedCardData.cardId
    );
    let _cards = cards;
        let _card = _cards[_cardIndex];
        _card.expiredFilterType = -1;
        _card.expiredFilterValues = null;
    _card.callQuality = updatedCardData.callQualityFilter
      .split(",")
      .map((x) => Number(x));
    _card.groups =
      updatedCardData.GroupFilter !== ""
        ? updatedCardData.GroupFilter.split(",").map((x) => Number(x))
        : [];
    _card.messages = updatedCardData.messagesFilter;
    _card.modalities = updatedCardData.modalitiesFilter
      .split(",")
      .map((x) => Number(x));
    _card.name = updatedCardData.Name;
    _card.reactions = updatedCardData.reactionFilter
      .split(",")
      .map((x) => Number(x));
    _card.runPeriod = updatedCardData.runPeriod;
    _card.selectedEndTime = updatedCardData.selectedEndTime;
    _card.selectedStartTime = updatedCardData.selectedStartTime;
    _card.sentiment = updatedCardData.sentimentFilter
      .split(",")
      .map((x) => Number(x));
    _card.teams =
      updatedCardData.TeamFilter !== ""
        ? updatedCardData.TeamFilter.split(",").map((x) => Number(x))
        : [];
    _card.timePeriod = updatedCardData.timePeriod;
    _card.users =
      updatedCardData.userFilter !== ""
        ? updatedCardData.userFilter.split(",").map((x) => Number(x))
        : [];

    _cards[_cardIndex] = _card;
    setCards(_cards);
  };

  return loaded && props.userFiltersData ? (
    <Flex
      className="editcardcontainer"
      gap="gap.small"
      padding="padding.medium"
      style={{
          minHeight: '100%',
          maxHeight: '100%'
      }}
    >
      {cards && cards.length > 0 && selectedCard > 0 ? (
        <>
          <Flex.Item size="size.quarter">
            <Segment>
              <MyCards
                cards={cards}
                selectedCardIndex={selectedCardIndex}
                handleCardChange={handleCardChange}
              />
            </Segment>
          </Flex.Item>

          <Flex.Item size="size.half" grow>
            <Segment>
              {selectedCard !== -1 ? (
                <>
                  <Header
                    as="h5"
                    styles={{fontSize: '1.15rem'}}
                    content={
                      AllWidgets.find(
                        (widget) =>
                          widget.cardWidgetID ==
                          cards.find((card) => card.cardID == selectedCard)
                            .cardWidgetID
                      ).widgetName
                    }
                  />
                  <SingleCardSettings
                    cardData={cards.find(
                      (card) => card.cardID === selectedCard
                    )}
                    dashboardId={dashboardId}
                    submitHandler={onConfirm}
                    cancelHandler={onCancel}
                    userFiltersData={props.userFiltersData}
                  />
                </>
              ) : (
                <></>
              )}
            </Segment>
          </Flex.Item>
        </>
      ) : (
        <p>Please add cards to edit!</p>
      )}
    </Flex>
  ) : (
    <Loader />
  );
};

export default EditCard;
