import React from "react";
import { useState, useEffect } from "react";
import {
  Flex,
  Form,
  Layout,
  Checkbox,
  Input,
  Grid,
  Button,
  Segment,
  Box,
  Loader,
  Text,
  Dropdown,
  Avatar,
  Label,
  Tooltip,
  RadioGroup,
} from "@fluentui/react-northstar";
import {
  WindowMinimizeIcon,
  PresenceAvailableIcon,
  ExclamationTriangleIcon,
  ContactGroupIcon,
} from "@fluentui/react-icons-northstar";
import "../Dashboard/dashboard.scss";
import "./config.scss";
import ConfigurationApiService from "../../../services/ConfigurationApiService";
import AuthHelper from "../../../services/auth-helper";
import TeamsTimePicker from "../../Shared/uicomponents/TeamsTimePicker";
import NativeTimePicker from "../../Shared/uicomponents/NativeTimePicker";
import WindowContext from "../../Shared/Context/Context";
import {GroupInheritanceIcon, TrialExpiredIcon, LicenseWarningIcon} from "./commonIcons";


const EditProfile = (props) => {
  const [loading, setLoading] = useState(true);
  const [myPeople, setMyPeople] = useState([]);
    const [myGroups, setMyGroups] = useState([]);

  useEffect(() => {
    loadUserProfileData();
  }, []);

  const loadUserProfileData = () => {
    setLoading(true);
    AuthHelper.getAccessToken(function (token) {
      ConfigurationApiService.GetUserProfile(props.user.userGUID, token).then(
        (response) => {
          if (response != undefined && response != "") {
            setMyPeople(response.myPeople);
            setMyGroups(response.myGroups);
          }
          setLoading(false);
          window.dispatchEvent(new Event("resize"));
        }
      );
    });
  };

  return (
    <WindowContext.Consumer>
      {(context) =>
        loading ? (
          <Loader label="Loading..." />
        ) : (
          <Box>
            <Grid
              className="user-profile"
              columns={3}
              styles={{
                width: "100%",
                marginTop: "0",
                marginBottom: "0",
                alignItems: "top",
              }}
            >
              <Segment
                                  className={`user-profile-managerlisting ${props.focused == 1 ? 'focused' : ''}`}
                                  styles={{ height: "330px", overflow: "hidden" }}
                              >
                <Box style={{ marginBottom: "0.5rem" }}>
                    <Text
                        styles={{
                            color: "#666",
                            lineHeight: 1,
                            marginLeft: "5px",
                        }}
                        content="Direct Reports"
                    />
                </Box>
                {
                myPeople && myPeople.filter((p) => p.isDirectReportee === true).length > 0 ? (
                    <div style={{ height: "calc(100% - 18px)", overflow:"auto" }}>
                {
                myPeople.filter((p) => p.isDirectReportee === true).map((people) => {
                return (
                    <Layout
                    gap="0.6rem"
                    start={
                        <Avatar
                        image={people.userImage}
                        name={people.userName}
                        size={
                            context.mediaCategory.sm
                            ? "medium"
                            : "large"
                        }
                        />
                    }
                    main={
                        <div className="user-info">
                        <Box className="">
                            <Text
                            content={people.userName}
                            size={
                                context.mediaCategory.sm
                                ? "small"
                                : "medium"
                            }
                            />
                        </Box>
                        <Text
                            content={people.jobTitle}
                            size={
                            context.mediaCategory.sm
                                ? "smaller"
                                : "small"
                            }
                            timestamp
                        />
                        </div>
                    }
                    end=""
                    style={{
                        marginTop: "0.5rem !important",
                        marginBottom: "0.5rem",
                    }}
                    />
                );
                })
                }
            </div>
                  
                                      ) : (<Flex
                                          column
                                          hAlign="center"
                                          vAlign="center"
                                          gap="gap.small"
                                          style={{
                                              height: 'calc(100% - 18px)',
                                          }}>
                    <ContactGroupIcon
                        className="no-data-icon"
                        outline
                        size="large"
                        style={{ color: "#A9A9A9" }}
                    />
                    <Text
                        content="Data Not Available"
                        size="small"
                        style={{ color: "#A9A9A9" }}
                    />
                </Flex>
                )}
              </Segment>
              <Segment
                                  className={`user-profile-managerlisting ${props.focused == 2 ? 'focused' : ''}`}
                styles={{ height: "330px", overflow: "hidden" }}
                              >
                <Box style={{ marginBottom: "0.5rem" }}>
                    <Text
                        styles={{
                            color: "#666",
                            lineHeight: 1,
                            marginLeft: "5px",
                        }}
                        content="My People"
                    />
                </Box>
                {
                    myPeople && myPeople.length > 0 ? (

                        <div style={{ height: "calc(100% - 18px)", overflow: "auto" }}>
                            {
                                myPeople.map((people) => {
                                    return (
                                        <Layout
                                            gap="0.6rem"
                                            start={
                                                <Avatar
                                                    image={people.userImage}
                                                    name={people.userName}
                                                    size={
                                                        context.mediaCategory.sm
                                                            ? "medium"
                                                            : "large"
                                                    }
                                                />
                                            }
                                            main={
                                                <div className="user-info">
                                                    <Box className="">
                                                        <Text
                                                            content={people.userName}
                                                            size={
                                                                context.mediaCategory.sm
                                                                    ? "small"
                                                                    : "medium"
                                                            }
                                                        />
                                                    </Box>
                                                    <Text
                                                        content={people.jobTitle}
                                                        size={
                                                            context.mediaCategory.sm
                                                                ? "smaller"
                                                                : "small"
                                                        }
                                                        timestamp
                                                    />
                                                </div>
                                            }
                                            end=""
                                            style={{
                                                marginTop: "0.5rem !important",
                                                marginBottom: "0.5rem",
                                            }}
                                        />
                                    );
                                })
                            }
                        </div>
                    ) : (
                                              <Flex
                                                  column
                                                  hAlign="center"
                                                  vAlign="center"
                                                  gap="gap.small"
                                                  style={{
                                                  height: 'calc(100% - 18px)',
                                              }}>
                            <ContactGroupIcon
                                className="no-data-icon"
                                outline
                                size="large"
                                style={{ color: "#A9A9A9" }}
                            />
                            <Text
                                content="Data Not Available"
                                size="small"
                                style={{ color: "#A9A9A9" }}
                            />
                        </Flex>
                    )}
              </Segment>
              <Segment
                                  className={`user-profile-groupslisting ${props.focused == 3 ? 'focused' : ''}`}
                styles={{ height: "330px", overflowY: "hidden" }}
              >
                <Box style={{ marginBottom: "0.5rem" }}>
                  <Text
                    styles={{ color: "#666", lineHeight: 1, marginLeft: "5px" }}
                    content="Groups"
                  />
                </Box>
                                  {myGroups != undefined && myGroups.length > 0 ? (
                                      <div style={{ height: "calc(100% - 18px)", overflow:"auto" }}>
                    {
                        myGroups.map((group) => {
                            return (
                                <Layout
                                    gap="0.6rem"
                                    start={
                                        <Avatar
                                            icon={<ContactGroupIcon />}
                                            size={context.mediaCategory.sm ? "medium" : "large"}
                                        />
                                    }
                                    main={
                                        <div className="user-info">
                                            <Box className="">
                                                <Text
                                                    content={group.groupName}
                                                    size={
                                                        context.mediaCategory.sm ? "small" : "medium"
                                                    }
                                                />
                                            </Box>
                                            <Text
                                                content={group.groupTypeName}
                                                size={
                                                    context.mediaCategory.sm ? "smaller" : "small"
                                                }
                                                timestamp
                                            />
                                        </div>
                                    }
                                    end=""
                                    style={{
                                        marginTop: "0.5rem !important",
                                        marginBottom: "0.5rem",
                                    }}
                                />
                            );
                        })
                    }
                    </div>
                ) : (
                                          <Flex
                                              column
                                              hAlign="center"
                                              vAlign="center"
                                              gap="gap.small"
                                              style={{
                                                  height: 'calc(100% - 18px)',
                                              }}>
                    <ContactGroupIcon
                    className="no-data-icon"
                      outline
                      size="large"
                      style={{ color: "#A9A9A9" }}
                    />
                    <Text
                      content="Data Not Available"
                      size="small"
                      style={{ color: "#A9A9A9" }}
                    />
                  </Flex>
                )}
              </Segment>
            </Grid>
          </Box>
        )
      }
    </WindowContext.Consumer>
  );
};

const EditPermissions = (props) => {
  const [loading, setLoading] = useState(false);
  const [adminAccess, setAdminAccess] = useState(false);
  const [reportingAccess, setReportingAccess] = useState(false);
  const [selectedTeams, setSelectedTeams] = useState([]);
  const [selectedTeamIsGroup, setSelectedTeamIsGroup] = useState([]);
  const [selectedGroups, setSelectedGroups] = useState([]);
  const [teams, setTeams] = useState([]);
  const [groups, setGroups] = useState([]);
  const [teamIsGroup, setTeamIsGroup] = useState(false);
  const [allTeamIsGroup, setAllTeamIsGroup] = useState(false);

  useEffect(() => {
    setAdminAccess(props.user.administrativeAccess);
    setReportingAccess(props.user.reportingAccess);
    loadGroupsData();
  }, []);

  const loadGroupsData = () => {
    setLoading(true);
    AuthHelper.getAccessToken(function (token) {
      ConfigurationApiService.GetGroups(token).then((response) => {
        if (response != undefined && response != "") {
          var tempTeams = [];
          var tempGroups = [];
          response.forEach((item) => {
            var data = {
              header: item.groupName,
              id: item.groupId,
              key: item.groupId
            };
            
            if (item.groupTypeId === 0) {
              tempTeams.push(data);
            } else {
              tempGroups.push(data);
            }
          });
          setTeams(tempTeams);
          setGroups(tempGroups);
        }
        setLoading(false);
        loadUserPermissions();
      });
    });
  };

  const loadUserPermissions = () => {
    setLoading(true);
    AuthHelper.getAccessToken(function (token) {
      ConfigurationApiService.GetUserPermissions(
        props.user.userGUID,
        token
      ).then((response) => {
        if (response != undefined && response != "") {
          var selectedTeamsData = [];
          var selectedGroupsData = [];
          var selectedTeamIsGroupData = [];
          response.forEach((element) => {
            var ob = {
              id: element.groupId,
              header: element.groupName,
              key: element.groupId,
            };
            if (element.isMSTeam) {
              selectedTeamsData.push(ob);
              if(element.teamIsGroup){
                selectedTeamIsGroupData.push(ob);
              }
            } else {
              selectedGroupsData.push(ob);
            }
          });
          setSelectedTeams(selectedTeamsData);
          setSelectedGroups(selectedGroupsData);
          setSelectedTeamIsGroup(selectedTeamIsGroupData);
          if(selectedTeamIsGroupData.length === 0){
            setTeamIsGroup(false);
          }else
          if(selectedTeamIsGroupData.length === selectedTeamsData.length){
            setTeamIsGroup(true);
            setAllTeamIsGroup(true);
          }else{
            setTeamIsGroup(true);
            setAllTeamIsGroup(false);
          }
        }
        setLoading(false);
      });
    });
  };

  const handleTeamSelection = {
    onAdd: (item) => {
      setSelectedTeams(selectedTeams.concat(item));
    },
    onRemove: (item) => {
      let array = [...selectedTeams];
      let index = array.indexOf(item);
      if (index !== -1) {
        array.splice(index, 1);
        setSelectedTeams(array);
      }
      let teamArray = [...selectedTeamIsGroup];
      let teamIndex = teamArray.indexOf(item);
      if (teamIndex !== -1) {
        teamArray.splice(teamIndex, 1);
        setSelectedTeamIsGroup(teamArray);
        teamArray.length === array.length ? setAllTeamIsGroup(true) : setAllTeamIsGroup(false);
      }
    },
  };

  const handleTeamIsGroupSelection = {
    onAdd: (item) => {
      let teamIsGroupData = [...selectedTeamIsGroup, item];
      setSelectedTeamIsGroup(teamIsGroupData);
      teamIsGroupData.length === selectedTeams.length ? setAllTeamIsGroup(true) : setAllTeamIsGroup(false);
    },
    onRemove: (item) => {
      let array = [...selectedTeamIsGroup];
      let index = array.indexOf(item);
      if (index !== -1) {
        array.splice(index, 1);
        setSelectedTeamIsGroup(array);
        array.length === selectedTeams.length ? setAllTeamIsGroup(true) : setAllTeamIsGroup(false);
      }
    },
  };

  const handleAllTeamIsGroup = (checked) => {
    checked ? setSelectedTeamIsGroup(selectedTeams) : setSelectedTeamIsGroup([]);
    setAllTeamIsGroup(checked);
  }

  const handleGroupSelection = {
    onAdd: (item) => {
      setSelectedGroups(selectedGroups.concat(item));
    },
    onRemove: (item) => {
      let array = [...selectedGroups];
      let index = array.indexOf(item);
      if (index !== -1) {
        array.splice(index, 1);
        setSelectedGroups(array);
      }
    },
  };

  const handleAdminAccess = (event, isChecked) => {
    setAdminAccess(isChecked.checked);
  };

  const handleRepotingAccess = (event, isChecked) => {
    setReportingAccess(isChecked.checked);
  };

  const submitPermissions = () => {
    setLoading(true);
    var data = {};
    if ((reportingAccess || props.user.reportingAccessInherited === 1 )) {
      var teamPermissionData = [];
      if(teamIsGroup && allTeamIsGroup){
        teamPermissionData = selectedTeams.map((el) => {
          return {Id: el.id, TeamIsGroup: true}
        })
      } else
      if(teamIsGroup && !allTeamIsGroup){
        teamPermissionData = selectedTeams.map((el) => {
          var tempTeam = selectedTeamIsGroup.find((item) => item.id === el.id) !== undefined;
          return {Id: el.id, TeamIsGroup: tempTeam}
        })
      } else {
        teamPermissionData = selectedTeams.map((el) => {
          return {Id: el.id, TeamIsGroup: false}
        })
      }

      data = {
        userGUID: props.user.userGUID,
        adminAccess: adminAccess,
        reportingAccess: reportingAccess,
        groupPermissions: selectedGroups.map((el) => {
          return el.id;
        }),
        teamPermissions: teamPermissionData,
      };
    } else {
      data = {
        userGUID: props.user.userGUID,
        adminAccess: adminAccess,
        reportingAccess: reportingAccess,
        groupPermissions: [],
        teamPermissions: [],
      };
      setSelectedTeams([]);
      setSelectedGroups([]);
      setSelectedTeamIsGroup([]);
      setAllTeamIsGroup(false);
    }
    AuthHelper.getAccessToken(function (token) {
      ConfigurationApiService.UpdateUserPermissions(data, token).then(
        (response) => {
          if (response != undefined && response != "") {
            props.handleAccessChange(reportingAccess, adminAccess);
          }
          setLoading(false);
        }
      );
    });
  };

  return loading ? (
    <Loader label="Loading..." />
  ) : (
    <Box className="permissions-group">
      <Grid
        columns={2}
        styles={{
          width: "100%",
          marginTop: "20px",
          alignItems: "center",
          gridColumnGap: "8px",
          gridTemplateColumns: "135px calc(100% - 185px)",
        }}
      >
        <Text content="App Owner : " weight="semibold" />
        {
          !adminAccess && props.user.administrativeAccessInherited !== 0 ? (
            props.user.administrativeAccessInherited === 1 ? 
            <GroupInheritanceIcon styles={{marginLeft:"10px"}}/> : <LicenseWarningIcon styles={{marginLeft:"10px"}}/>
          ) : (
            <Box>
              <Tooltip
                trigger={
                <Checkbox
                  toggle
                  disabled={props.user.email == props.loggedInUserEmail || (!props.user.isSubscribed && props.user.isSubscribedInherited !== 1)}
                  checked={adminAccess}
                  onClick={handleAdminAccess}
                />
                }
                align="center"
                position="below"
                content={(props.user.email == props.loggedInUserEmail)? "Edit permission disabled for own account" : "Edit App Owner status."}
              />
            </Box>
          )
        }
      </Grid>
      <Grid
        columns={2}
        styles={{
          width: "100%",
          marginTop: "20px",
          alignItems: "center",
          gridColumnGap: "8px",
          gridTemplateColumns: "135px calc(100% - 185px)",
        }}
      >
        <Text content="Reporting Access : " weight="semibold" />
        {
          !reportingAccess && props.user.reportingAccessInherited !== 0 ? (
            props.user.reportingAccessInherited === 1 ?
            <Box styles={{marginLeft:"10px"}}><GroupInheritanceIcon /></Box>
            : <Box styles={{marginLeft:"10px"}}><LicenseWarningIcon /></Box>
          ) : (
            <Box>
              <Tooltip
                trigger={
                <Checkbox
                  toggle
                  disabled={!props.user.isSubscribed && props.user.isSubscribedInherited !== 1}
                  checked={reportingAccess}
                  onClick={handleRepotingAccess}
                />
                }
                align="center"
                position="below"
                content={"Edit Reporting Access status."}
              />
            </Box>
          )
        }
      </Grid>
      {(reportingAccess || props.user.reportingAccessInherited === 1 ) && (
        <>
          <Grid
            columns={2}
            styles={{
              width: "100%",
              marginTop: "20px",
              alignItems: "center",
              gridColumnGap: "8px",
              gridTemplateColumns: "135px calc(100% - 185px)",
            }}
          >
            <Text content="People : " weight="semibold" />
            <Box>
                <Text className="profile-link" onClick={() => props.handleProfileClick(2) } content="My people" weight="regular" />
                <Text content=" | " weight="regular" />
                <Text className="profile-link" onClick={() => props.handleProfileClick(1)} content="Direct Reports" weight="regular" />
            </Box>
          </Grid>

          <Grid
            className="whRow"
            columns={3}
            styles={{
              width: "100%",
              marginTop: "20px",
              alignItems: "top",
              gridColumnGap: "8px",
              gridTemplateColumns: "135px 320px calc(100% - 470px)",
            }}
          >
            <Text content="Teams : " weight="semibold" />
            <Box>
              <Dropdown
                search
                multiple
                items={teams}
                placeholder="Select Teams"
                disabled={!(reportingAccess || props.user.reportingAccessInherited === 1 )}
                getA11ySelectionMessage={handleTeamSelection}
                defaultValue={selectedTeams}
                itemToValue={(obj) => {
                  return obj.key;
                }}
              />
            </Box>
            <Box>
              <Checkbox
                label="Enable reporting access on activity outside selected Teams"
                value={teamIsGroup}
                checked={teamIsGroup}
                onChange={(e, { checked }) => {setTeamIsGroup(checked)}}
              />
              {teamIsGroup && 
                <Dropdown
                  styles={{marginTop: "15px", marginLeft: "30px"}}
                  search
                  multiple
                  items={selectedTeams}
                  placeholder="Select Teams"
                  disabled={!(reportingAccess || props.user.reportingAccessInherited === 1 )}
                  headerMessage={
                    <Checkbox
                      checked={allTeamIsGroup} 
                      onChange={(e, { checked }) => {handleAllTeamIsGroup(checked)}} 
                      label={<Text content="Select All" weight="semibold" />} 
                    />
                  }
                  getA11ySelectionMessage={handleTeamIsGroupSelection}
                  value={selectedTeamIsGroup}
                  itemToValue={(obj) => {
                    return obj.key;
                  }}
                />
              }
            </Box>
          </Grid>
          <Grid
            className="whRow"
            columns={2}
            styles={{
              width: "100%",
              marginTop: "20px",
              alignItems: "center",
              gridColumnGap: "8px",
              gridTemplateColumns: "135px calc(100% - 143px)",
            }}
          >
            <Text content="Groups : " weight="semibold" />
            <Dropdown
              search
              multiple
              items={groups}
              placeholder="Select Groups"
              disabled={!(reportingAccess || props.user.reportingAccessInherited === 1 )}
              getA11ySelectionMessage={handleGroupSelection}
              defaultValue={selectedGroups}
              itemToValue={(obj) => {
                return obj.key;
              }}
            />
          </Grid>
        </>
      )}

      <Flex space="between" styles={{ marginTop: "40px" }}>
        <Box />
        <Flex gap="gap.small">
          <Button content="Save" primary onClick={submitPermissions} />
        </Flex>
      </Flex>
    </Box>
  );
};

const TimePickerComponent = (props) => {
  return (
    <>
      <Text
        content={props.lableText}
        align="start"
        styles={{ marginRight: "8px" }}
      />
      {props.context.teams.hostClientType.web ||
      props.context.teams.hostClientType.desktop ? (
        <TeamsTimePicker
          value={props.startTime}
          onChange={(value) => {
            props.onStartTimeChange(value);
          }}
          required={true}
        />
      ) : (
        <NativeTimePicker
          id={props.day + "StartTime"}
          value={props.startTime}
          onChange={(value) => {
            props.onStartTimeChange(value);
          }}
          required={true}
        />
      )}
      <WindowMinimizeIcon />
      {props.context.teams.hostClientType.web ||
      props.context.teams.hostClientType.desktop ? (
        <TeamsTimePicker
          value={props.endTime}
          onChange={(value) => {
            props.onEndTimeChange(value);
          }}
          required={true}
        />
      ) : (
        <NativeTimePicker
          id={props.day + "EndTime"}
          value={props.endTime}
          onChange={(value) => {
            props.onEndTimeChange(value);
          }}
          required={true}
        />
      )}
    </>
  );
};

const EditOfficeHours = (props) => {
  const [loading, setLoading] = useState(false);
  const [templateData, setTemplateData] = useState([]);
  const [mondayStart, setMondayStart] = useState("00:00");
  const [mondayEnd, setMondayEnd] = useState("00:00");
  const [tuesdayStart, setTuesdayStart] = useState("00:00");
  const [tuesdayEnd, setTuesdayEnd] = useState("00:00");
  const [wednesdayStart, setWednesdayStart] = useState("00:00");
  const [wednesdayEnd, setWednesdayEnd] = useState("00:00");
  const [thursdayStart, setThursdayStart] = useState("00:00");
  const [thursdayEnd, setThursdayEnd] = useState("00:00");
  const [fridayStart, setFridayStart] = useState("00:00");
  const [fridayEnd, setFridayEnd] = useState("00:00");
  const [saturdayStart, setSaturdayStart] = useState("00:00");
  const [saturdayEnd, setSaturdayEnd] = useState("00:00");
  const [sundayStart, setSundayStart] = useState("00:00");
  const [sundayEnd, setSundayEnd] = useState("00:00");

  useEffect(() => {
    loadTemplateData();
    loadUserTimeDetail();
  }, [props.user]);

  const loadTemplateData = () => {
    setLoading(true);
    AuthHelper.getAccessToken(function (token) {
      ConfigurationApiService.GetTemplates(token).then((response) => {
        if (response != undefined && response != "") {
          var tempData = response.map((element) => {
            var ob = Object.assign({}, element);
            ob.header = ob.name;
            ob.key = ob.templateGUID;
            return ob;
          });
          setTemplateData(tempData);
        }
        setLoading(false);
      });
    });
  };

  const loadUserTimeDetail = () => {
    setLoading(true);
    AuthHelper.getAccessToken(function (token) {
      ConfigurationApiService.GetUserWorkingHours(
        props.user.userGUID,
        token
      ).then((response) => {
        if (response != undefined && response != "") {
          response.forEach((item) => {
            if (item.dayOfWeekId === 0) {
              setSundayEnd(item.endTime);
              setSundayStart(item.startTime);
            } else if (item.dayOfWeekId === 1) {
              setMondayEnd(item.endTime);
              setMondayStart(item.startTime);
            } else if (item.dayOfWeekId === 2) {
              setTuesdayEnd(item.endTime);
              setTuesdayStart(item.startTime);
            } else if (item.dayOfWeekId === 3) {
              setWednesdayEnd(item.endTime);
              setWednesdayStart(item.startTime);
            } else if (item.dayOfWeekId === 4) {
              setThursdayEnd(item.endTime);
              setThursdayStart(item.startTime);
            } else if (item.dayOfWeekId === 5) {
              setFridayEnd(item.endTime);
              setFridayStart(item.startTime);
            } else if (item.dayOfWeekId === 6) {
              setSaturdayEnd(item.endTime);
              setSaturdayStart(item.startTime);
            }
          });
        }
        setLoading(false);
      });
    });
  };

  const getA11ySelectionMessage = {
    onAdd: (item) => {
      setLoading(true);
      var timeData = item.timeDetails;
      timeData.forEach((item) => {
        if (item.dayOfWeekId === 0) {
          setSundayEnd(item.endTime);
          setSundayStart(item.startTime);
        } else if (item.dayOfWeekId === 1) {
          setMondayEnd(item.endTime);
          setMondayStart(item.startTime);
        } else if (item.dayOfWeekId === 2) {
          setTuesdayEnd(item.endTime);
          setTuesdayStart(item.startTime);
        } else if (item.dayOfWeekId === 3) {
          setWednesdayEnd(item.endTime);
          setWednesdayStart(item.startTime);
        } else if (item.dayOfWeekId === 4) {
          setThursdayEnd(item.endTime);
          setThursdayStart(item.startTime);
        } else if (item.dayOfWeekId === 5) {
          setFridayEnd(item.endTime);
          setFridayStart(item.startTime);
        } else if (item.dayOfWeekId === 6) {
          setSaturdayEnd(item.endTime);
          setSaturdayStart(item.startTime);
        }
      });
      setLoading(false);
    },
  };

  const submitForm = () => {
    setLoading(true);
    var data = [
      {
        userGUID: props.user.userGUID,
        dayOfWeekId: 0,
        startTime: sundayStart,
        endTime: sundayEnd,
      },
      {
        userGUID: props.user.userGUID,
        dayOfWeekId: 1,
        startTime: mondayStart,
        endTime: mondayEnd,
      },
      {
        userGUID: props.user.userGUID,
        dayOfWeekId: 2,
        startTime: tuesdayStart,
        endTime: tuesdayEnd,
      },
      {
        userGUID: props.user.userGUID,
        dayOfWeekId: 3,
        startTime: wednesdayStart,
        endTime: wednesdayEnd,
      },
      {
        userGUID: props.user.userGUID,
        dayOfWeekId: 4,
        startTime: thursdayStart,
        endTime: thursdayEnd,
      },
      {
        userGUID: props.user.userGUID,
        dayOfWeekId: 5,
        startTime: fridayStart,
        endTime: fridayEnd,
      },
      {
        userGUID: props.user.userGUID,
        dayOfWeekId: 6,
        startTime: saturdayStart,
        endTime: saturdayEnd,
      },
    ];
    AuthHelper.getAccessToken(function (token) {
      ConfigurationApiService.UpdateUserWorkingHours(data, token).then(
        (response) => {
          setLoading(false);
        }
      );
    });
  };

  return (
    <WindowContext.Consumer>
      {(context) =>
        loading ? (
          <Loader label="Loading..." />
        ) : (
          <Box>
            <Form
              className="edit_user_officeHoursForm"
              onSubmit={submitForm}
              styles={{ marginTop: "0px", fontSize: "0.9rem" }}
            >
              <Grid
                columns={4}
                styles={{
                  width: "100%",
                  alignItems: "center",
                  gridColumnGap: "8px",
                  gridTemplateColumns:
                    "94px calc(50% - 72px) 25px calc(50% - 72px)",
                  marginBottom: "0.6rem !important",
                }}
              >
                <TimePickerComponent
                  lableText={"Monday : "}
                  day={"monday"}
                  context={context}
                  startTime={mondayStart}
                  endTime={mondayEnd}
                  onStartTimeChange={(value) => {
                    setMondayStart(value);
                  }}
                  onEndTimeChange={(value) => {
                    setMondayEnd(value);
                  }}
                />
              </Grid>
              <Grid
                columns={4}
                styles={{
                  width: "100%",
                  alignItems: "center",
                  gridColumnGap: "8px",
                  gridTemplateColumns:
                    "94px calc(50% - 72px) 25px calc(50% - 72px)",
                  marginBottom: "0.6rem !important",
                }}
              >
                <TimePickerComponent
                  lableText={"Tuesday : "}
                  day={"tuesday"}
                  context={context}
                  startTime={tuesdayStart}
                  endTime={tuesdayEnd}
                  onStartTimeChange={(value) => {
                    setTuesdayStart(value);
                  }}
                  onEndTimeChange={(value) => {
                    setTuesdayEnd(value);
                  }}
                />
              </Grid>
              <Grid
                columns={4}
                styles={{
                  width: "100%",
                  alignItems: "center",
                  gridColumnGap: "8px",
                  gridTemplateColumns:
                    "94px calc(50% - 72px) 25px calc(50% - 72px)",
                  marginBottom: "0.6rem !important",
                }}
              >
                <TimePickerComponent
                  lableText={"Wednesday : "}
                  day={"wednesday"}
                  context={context}
                  startTime={wednesdayStart}
                  endTime={wednesdayEnd}
                  onStartTimeChange={(value) => {
                    setWednesdayStart(value);
                  }}
                  onEndTimeChange={(value) => {
                    setWednesdayEnd(value);
                  }}
                />
              </Grid>
              <Grid
                columns={4}
                styles={{
                  width: "100%",
                  alignItems: "center",
                  gridColumnGap: "8px",
                  gridTemplateColumns:
                    "94px calc(50% - 72px) 25px calc(50% - 72px)",
                  marginBottom: "0.6rem !important",
                }}
              >
                <TimePickerComponent
                  lableText={"Thursday : "}
                  day={"thursday"}
                  context={context}
                  startTime={thursdayStart}
                  endTime={thursdayEnd}
                  onStartTimeChange={(value) => {
                    setThursdayStart(value);
                  }}
                  onEndTimeChange={(value) => {
                    setThursdayEnd(value);
                  }}
                />
              </Grid>
              <Grid
                columns={4}
                styles={{
                  width: "100%",
                  alignItems: "center",
                  gridColumnGap: "8px",
                  gridTemplateColumns:
                    "94px calc(50% - 72px) 25px calc(50% - 72px)",
                  marginBottom: "0.6rem !important",
                }}
              >
                <TimePickerComponent
                  lableText={"Friday : "}
                  day={"friday"}
                  context={context}
                  startTime={fridayStart}
                  endTime={fridayEnd}
                  onStartTimeChange={(value) => {
                    setFridayStart(value);
                  }}
                  onEndTimeChange={(value) => {
                    setFridayEnd(value);
                  }}
                />
              </Grid>
              <Grid
                columns={4}
                styles={{
                  width: "100%",
                  alignItems: "center",
                  gridColumnGap: "8px",
                  gridTemplateColumns:
                    "94px calc(50% - 72px) 25px calc(50% - 72px)",
                  marginBottom: "0.6rem !important",
                }}
              >
                <TimePickerComponent
                  lableText={"Saturday : "}
                  day={"saturday"}
                  context={context}
                  startTime={saturdayStart}
                  endTime={saturdayEnd}
                  onStartTimeChange={(value) => {
                    setSaturdayStart(value);
                  }}
                  onEndTimeChange={(value) => {
                    setSaturdayEnd(value);
                  }}
                />
              </Grid>
              <Grid
                columns={4}
                styles={{
                  width: "100%",
                  alignItems: "center",
                  gridColumnGap: "8px",
                  gridTemplateColumns:
                    "94px calc(50% - 72px) 25px calc(50% - 72px)",
                  marginBottom: "0.6rem !important",
                }}
              >
                <TimePickerComponent
                  lableText={"Sunday : "}
                  day={"sunday"}
                  context={context}
                  startTime={sundayStart}
                  endTime={sundayEnd}
                  onStartTimeChange={(value) => {
                    setSundayStart(value);
                  }}
                  onEndTimeChange={(value) => {
                    setSundayEnd(value);
                  }}
                />
              </Grid>

              <Grid
                className="whRow"
                columns={2}
                styles={{
                  width: "100%",
                  marginTop: "10px",
                  alignItems: "center",
                  gridColumnGap: "8px",
                  gridTemplateColumns: "130px calc(100% - 130px)",
                  marginBottom: "0.5rem !important",
                  marginTop: "0.5rem",
                }}
              >
                <Text
                  content="Update Schedule : "
                  weight="semibold"
                  styles={{ marginRight: "8px" }}
                />
                <Dropdown
                  search
                  items={templateData}
                  placeholder="select a profile"
                  getA11ySelectionMessage={getA11ySelectionMessage}
                  itemToValue={(obj) => {
                    return obj.key;
                  }}
                />
              </Grid>
              <Flex space="between" styles={{ marginTop: "0.6rem" }}>
                <Box />
                <Flex gap="gap.small">
                  <Button content="Save" type="submit" primary />
                </Flex>
              </Flex>
            </Form>
          </Box>
        )
      }
    </WindowContext.Consumer>
  );
};

const EditUser = (props) => {
  const [loading, setLoading] = useState(false);
    const [selectedTab, setSelectedTab] = useState("profile");
    const [focused, setFocused] = useState(0);

  const handleAccessChange = (isAnalytics, isAdmin) => {
    props.handleAccessChange(isAnalytics, isAdmin);
    };

  return (
    <WindowContext.Consumer>
      {(context) =>
        loading ? (
          <Loader label="Loading..." />
        ) : (
          <Box>
            <Grid
            className="user-profile-header"
            columns={2}
            >
              <Box>
              <Layout
                gap="0.6rem"
                start={
                  <Avatar
                    image={props.user.userImage}
                    size={context.mediaCategory.sm ? "large" : "larger"}
                    name={props.user.userName}
                  />
                }
                main={
                  <div className="user-info">
                    <Text
                      content={props.user.userName}
                      size={context.mediaCategory.sm ? "medium" : "large"}
                    />
                    <Text
                      content={props.user.jobTitle}
                      size={context.mediaCategory.sm ? "small" : "medium"}
                      timestamp
                    />
                    <Text
                      content={props.user.email}
                      size={context.mediaCategory.sm ? "small" : "medium"}
                      timestamp
                    />
                  </div>
                }
                end=""
                styles={{ marginTop: "0" }}
              />
              </Box>
              <Box>
              {(selectedTab === "profile" && (props.user.managerName != undefined &&
                  props.user.managerName.trim() != "")) ? (
                    <Box>
                      <Text
                        styles={{
                          color: "#666",
                          lineHeight: 1,
                          marginLeft: "5px",
                        }}
                        content="Manager"
                      />
                      <Layout
                        gap="0.6rem"
                        start={
                          <Avatar
                            image={props.user.managerPhoto}
                            size={
                              context.mediaCategory.sm ? "medium" : "large"
                            }
                            name={props.user.managerName}
                          />
                        }
                        main={
                          <div className="user-info">
                            <Box className="">
                              <Text
                                content={props.user.managerName}
                                size={
                                  context.mediaCategory.sm
                                    ? "small"
                                    : "medium"
                                }
                              />
                            </Box>
                            <Text
                              content={props.user.managerJobTitle}
                              size={
                                context.mediaCategory.sm ? "smaller" : "small"
                              }
                              timestamp
                            />
                          </div>
                        }
                        end=""
                        style={{
                          marginTop: "0.5rem !important",
                          marginBottom: "0.5rem",
                        }}
                      />
                    </Box>
                  ) : (
                    <Box />
                  )}
              </Box>
            </Grid>
            <Flex
              className="addcardcontainer"
              column
              gap="gap.small"
              styles={{ marginTop: "1rem" }}
            >
              <div className="editBtnGroup">
                <Button
                  content="Profile"
                                      onClick={() => { setSelectedTab("profile"); setFocused(0); }}
                  primary={selectedTab === "profile"}
                />
                <Button
                  content="Permissions"
                  onClick={() => {
                      setSelectedTab("permissions");
                      setFocused(0);
                    setTimeout(function () {
                      document
                        .querySelectorAll(".k-dialog .ui-input__input")
                        .forEach((item) => {
                          item.addEventListener("keydown", function (e) {
                            if (e.keyCode === 32) {
                              e.stopPropagation();
                            }
                          });
                        });
                    }, 1000);
                  }}
                  primary={selectedTab === "permissions"}
                  disabled={!props.user.canLogin}
                />
                <Button
                  content="Working Hours"
                                      onClick={() => { setSelectedTab("workinghours"); setFocused(0); }}
                  primary={selectedTab === "workinghours"}
                />
              </div>
              <Segment
                className="edit_user_container"
                styles={{ minWidth: "400px" }}
              >
                                  {selectedTab === "profile" && <EditProfile user={props.user} focused={focused} />}
                {selectedTab === "permissions" && (
                  <EditPermissions
                                          user={props.user}
                                          loggedInUserEmail={props.loggedInUserEmail}
                                          handleAccessChange={(isAnalytics, isAdmin) => {
                                              handleAccessChange(isAnalytics, isAdmin);
                                          }}
                                          handleProfileClick={(focused) => {
                                              setFocused(focused);
                                              setSelectedTab("profile");
                                          }}
                  />
                )}
                {selectedTab === "workinghours" && (
                  <EditOfficeHours user={props.user} />
                )}
              </Segment>
            </Flex>
          </Box>
        )
      }
    </WindowContext.Consumer>
  );
};

export default EditUser;
