import React from "react";
import { useState, useEffect } from "react";
import {
  Flex,
  Button,
  Input,
  Loader,
  Dialog as FluentDialog,
} from "@fluentui/react-northstar";
import {
  AddIcon,
  TrashCanIcon,
  EditIcon,
  SearchIcon,
} from "@fluentui/react-icons-northstar";
import { Dialog } from "@progress/kendo-react-dialogs";
import ConfigurationApiService from "../../../services/ConfigurationApiService";
import AuthHelper from "../../../services/auth-helper";
import {
  Grid,
  GridColumn as Column,
  GridNoRecords,
} from "@progress/kendo-react-grid";
import BusinessHourTemplate from "./workinghourstemplate";
import "../Dashboard/dashboard.scss";
import CenteredHeader from "../../Shared/Header/Header";
import useMediaQuery from "../../Shared/CustomHooks/useMediaQuery";
import WindowContext from "../../Shared/Context/Context";


const CellWithAction = (props) => {
  const data = props.dataItem;
  return (
    <WindowContext.Consumer>
      {
        context => (
        <td>
          <Flex hAlign="center">
            <Button
              onClick={() => props.onOpen(data, true)}
              icon={<EditIcon />}
              content={context.mediaCategory.sm ? "" : "Edit"}
              iconOnly={context.mediaCategory.sm}
              title="Edit"
              size="small"
              primary
            />
            <FluentDialog
              cancelButton="Cancel"
              confirmButton="Delete"
              onConfirm={() => props.handleDeleteConfirm(data.templateGUID)}
              closeOnOutsideClick={false}
              defaultOpen={false}
              styles={{ maxWidth: "500px" }}
              trigger={
                <Button
                  icon={<TrashCanIcon />}
                  styles={{ marginLeft: "8px" }}
                  content={context.mediaCategory.sm ? "" : "Delete"}
                  iconOnly={context.mediaCategory.sm}
                  title="Delete"
                  size="small"
                  primary
                />
              }
              content={"Are you sure you want to delete this Profile?"}
            />
          </Flex>
        </td>
        )
      }
    </WindowContext.Consumer>
  );
};

const BusinessHoursConfig = (props) => {
  const [templateData, setTemplateData] = useState(null);
  const [searchInput, setSearchInput] = useState("");
  const [open, setOpen] = useState(false);
  const [selectedBH, setSelectedBH] = useState(null);
  const [isEdit, setIsEdit] = useState(false);


  useEffect(() => {
    setSearchInput("");
    loadTemplateData();
  }, []);

  const onOpen = (businessHourDetail, _isEdit) => {
    setIsEdit(_isEdit);
    setSelectedBH(businessHourDetail);
    setOpen(true);
  };

  const onCancel = () => {
    setOpen(false);
    setIsEdit(false);
    setSelectedBH(null);
  };

  const loadTemplateData = () => {
    setTemplateData(null);
    AuthHelper.getAccessToken(function (token) {
      ConfigurationApiService.GetTemplates(token).then((response) => {
        if (response != "") {
          setTemplateData(response);
        } else {
          setTemplateData([]);
        }
      });
    });
  };

  const handleAddConfirm = (template) => {
    var tempData = templateData;
    tempData.push(template);
    setTemplateData(tempData);
    onCancel();
  };

  const handleUpdateConfirm = (template) => {
    var tempData = templateData;
    var indexId = tempData.findIndex(
      (x) => x.templateGUID === template.templateGUID
    );
    tempData[indexId] = template;
    setTemplateData(tempData);
    onCancel();
  };

  const handleDeleteConfirm = (templateGUID) => {
    var tempData = templateData;
    setTemplateData(null);
    AuthHelper.getAccessToken(function (token) {
      ConfigurationApiService.DeleteTemplate(templateGUID, token).then(
        (response) => {
          if (response !== undefined && response !== "") {
            var indexId = tempData.findIndex(
              (x) => x.templateGUID === templateGUID
            );
            tempData.splice(indexId, 1);
          }
          setTemplateData(tempData);
        }
      );
    });
  };

  const CustomCellWithAction = (props) => {
    return (
      <CellWithAction
        {...props}
        onOpen={(_data, _isEdit) => {
          onOpen(_data, _isEdit);
        }}
        handleDeleteConfirm={handleDeleteConfirm}
      />
    );
  };

  return (
    <WindowContext.Consumer>
      {
        context => (
        <>
          <Flex gap="gap.small" padding="padding.medium" column fill hAlign="end">
            <Flex.Item>
              <Button
                onClick={() => onOpen(null, false)}
                icon={<AddIcon />}
                content="Add New"
                size="small"
                primary
              />
            </Flex.Item>
            <Flex.Item>
              <Input
                fluid
                icon={<SearchIcon />}
                clearable
                value={searchInput}
                placeholder="Search..."
                onChange={(e, { name, value }) => {
                  setSearchInput(value);
                }}
              />
            </Flex.Item>
            <Flex.Item>
              <Grid
                style={{ maxHeight: "calc(100vh - 145px)" }}
                data={
                  templateData != null
                    ? templateData.filter((obj) =>
                        obj.name.toLowerCase().includes(searchInput.toLowerCase())
                      )
                    : null
                }
              >
                <GridNoRecords>
                  {templateData != null ? "No records available" : <Loader />}
                </GridNoRecords>
                <Column
                  width={context.mediaCategory.sm ? "calc(100% - 80px)" : "calc(100% - 180px)"}
                  field="name"
                  title="Profile Name"
                />
                <Column
                  width={context.mediaCategory.sm ? "80px" : "180px"}
                  title="Actions"
                  cell={CustomCellWithAction}
                  headerCell={CenteredHeader}
                />
              </Grid>
            </Flex.Item>
          </Flex>
          {open && (
            <Dialog
              autoFocus={true}
              className="settingsDialog"
              title={isEdit ? "Edit Profile" : "Add New Profile"}
              onClose={() => onCancel()}
            >
              <BusinessHourTemplate
                isEdit={isEdit}
                handleFormSubmit={isEdit ? handleUpdateConfirm : handleAddConfirm}
                onCancel={onCancel}
                templateValues={selectedBH}
              />
            </Dialog>
          )}
        </>
        )
      }
    </WindowContext.Consumer>
  );
};

export default BusinessHoursConfig;
