export const TeamsThemes = {
    contrast: "constrast",
    dark: "dark",
    default: "default"
};








export const isLocal = false;

const Api_Local = {
    baseUri: "https://localhost:44309/api"
}

const Api_Prod = {
    //baseUri: "https://apptest.uk.analytics-365.com/api"
    baseUri: "https://app.uk.analytics-365.com/api"
}

export const Api = isLocal ? Api_Local : Api_Prod;


const Auth_Prod = {
    //appId: "e772d10b-c961-4afe-8965-4e2cabcc0751",
    //audience: "api://apptest.uk.analytics-365.com/e772d10b-c961-4afe-8965-4e2cabcc0751"
    appId: "ed734a73-73d5-4339-bb60-b078d9fea5a2",
    audience: "api://app.uk.analytics-365.com/ed734a73-73d5-4339-bb60-b078d9fea5a2"
};

const Auth_Local = {
    appId: "e772d10b-c961-4afe-8965-4e2cabcc0751",
    cacheLocation: 'localStorage',
    loginPage: 'login',
    signInStartPage: 'signinstart',
    silentTokenStartPage: 'silenttokenstart',
    signInEndPage: 'signinend',
    authenticatedDomains: {
        "https://graph.microsoft.com": "https://graph.microsoft.com"
    }
};

export const Auth = isLocal ? Auth_Local : Auth_Prod;