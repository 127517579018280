import "./App.css";
import { Provider } from "@fluentui/react-northstar";
import { Fragment } from "react";
import Dashboards from "./components/pages/Dashboard";
import Configuration from "./components/pages/Configuration/configurations";

import AccessDenied from "./components/Auth/AccessDenied";
import NotProvisioned from "./components/Auth/NotProvisioned";
import ProvisioningInProgress from "./components/Auth/ProvisioningInProgress";

import SignInStart from "./components/Auth/SignInStart";
import SignInEnd from "./components/Auth/SignInEnd";
import SilentTokenStart from "./components/Auth/SilentTokenStart";

import { Layout } from "./components/Layout";
import NotTeams from "./components/pages/Home/NotTeams";
import {
  Route,
  Switch,
  BrowserRouter as Router,
  Redirect,
} from "react-router-dom";
import AuthenticatedRoute from "./components/RouteHelper";
import AppOverview from './components/Shared/uicomponents/AppOverview';
import WindowProvider from "./components/Shared/Context/ContextProvider";
import WindowContext from "./components/Shared/Context/Context";

const ProtectedRouteContainer = (props) => {
  return (
    <Layout themeString={props.themeString} themeHandler={props.setTheme}>
      <Switch>
        <Route exact path="/" render={() => <Redirect to="/dashboards" />} />
        <AuthenticatedRoute path="/dashboards" component={Dashboards} />
        <AuthenticatedRoute path="/configuration" component={Configuration} />
        <Route path='/appoverview' component={AppOverview} />
      </Switch>
    </Layout>
  );
};
const App = () => {
  return (
    <WindowProvider>
      <WindowContext.Consumer>
        {(context) =>
          context.teams.inTeams || context.constants.isLocal ? (
            <Provider theme={context.teams.theme}>
              <Router>
                <Fragment>
                  <Switch>
                    <Route exact path="/provision" component={NotProvisioned} />
                    <Route
                      exact
                      path="/accessdenied"
                      component={AccessDenied}
                    />
                    <Route
                      exact
                      path="/provisioning"
                      component={ProvisioningInProgress}
                    />
                    <Route exact path="/signinstart" component={SignInStart} />
                    <Route
                      exact
                      path="/silenttokenstart"
                      component={SilentTokenStart}
                    />
                    <Route exact path="/signinend" component={SignInEnd} />
                    <Route>
                      <ProtectedRouteContainer
                        setTheme={context.teams.setTheme}
                        themeString={context.teams.themeString}
                      />
                    </Route>
                  </Switch>
                </Fragment>
              </Router>
            </Provider>
          ) : (
            <NotTeams />
          )
        }
      </WindowContext.Consumer>
    </WindowProvider>
  );
};

export default App;
