import React, { useEffect, useState } from 'react';
import CircleChart from '../../Shared/uicomponents/circleChart';
import './UserComparisonView.scss';

const UserComparisonView = ({ data }) => {
    return (
        <div className="userComparisonView">
        <div className="compareGraph">
            <div className="compareTrk compareTm">
                <div className="topUsrArea">
                    <div className="topUsrAreaPic">
                        <div className="topUsrAreaPic-i">
                            <div className="contact-list-icon">
                                <div className="contact-list-icon-txt">{data.initials1}</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="compareTrk compareData">
                <div className="compareDataInr">
                    <div className="compareDataInrRow tmRowTitle">
                        <div className="compareDataInrRow-val"></div>
                        <div className="compareDataInrRow-p">User Stats</div>
                        <div className="compareDataInrRow-val"></div>
                    </div>
                    <div className="compareDataInrRow">
                        <div className="compareDataInrRow-val">{data.callsOrganized1}</div>
                        <div className="compareDataInrRow-p">Meetings Organised</div>
                        <div className="compareDataInrRow-val">{data.callsOrganized2}</div>
                    </div>
                    <div className="compareDataInrRow">
                        <div className="compareDataInrRow-val">{data.reactionCount1}</div>
                        <div className="compareDataInrRow-p">Reactions</div>
                        <div className="compareDataInrRow-val">{data.reactionCount2}</div>
                    </div>
                    <div className="compareDataInrRow">
                        <div className="compareDataInrRow-val">{data.mentionedCount1}</div>
                        <div className="compareDataInrRow-p">Mentions</div>
                        <div className="compareDataInrRow-val">{data.mentionedCount2}</div>
                    </div>
                    <div className="compareDataInrRow">
                        <div className="compareDataInrRow-val">{data.sent1}</div>
                        <div className="compareDataInrRow-p">Messages Sent</div>
                        <div className="compareDataInrRow-val">{data.sent2}</div>
                    </div>
                    <div className="compareDataInrRow">
                        <div className="compareDataInrRow-val">{data.replied1}</div>
                        <div className="compareDataInrRow-p">Replies</div>
                        <div className="compareDataInrRow-val">{data.replied2}</div>
                    </div>
                    <div className="compareDataInrRow">
                        <div className="compareDataInrRow-val">{data.atLeastDaysNotice1}</div>
                        <div className="compareDataInrRow-p">Invitations sent with at least a day's notice (%)</div>
                        <div className="compareDataInrRow-val">{data.atLeastDaysNotice2}</div>
                    </div>
                    <div className="compareDataInrRow">
                        <div className="compareDataInrRow-val">{data.attendance1}</div>
                        <div className="compareDataInrRow-p">High attendance (%)</div>
                        <div className="compareDataInrRow-val">{data.attendance2}</div>
                    </div>
                    <div className="compareDataInrRow">
                        <div className="compareDataInrRow-val">{data.noMessage1}</div>
                        <div className="compareDataInrRow-p">No chat during meeting (%)</div>
                        <div className="compareDataInrRow-val">{data.noMessage2}</div>
                    </div>
                    <div className="compareDataInrRow">
                        <div className="compareDataInrRow-val">{data.joinedBeforeMeetingStarts1}</div>
                        <div className="compareDataInrRow-p">Online meeting started on time (%)</div>
                        <div className="compareDataInrRow-val">{data.joinedBeforeMeetingStarts2}</div>
                    </div>
                    <div className="compareDataInrRow">
                        <div className="compareDataInrRow-val">{data.duringWorkingHours1}</div>
                        <div className="compareDataInrRow-p">During working hours (%)</div>
                        <div className="compareDataInrRow-val">{data.duringWorkingHours2}</div>
                    </div>
                    <div className="compareDataInrRow">
                        <div className="compareDataInrRow-val">{data.noOverlap1}</div>
                        <div className="compareDataInrRow-p">No overlap with other meetings (%)</div>
                        <div className="compareDataInrRow-val">{data.noOverlap2}</div>
                    </div>
                </div>
            </div>
            <div className="compareTrk compareTm" id="chooseTeamData-right-btn">
                <div className="topUsrArea">
                    <div className="topUsrAreaPic">
                        <div className="topUsrAreaPic-i">
                            <div className="contact-list-icon">
                                <div className="contact-list-icon-txt">{data.initials2}</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        </div>
    );
}

export default UserComparisonView;