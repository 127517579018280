import React from 'react';
import { Flex, Button, Text, Segment } from '@fluentui/react-northstar'
import './notprovisioned.scss';

const NotProvisioned = () => {
    const openInNewTab = (url) => {
        const newWindow = window.open(url, '_blank', 'noopener,noreferrer')
        if (newWindow) newWindow.opener = null
    }
    return (
        <div class="login-container-msg datatrkMain">
        <div class="innerlogin-msg">

            <div class="signUp-data">
                <div class="signUp-datatrk datatrkL">
                    <div class="signUp-datatrkInr">
                        <div class="datatrk-logo">
                            <a class="navbar-logo" href="#" title="Logo">
                                <img src="/logo365.png" alt="Logo" />
                            </a>
                        </div>
                        <div class="datatrk-h">
                            Analytics for Customer Engagement, Collaboration and Wellbeing 
                        </div>
                        <div class="datatrk-p">
                            Analytics 365 is a native app for Microsoft Teams that provides actionable insights into internal and external collaboration to improve business performance.
                            Intuitive dashboards display collaboration trends, participation, activity, sentiment and quality issues. 
                            Company data protection policies and individual privacy is maintained through tight integration with Microsoft 365 admin permissions.
                            Analytics 365 data insights provide a holistic view of people, team and group collaboration. Detailed individual interactions and personal data (such as chat messages and call data) are not made accessible to other subscribers.
                        </div>
                    </div>
                </div>
                <div class="signUp-datatrk datatrkR">
                    <div class="signUp-datatrkInr">
                        <div class="datatrk-h">
                            Sign up now!
                        </div>
                        <div class="datatrk-p">
                            Sign up to subscribe to the app via the Analytics 365 web store. Once submitted, your Office 365 administrator will receive an email requesting permission to be granted to the app. When access has been granted, open your app to start creating your personalised dashboards.
                        </div>
                        <div class="datatrk-h">
                            <div class="datatrk-btnOuter">
                            <a class="datatrk-btn" onClick={() => openInNewTab("https://store.uk.analytics-365.com")}>Sign Up</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    </div>
    );
}

export default NotProvisioned;