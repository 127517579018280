import React, { useEffect, useState } from "react";
import { useTeams } from "../../Shared/Themes";
import CircleChart from "../../Shared/uicomponents/circleChart";
import "./MeetingSummaryView.scss";

const MeetingSummaryView = ({ data }) => {
  //debugger;
  return (
    <div className="meetingArea meettingStr">
      <div className="meetingData">
        <div className="meetingDataCol">
          <div className="meetingDataTitle">Organised</div>
          <div className="meetingDataTrk">
            <div className="meetingDataTotal">
              <div className="meetingDataMain-val">{data.totalOrganized}</div>
            </div>
            <div className="meetingDataSub">
              <div className="meetingEnt">
                <div className="meetingEnt-val">{data.internalOrganized}</div>
                <div className="meetingEnt-p">Internal</div>
              </div>
              <div className="meetingEnt">
                <div className="meetingEnt-val">{data.externalOrganized}</div>
                <div className="meetingEnt-p">External</div>
              </div>
            </div>
          </div>
        </div>
        <div className="meetingDataCol">
          <div className="meetingDataTitle">Participated</div>
          <div className="meetingDataTrk">
            <div className="meetingDataTotal">
              <div className="meetingDataMain-val">
                {data.totalParticipated}
              </div>
            </div>
            <div className="meetingDataSub">
              <div className="meetingEnt">
                <div className="meetingEnt-val">
                  {data.internalParticipated}
                </div>
                <div className="meetingEnt-p">Internal</div>
              </div>
              <div className="meetingEnt">
                <div className="meetingEnt-val">
                  {data.externalParticipated}
                </div>
                <div className="meetingEnt-p">External</div>
              </div>
            </div>
          </div>
        </div>
        <div className="meetingDataCol">
          <div className="meetingDataTitle">Time In Meetings</div>
          <div className="meetingDataTrk">
            <div className="meetingDataTotal">
              <div className="meetingDataMain-val">
                {data.totalTimeInMeetings}
              </div>
            </div>
            <div className="meetingDataSub">
              <div className="meetingEnt">
                <div className="meetingEnt-val">
                  {data.internalTimeInMeetings}
                </div>
                <div className="meetingEnt-p">Internal</div>
              </div>
              <div className="meetingEnt">
                <div className="meetingEnt-val">
                  {data.externalTimeInMeetings}
                </div>
                <div className="meetingEnt-p">External</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="meetingGraph">
        <div className="meetingsGraphTrk">
          <div className="meetingGraph-area">
            <CircleChart
              id="xx"
              value={data.externalParticipation}
              size="small"
            />
          </div>
          <div className="meetingGraph-p">External participation</div>
        </div>
        <div className="meetingsGraphTrk">
          <div className="meetingGraph-area">
            <CircleChart id="xx" value={data.timeSpentOnMeeting} size="small" />
          </div>
          <div className="meetingGraph-p">Time spent on meeting</div>
        </div>
        <div className="meetingsGraphTrk">
          <div className="meetingGraph-area">
            <CircleChart id="xx" value={data.attended} size="small" />
          </div>
          <div className="meetingGraph-p">Attended</div>
        </div>
        <div className="meetingsGraphTrk">
          <div className="meetingGraph-area">
            <CircleChart id="xx" value={data.overrun} size="small" />
          </div>
          <div className="meetingGraph-p">Over run Meetings</div>
        </div>
      </div>
    </div>
  );
};

export default MeetingSummaryView;
