import "./UserSummaryView.scss";
import { Grid, GridColumn as Column } from "@progress/kendo-react-grid";
import * as React from "react";
import { orderBy } from "@progress/kendo-data-query";
import { EmojiIcon } from "@fluentui/react-northstar";

const UserSummaryView = ({ data }) => {
    const [sort, setSort] = React.useState([]);
    // const data = props.data
    return (
        <div className="usr-view">
            <div className="usr-vHdr">
                <div className="usr-vHdr-pro">
                    <div className="topUsrAreaPic">
                        <div className="topUsrAreaPic-i">
                            <div className="contact-list-icon">
                                <div className="contact-list-icon-txt">
                                    <div className="contact-list-icon-img">
                                        <img src={data.image} />
                                    </div>
                                    {data.image == "" ? data.initials : ""}
                                </div>
                            </div>
                        </div>
                        <div className="topUsrAreaPic-tx">
                            <div className="topUsrAreaPic-h">{data.userName}</div>
                            <div className="topUsrAreaPic-p">{data.designation}</div>
                            <div className="topUsrAreaPic-p">{data.email}</div>
                        </div>
                    </div>
                </div>
                <div className="usrHdrR">
                    <div className="usrHdr-topR float-right pl-5">
                        <div className="usr-vHdr-qt">
                            <div className="usr-vHdr-qtTx">Call Quality</div>
                            <div className="usr-vHdr-qtVal">
                                <div className="progress">
                                    <div className="progress-bar bg-success" style={{ width: `${data.callQuality.good}%` }} title="Good">
                                        {data.callQuality.good}
                                    </div>
                                    <div className="progress-bar bg-warning" style={{ width: `${data.callQuality.avg}%` }} title="Average">
                                        {data.callQuality.avg}
                                    </div>
                                    <div className="progress-bar bg-danger" style={{ width: `${data.callQuality.poor}%` }} title="Poor">
                                        {data.callQuality.poor}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="usrHdr-topR float-right">
                        <div className="usr-vHdr-qt">
                            <div className="usr-vHdr-qtTx pb-0">Sentiment</div>
                            <div className="usr-vHdr-qtVal">
                                <EmojiIcon className={`usr-smy-sentiment ${data.messages.avgSentiments}`} title={data.messages.avgSentiments} />
                                {/* <i className="bi bi-emoji-smile-fill text-success" style={{fontSize:"20px"}} title={data.messages.avgSentiments}></i> */}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="usr-vDtl">
                <div className="row">
                    <div className="col-md-4 mb-2 pr-1 pl-1">
                        <div className="usr-vDtlRowTrk h-100">
                            <div className="vDtl-t">
                                <span>Calls</span>
                            </div>
                            <div className="vDtl-inr calcH43 p-0">
                                <div className="row h-50">
                                    <div className="col-md-12 d-flex align-items-center justify-content-center">
                                        <div className="vDtlRow">
                                            <div className="vDtlRowTrk">
                                                <div className="vDtlRowTrkUL">
                                                    <div className="vDtlRowTrkLi">
                                                        <div className="vDtlRowTrk-val">{data.calls.total}</div>
                                                        <div className="vDtlRowTrk-p">Calls</div>
                                                    </div>
                                                    <div className="vDtlRowTrkLi">
                                                        <div className="vDtlRowTrk-val">{data.calls.totalTalkTime}</div>
                                                        <div className="vDtlRowTrk-p">Duration</div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row h-50">
                                    <div className="col-md-6 pr-0 d-flex align-items-center justify-content-center">
                                        <div className="vDtlRow">
                                            <div className="vDtlRowTrk">
                                                <div className="vDtlRowTrkUL">
                                                    <div className="vDtlRowTrkLi">
                                                        <div className="vDtlRowTrk-val">{data.calls.organised}</div>
                                                        <div className="vDtlRowTrk-p">Organised</div>
                                                    </div>
                                                    <div className="vDtlRowTrkLi">
                                                        <div className="vDtlRowTrk-val">{data.calls.organisedTalkTime}</div>
                                                        <div className="vDtlRowTrk-p">Duration</div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-6 pl-0 d-flex align-items-center justify-content-center">
                                        <div className="vDtlRow">
                                            <div className="vDtlRowTrk">
                                                <div className="vDtlRowTrkUL">
                                                    <div className="vDtlRowTrkLi">
                                                        <div className="vDtlRowTrk-val">{data.calls.participated}</div>
                                                        <div className="vDtlRowTrk-p">Participated</div>
                                                    </div>
                                                    <div className="vDtlRowTrkLi">
                                                        <div className="vDtlRowTrk-val">{data.calls.participatedTalkTime}</div>
                                                        <div className="vDtlRowTrk-p">Duration</div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-4 mb-2 pr-1 pl-1">
                        <div className="usr-vDtlRowTrk h-100">
                            <div className="vDtl-t">
                                <span>Messages</span>
                            </div>
                            <div className="vDtl-inr calcH43 p-0">
                                <div className="row h-50">
                                    <div className="col-md-6 pr-0 d-flex align-items-center justify-content-center">
                                        <div className="vDtlRow">
                                            <div className="vDtlRowTrk">
                                                <div className="vDtlRowTrkUL">
                                                    <div className="vDtlRowTrkLi">
                                                        <div className="vDtlRowTrk-val">{data.messages.sent}</div>
                                                        <div className="vDtlRowTrk-p">Sent</div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-6 pl-0 d-flex align-items-center justify-content-center">
                                        <div className="vDtlRow">
                                            <div className="vDtlRowTrk">
                                                <div className="vDtlRowTrkUL">
                                                    <div className="vDtlRowTrkLi">
                                                        <div className="vDtlRowTrk-val">{data.messages.replied}</div>
                                                        <div className="vDtlRowTrk-p">Replied</div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row h-50">
                                    <div className="col-md-6 pr-0 d-flex align-items-center justify-content-center">
                                        <div className="vDtlRow">
                                            <div className="vDtlRowTrk">
                                                <div className="vDtlRowTrkUL">
                                                    <div className="vDtlRowTrkLi">
                                                        <div className="vDtlRowTrk-val">{data.messages.mentions}</div>
                                                        <div className="vDtlRowTrk-p">Mentions</div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-6 pl-0 d-flex align-items-center justify-content-center">
                                        <div className="vDtlRow">
                                            <div className="vDtlRowTrk">
                                                <div className="vDtlRowTrkUL">
                                                    <div className="vDtlRowTrkLi">
                                                        <div className="vDtlRowTrk-val">{data.messages.reactions}</div>
                                                        <div className="vDtlRowTrk-p">Reactions</div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-4 mb-2 pr-1 pl-1">
                        <div className="usr-vDtlRowTrk">
                            <div className="vDtl-t">
                                <span>Collaborators</span>
                            </div>
                            <div className="vDtl-inr">
                                {data.collaborators ?
                                    orderBy(data.collaborators.collabList, sort).map((collaborator) => {
                                        return (
                                            <div className="vDtl-inrRow">
                                                <div className="vDtl-inrRow-p">{collaborator.userName}</div>
                                                <div className="vDtl-inrRow-val">{collaborator.totalTalkTime}</div>
                                            </div>
                                        )
                                    })
                                    : []}
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-8 mb-2 pr-1 pl-1">
                        <div className="usr-vDtlRowTrk h-100">
                            <div className="vDtl-t">
                                <span>Meetings</span>
                                <div className="pointLbl">
                                    <div className="pointLblTrk">
                                        <div className="pointLblTrk-h">{data.meetingOverview.totalStatusUnknown}</div>
                                        <div className="pointLblTrk-p">Status Unknown</div>
                                    </div>
                                    <div className="pointLblTrk">
                                        <div className="pointLblTrk-h">{data.meetingOverview.totalNotAttended}</div>
                                        <div className="pointLblTrk-p">Not Attended</div>
                                    </div>
                                    <div className="pointLblTrk">
                                        <div className="pointLblTrk-h">{data.meetingOverview.totalAttended}</div>
                                        <div className="pointLblTrk-p">Attended</div>
                                    </div>
                                    <div className="pointLblTrk">
                                        <div className="pointLblTrk-h">{data.meetingOverview.totalMeetings}</div>
                                        <div className="pointLblTrk-p">Total</div>
                                    </div>
                                </div>
                            </div>
                            <div className="vDtl-inr">
                                <div className="vDtl-inrRow">
                                    <div className="vDtl-inrRow-p">Time in meetings</div>
                                    <div className="vDtl-inrRow-val">{data.meetings.totalTimeInMeeting}</div>
                                </div>
                                <div className="vDtl-inrRow">
                                    <div className="vDtl-inrRow-p">Recurring meetings</div>
                                    <div className="vDtl-inrRow-val">{data.meetings.totalRecurringMeetings}</div>
                                </div>
                                <div className="vDtl-inrRow">
                                    <div className="vDtl-inrRow-p">Time in recurring meetings</div>
                                    <div className="vDtl-inrRow-val">{data.meetings.totalTimeMeetingRecurring}</div>
                                </div>

                                {/* <div className="vDtl-inrRow">
                                    <div className="vDtl-inrRow-p">Back to back meetings</div>
                                    <div className="vDtl-inrRow-val">36</div>
                                </div> */}
                                <div className="vDtl-inrRow">
                                    <div className="vDtl-inrRow-p">Overlapping meetings</div>
                                    <div className="vDtl-inrRow-val">{data.meetings.totalOverlappingMeetings}</div>
                                </div>
                                <div className="vDtl-inrRow">
                                    <div className="vDtl-inrRow-p">Late attendance</div>
                                    <div className="vDtl-inrRow-val">{data.meetings.totalMeetingsLate}</div>
                                </div>
                                <div className="vDtl-inrRow">
                                    <div className="vDtl-inrRow-p">Overrun meetings</div>
                                    <div className="vDtl-inrRow-val">{data.meetings.totalMeetingsOverran}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-4 mb-2 pr-1 pl-1">
                        <div className="usr-vDtlRowTrk h-100">
                            <div className="vDtl-t">
                                <span>{data.timePeriod}</span>
                            </div>
                            <div className="vDtl-inr calcH43 p-0">
                                <div className="row h-50">
                                    <div className="col-md-12 d-flex align-items-center justify-content-center">
                                        <div className="vDtlRow">
                                            <div className="vDtlRowTrk">
                                                <div className="vDtlRowTrkUL">
                                                    <div className="vDtlRowTrkLi">
                                                        <div className="vDtlRowTrk-val">{data.nextXTime.totalMeetings}</div>
                                                        <div className="vDtlRowTrk-p">Meetings</div>
                                                    </div>
                                                    <div className="vDtlRowTrkLi">
                                                        <div className="vDtlRowTrk-val">{data.nextXTime.totalTimeInMeeting}</div>
                                                        <div className="vDtlRowTrk-p">Duration</div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row h-50">
                                   <div className="col-md-12 pr-0 d-flex align-items-center justify-content-center">
                                        <div className="vDtlRow">
                                            <div className="vDtlRowTrk">
                                                <div className="vDtlRowTrkUL">
                                                    <div className="vDtlRowTrkLi">
                                                        <div className="vDtlRowTrk-val">{data.nextXTime.totalRecurringMeetings}</div>
                                                        <div className="vDtlRowTrk-p">Recurring</div>
                                                    </div>
                                                    <div className="vDtlRowTrkLi">
                                                        <div className="vDtlRowTrk-val">{data.nextXTime.totalTimeMeetingRecurring}</div>
                                                        <div className="vDtlRowTrk-p">Duration</div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div> 
                                    {/*<div className="col-md-6 p-0 d-flex align-items-center justify-content-center">*/}
                                    {/*    <div className="vDtlRow">*/}
                                    {/*        <div className="vDtlRowTrk">*/}
                                    {/*            <div className="vDtlRowTrkUL">*/}
                                    {/*                <div className="vDtlRowTrkLi">*/}
                                    {/*                    <div className="vDtlRowTrk-val">{data.nextXTime.totalOverlappingMeetings}</div>*/}
                                    {/*                    <div className="vDtlRowTrk-p">Overlapping</div>*/}
                                    {/*                </div>*/}
                                    {/*                <div className="vDtlRowTrkLi">*/}
                                    {/*                    <div className="vDtlRowTrk-val">{data.nextXTime.totalTimeMeetingOverlapping}</div>*/}
                                    {/*                    <div className="vDtlRowTrk-p">Duration</div>*/}
                                    {/*                </div>*/}
                                    {/*            </div>*/}
                                    {/*        </div>*/}
                                    {/*    </div>*/}
                                    {/*</div>*/}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default UserSummaryView;
