import React, { useState } from 'react';
import { Collapse, Container,Nav, NavbarBrand, NavItem, NavLink,UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem } from 'reactstrap';
import { Link } from 'react-router-dom';
import './NavMenu.scss';

import { Button, Text, Image } from '@fluentui/react-northstar'
import {  MenuIcon, CloseIcon } from '@fluentui/react-icons-northstar'

export const NavMenu = (props) => {
  const [collapsed,setCollpased] = useState(true);
  return (
    <header>
      <Nav className="header navbar navbar-expand-md border-bottom box-shadow mb-3">
        <Container fluid style={{padding: 0}}>
          <NavbarBrand tag={Link} to="/"><Image styles={{height:"38px"}} src="/logo-master.png" alt="Analytics 365" /></NavbarBrand>
          <Button content={ collapsed ? <MenuIcon size="large" /> : <CloseIcon size="large" />} text iconOnly onClick={() => setCollpased(!collapsed)}  className="mr-2 navbar-toggler"/>
          
          <Collapse className="d-sm-inline-flex flex-sm-row-reverse" isOpen={!collapsed} navbar>
            <ul className="navbar-nav flex-grow">
            <NavItem>
              </NavItem>
              <NavItem>
                <NavLink tag={Link} className="" to="/dashboards"><Text content="My Dashboard" /></NavLink>
              </NavItem>
              <NavItem>
                <NavLink tag={Link} className="" to="/configuration"><Text content="Configuration" /></NavLink>
              </NavItem>
              <UncontrolledDropdown nav inNavbar>
                <DropdownToggle nav tag="div" style={{paddingTop: '0.2rem', cursor: 'pointer'}}>
                  <Image circular src="/images/user-img.jpg" styles={{maxHeight: '30px'}} />
                </DropdownToggle>
                <DropdownMenu right>
                  <DropdownItem active={props.themeString === 'default'} onClick={()=> props.themeHandler('default')}>
                    Teams Light
                  </DropdownItem>
                  <DropdownItem active={props.themeString === 'dark'} onClick={()=> props.themeHandler('dark')}>
                    Dark
                  </DropdownItem>
                  <DropdownItem active={props.themeString === 'contrast'} onClick={()=> props.themeHandler('contrast')}>
                    High Contrast
                  </DropdownItem>
                  <DropdownItem divider />
                  {/* <DropdownItem onClick={() => props.signOutHandler()}> */}
                  <DropdownItem>
                    Sign Out
                  </DropdownItem>
                </DropdownMenu>
              </UncontrolledDropdown>
            </ul>
          </Collapse>
        </Container>
      </Nav>
    </header>
  );
}
