import { Grid, GridColumn as Column } from "@progress/kendo-react-grid";
import "./StandardGrid.scss";
import { CircleIcon } from "@fluentui/react-icons-northstar";
import * as React from "react";
import { orderBy } from "@progress/kendo-data-query";

// const data = [
//     {
//         MessageType: 'Group',
//         Sent: 647,
//         Replied: 453,
//         SentimentScore: 'Positive',
//         MentionedCount: 10,
//     },
//     {
//         MessageType: 'PeerToPeer',
//         Sent: 500,
//         Replied: 350,
//         SentimentScore: 'Neutral',
//         MentionedCount: 10,
//     },
//     {
//         MessageType: 'Team',
//         Sent: 500,
//         Replied: 350,
//         SentimentScore: 'Negative',
//         MentionedCount: 10,
//     }
// ];

class cellWithIcon extends React.Component {
  render() {
    let sentimentScore = this.props.dataItem.sentimentScore ? this.props.dataItem.sentimentScore : this.props.dataItem.sentiment;
    let className = "st_good";
    switch (sentimentScore) {
      case "Negative":
        className = "st_poor";
        break;
      case "Neutral":
        className = "st_average";
        break;
      case "Positive":
        className = "st_good";
        break;
      default:
        className = "st_good";
        sentimentScore = "Positive";
    }
    return (
      <td>
        <CircleIcon className={className} /> {sentimentScore}
      </td>
    );
  }
}

export default function StandardGrid({ data, widgetId }) {
  const [sort, setSort] = React.useState([]);

  return (
    <>
      {data && data.length > 0 && widgetId === 6 && (
        <Grid
          className="standardGrid"
          style={{ height: "100%" }}
          data={orderBy(data, sort)}
          sortable={true}
          sort={sort}
          onSortChange={(e) => {
            setSort(e.sort);
          }}
        >
          <Column field="messageType" title="Message Type" width={100} locked />
          <Column field="sent" title="Sent" width={50} />
          <Column field="replied" title="Replied" width={65} />
          <Column
            field="sentimentScore"
            title="Sentiment Score"
            cell={cellWithIcon}
            width={115}
          />
        </Grid>
      )}
      {data && data.length > 0 && widgetId === 27 && (
        <Grid
          className="standardGrid"
          style={{ height: "100%" }}
          data={orderBy(data, sort)}
          sortable={true}
          sort={sort}
          onSortChange={(e) => {
            setSort(e.sort);
          }}
        >
          <Column field="groupName" title="Team Name" width={85} locked />
          <Column field="replied" title="Replies" width={85} />
          <Column field="posted" title="Posts" width={85} />
          <Column field="mentions" title="Mentions" width={80} />
          <Column field="reactions" title="Reactions" width={82} />
          <Column
            field="sentimentScore"
            title="Sentiment Score"
            cell={cellWithIcon}
            width={115}
          />
          <Column field="meetings" title="Calls" width={85} />
        </Grid>
      )}
      {data && data.length > 0 && widgetId === 28 && (
        <Grid
          className="standardGrid"
          style={{ height: "100%" }}
          data={orderBy(data, sort)}
          sortable={true}
          sort={sort}
          onSortChange={(e) => {
            setSort(e.sort);
          }}
        >
          <Column field="groupName" title="Team Name" locked width={150} />
          <Column
            width={150}
            field="atLeastDaysNotice"
            title="Invitation sent with at least a day's notice"
          />
          <Column width={150} field="attendance" title="High attendance" />
          <Column
            width={150}
            field="noMessage"
            title="No chat during meeting"
          />
          <Column
            width={150}
            field="joinedBeforeMeetingStarts"
            title="Online meeting started on time"
          />
          <Column
            width={150}
            field="duringWorkingHours"
            title="During working hours"
          />
          <Column
            width={150}
            field="noOverlap"
            title="No overlap with other meetings"
          />
        </Grid>
      )}
      {data && data.length > 0 && widgetId === 29 && (
        <Grid
          className="standardGrid"
          style={{ height: "100%" }}
          data={orderBy(data, sort)}
          sortable={true}
          sort={sort}
          onSortChange={(e) => {
            setSort(e.sort);
          }}
        >
          <Column field="userName" title="Name" width={80} locked />
          <Column field="totalOrganized" title="Organised" width={78} />
          <Column field="totalParticipated" title="Participated" width={90} />
          <Column field="totalTalkTime" title="Total Talk Time" width={103} />
        </Grid>
      )}
      {data && data.length > 0 && widgetId === 35 && (
        <Grid
          className="standardGrid"
          style={{ height: "100%" }}
          data={orderBy(data, sort)}
          sortable={true}
          sort={sort}
          onSortChange={(e) => {
            setSort(e.sort);
          }}
        >
          <Column field="teamName" title="Team Name" locked width={100} />
          <Column field="calls" title="Calls" width={80} />
          <Column field="totalTalkTime" title="Total Talk Time" width={116} />
          <Column field="posted" title="Posts" width={116} />
          <Column field="replied" title="Replies	" width={126} />
          <Column
            field="sentimentScore"
            title="Sentiment Score"
            cell={cellWithIcon}
            width={126}
          />
        </Grid>
      )}
      {data && data.length > 0 && widgetId === 36 && (
        <Grid
          className="standardGrid"
          style={{ height: "100%" }}
          data={orderBy(data, sort)}
          sortable={true}
          sort={sort}
          onSortChange={(e) => {
            setSort(e.sort);
          }}
        >
          <Column width={150} field="teamName" title="Team Name" locked />
          <Column width={100} field="memberANDguestCount" title="Members" />
          <Column width={100} field="ownerCount" title="Owners" />
          <Column width={150} field="dateCreated" title="Creation Date" />
          <Column width={150} field="totalChat" title="Posts" />
          <Column width={150} field="totalReplied" title="Replies" />
          <Column width={100} field="reactionCount" title="Reactions" />
          <Column width={100} field="mentionedCount" title="Mentions" />
          <Column width={150} field="totalCalls" title="Calls" />
          <Column width={150} field="mostRecentTime" title="Last Activity" />
        </Grid>
      )}
    </>
  );
}
