import axios, { AxiosInstance } from 'axios';
import AuthHelper from './auth-helper';
import { AuthenticationResult } from '@azure/msal-browser';
import * as constants from '../constants';

class ConfigurationApiService {

    static AuthenticatedApi = axios.create({
        baseURL: constants.Api.baseUri,
        headers: { 
            'Content-Type': 'application/json',
        }
    });

    // Users

    static GetAdminUserData(token) {
        return this.AuthenticatedApi.get('/UserConfig/GetAdminUserData', {
            headers: { Authorization: 'Bearer ' + token }
        })
        .then((response) => { return response.data; })
        .catch((error) => { throw Error('An error has occurred calling the web api: ' + error); });
    }

    static GetUsers(token) {
        return this.AuthenticatedApi.get('/UserConfig/GetUsers', {
            headers: { Authorization: 'Bearer ' + token }
        })
        .then((response) => { return response.data; })
        .catch((error) => { throw Error('An error has occurred calling the web api: ' + error); });
    }

    static UpdateUserAdminAccess(data, token) {
        return this.AuthenticatedApi.post('/UserConfig/UpdateAdministrativeAccess', data, {
            headers: { Authorization: 'Bearer ' + token }
        })
        .then((response) => { return response.data; })
        .catch((error) => { throw Error('An error has occurred calling the web api: ' + error); });
    }

    static UpdateUserReportingAccess(data, token) {
        return this.AuthenticatedApi.post('/UserConfig/UpdateReportingAccess', data, {
            headers: { Authorization: 'Bearer ' + token }
        })
        .then((response) => { return response.data; })
        .catch((error) => { throw Error('An error has occurred calling the web api: ' + error); });
    }

    static UpdateUserSubscription(data, token) {
        return this.AuthenticatedApi.post('/UserConfig/UpdateUserSubscription', data, {
            headers: { Authorization: 'Bearer ' + token }
        })
        .then((response) => { return response.data; })
        .catch((error) => { throw Error('An error has occurred calling the web api: ' + error); });
    }

    static UpdateUserTrialSubscription(data, token) {
        return this.AuthenticatedApi.post('/UserConfig/UpdateUserTrialSubscription', data, {
            headers: { Authorization: 'Bearer ' + token }
        })
        .then((response) => { return response.data; })
        .catch((error) => { throw Error('An error has occurred calling the web api: ' + error); });
    }

    static GetUserProfile(userGUID, token) {
        return this.AuthenticatedApi.get('/UserConfig/GetUserProfile/' + userGUID, {
            headers: { Authorization: 'Bearer ' + token }
        })
        .then((response) => { return response.data; })
        .catch((error) => { throw Error('An error has occurred calling the web api: ' + error); });
    }

    static GetUserPermissions(userGUID, token) {
        return this.AuthenticatedApi.get('/UserConfig/GetUserPermissions/' + userGUID, {
            headers: { Authorization: 'Bearer ' + token }
        })
        .then((response) => { return response.data; })
        .catch((error) => { throw Error('An error has occurred calling the web api: ' + error); });
    }

    static UpdateUserPermissions(data, token) {
        return this.AuthenticatedApi.post('/UserConfig/UpdateUserPermissions', data, {
            headers: { Authorization: 'Bearer ' + token }
        })
        .then((response) => { return response.data; })
        .catch((error) => { throw Error('An error has occurred calling the web api: ' + error); });
    }

    static GetUserWorkingHours(userGUID, token) {
        return this.AuthenticatedApi.get('/UserConfig/GetUserWorkingHours/' + userGUID, {
            headers: { Authorization: 'Bearer ' + token }
        })
        .then((response) => { return response.data; })
        .catch((error) => { throw Error('An error has occurred calling the web api: ' + error); });
    }

    static UpdateUserWorkingHours(data, token) {
        return this.AuthenticatedApi.post('/UserConfig/UpdateUserWorkingHours', data, {
            headers: { Authorization: 'Bearer ' + token }
        })
        .then((response) => { return response.data; })
        .catch((error) => { throw Error('An error has occurred calling the web api: ' + error); });
    }

    static AddUserLogin(userGUID, token) {
        return this.AuthenticatedApi.get('/UserConfig/AddUserLogin/' + userGUID, {
            headers: { Authorization: 'Bearer ' + token }
        })
        .then((response) => { return response.data; })
        .catch((error) => { throw Error('An error has occurred calling the web api: ' + error); });
    }

    static DeleteUserLogin(userGUID, token) {
        return this.AuthenticatedApi.get('/UserConfig/DeleteUserLogin/' + userGUID, {
            headers: { Authorization: 'Bearer ' + token }
        })
        .then((response) => { return response.data; })
        .catch((error) => { throw Error('An error has occurred calling the web api: ' + error); });
    }
    

    // Groups

    static GetGroups(token) {
        return this.AuthenticatedApi.get('/UserConfig/GetGroups', {
            headers: { Authorization: 'Bearer ' + token }
        })
        .then((response) => { return response.data; })
        .catch((error) => { throw Error('An error has occurred calling the web api: ' + error); });
    }

    static GetUsersByGroupId(groupId, token) {
        return this.AuthenticatedApi.get('/UserConfig/GetUsersByGroupId/' + groupId, {
            headers: { Authorization: 'Bearer ' + token }
        })
        .then((response) => { return response.data; })
        .catch((error) => { throw Error('An error has occurred calling the web api: ' + error); });
    }

    static UpdateGroupAdminAccess(data, token) {
        return this.AuthenticatedApi.post('/UserConfig/UpdateGroupAdministrativeAccess', data, {
            headers: { Authorization: 'Bearer ' + token }
        })
        .then((response) => { return response.data; })
        .catch((error) => { throw Error('An error has occurred calling the web api: ' + error); });
    }

    static UpdateGroupReportingAccess(data, token) {
        return this.AuthenticatedApi.post('/UserConfig/UpdateGroupReportingAccess', data, {
            headers: { Authorization: 'Bearer ' + token }
        })
        .then((response) => { return response.data; })
        .catch((error) => { throw Error('An error has occurred calling the web api: ' + error); });
    }

    static UpdateGroupSubscription(data, token) {
        return this.AuthenticatedApi.post('/UserConfig/UpdateGroupSubscription', data, {
            headers: { Authorization: 'Bearer ' + token }
        })
        .then((response) => { return response.data; })
        .catch((error) => { throw Error('An error has occurred calling the web api: ' + error); });
    }

    static UpdateGroupTrialSubscription(data, token) {
        return this.AuthenticatedApi.post('/UserConfig/UpdateGroupTrialSubscription', data, {
            headers: { Authorization: 'Bearer ' + token }
        })
        .then((response) => { return response.data; })
        .catch((error) => { throw Error('An error has occurred calling the web api: ' + error); });
    }
    

    // Business Hours

    static GetTemplates(token) {
        return this.AuthenticatedApi.get('/BusinessHoursConfig/GetTemplates', {
            headers: { Authorization: 'Bearer ' + token }
        })
        .then((response) => { return response.data; })
        .catch((error) => { throw Error('An error has occurred calling the web api: ' + error); });
    }

    static AddTemplate(templateData, token) {
        return this.AuthenticatedApi.post('/BusinessHoursConfig/AddTemplate', templateData, {
            headers: { Authorization: 'Bearer ' + token }
        })
        .then((response) => { return response.data; })
        .catch((error) => { throw Error('An error has occurred calling the web api: ' + error); });
    }

    static UpdateTemplate(templateData, token) {
        return this.AuthenticatedApi.post('/BusinessHoursConfig/UpdateTemplate', templateData, {
            headers: { Authorization: 'Bearer ' + token }
        })
        .then((response) => { return response.data; })
        .catch((error) => { throw Error('An error has occurred calling the web api: ' + error); });
    }

    static DeleteTemplate(templateGUID, token) {
        return this.AuthenticatedApi.delete('/BusinessHoursConfig/DeleteTemplate/' + templateGUID, {
            headers: { Authorization: 'Bearer ' + token }
        })
        .then((response) => { return response.data; })
        .catch((error) => { throw Error('An error has occurred calling the web api: ' + error); });
    }

    
    // Tenant Details

    static GetTenantDetails(token) {
        return this.AuthenticatedApi.get('/Tenant/', {
            headers: { Authorization: 'Bearer ' + token }
        })
        .then((response) => { return response.data; })
        .catch((error) => { throw Error('An error has occurred calling the web api: ' + error); });
    }


}

export default ConfigurationApiService;
