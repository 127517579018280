import { Grid, GridColumn as Column } from "@progress/kendo-react-grid";
import "./GroupedGrid.scss";
import { CircleIcon } from "@fluentui/react-icons-northstar";
import * as React from "react";
import { orderBy } from "@progress/kendo-data-query";

class cellWithIconAudio extends React.Component {
  render() {
    let avgCallQuality = this.props.dataItem.audio.avgCallQuality;
    let _className = "st_good";
    switch (avgCallQuality) {
      case "Poor":
        _className = "st_poor";
        break;
      case "Average":
        _className = "st_average";
        break;
      case "Good":
        _className = "st_good";
        break;
      default:
        _className = "st_none";
        avgCallQuality = "N/A";
    }
    return (
      <td className={this.props.className}>
        <CircleIcon className={_className} /> {avgCallQuality}
      </td>
    );
  }
}

class cellWithIconVideo extends React.Component {
  render() {
    let avgCallQuality = this.props.dataItem.video.avgCallQuality;
    let _className = "st_good";
    switch (avgCallQuality) {
      case "Poor":
        _className = "st_poor";
        break;
      case "Average":
        _className = "st_average";
        break;
      case "Good":
        _className = "st_good";
        break;
      default:
        _className = "st_none";
        avgCallQuality = "N/A";
    }
    return (
      <td className={this.props.className}>
        <CircleIcon className={_className} /> {avgCallQuality}
      </td>
    );
  }
}

class cellWithIconSentimentPeer extends React.Component {
  render() {
    let sentimentScore = this.props.dataItem.peer.sentimentScore;
    let _className = "st_good";
    switch (sentimentScore) {
      case "Negative":
        _className = "st_poor";
        break;
      case "Neutral":
        _className = "st_average";
        break;
      case "Positive":
        _className = "st_good";
        break;
      default:
        _className = "st_average";
        sentimentScore = "Neutral";
    }
    return (
      <td className={this.props.className}>
        <CircleIcon className={_className} /> {sentimentScore}
      </td>
    );
  }
}

class cellWithIconSentimentTeam extends React.Component {
  render() {
    let sentimentScore = this.props.dataItem.team.sentimentScore;
    let _className = "st_good";
    switch (sentimentScore) {
      case "Negative":
        _className = "st_poor";
        break;
      case "Neutral":
        _className = "st_average";
        break;
      case "Positive":
        _className = "st_good";
        break;
      default:
        _className = "st_average";
        sentimentScore = "Neutral";
    }
    return (
      <td className={this.props.className}>
        <CircleIcon className={_className} /> {sentimentScore}
      </td>
    );
  }
}

class cellWithIconSentimentTotal extends React.Component {
  render() {
    let sentimentScore = this.props.dataItem.total.sentimentScore;
    let _className = "st_good";
    switch (sentimentScore) {
      case "Negative":
        _className = "st_poor";
        break;
      case "Neutral":
        _className = "st_average";
        break;
      case "Positive":
        _className = "st_good";
        break;
      default:
        _className = "st_none";
        sentimentScore = "";
    }
    return (
      <td className={this.props.className}>
        <CircleIcon className={_className} /> {sentimentScore}
      </td>
    );
  }
}

export default function GroupedGrid({ data, widgetId }) {
  const [sort, setSort] = React.useState([]);
  return (
    <>
      {data && data.length > 0 && widgetId === 5 && (
        <Grid
          className="groupedGrid"
          style={{ height: "100%" }}
          // resizable={true}
          // reorderable={true}
          data={orderBy(data, sort)}
          sortable={true}
          sort={sort}
          onSortChange={(e) => {
            setSort(e.sort);
          }}
        >
          <Column
            field="callType"
            title="Call Type"
            width={100}
            resizable={false}
            locked
          />
          <Column title="Audio">
            <Column className="darken" width={105} field="audio.organised" title="Organised" />
            <Column
              className="darken" 
              width={111}
              field="audio.participated"
              title="Participated"
            />
            <Column
              className="darken" 
              width={112}
              field="audio.totalTalkTime"
              title="Total Talk Time"
            />
            <Column
              className="darken" 
              width={112}
              field="audio.avgTalkTime"
              title="Avg. Talk Time"
            />
            <Column
              className="darken" 
              width={112}
              field="audio.avgCallQuality"
              title="Avg. Call Quality"
              cell={cellWithIconAudio}
            />
          </Column>
          <Column title="Video">
            <Column width={105} field="video.organised" title="Organised" />
            <Column
              width={111}
              field="video.participated"
              title="Participated"
            />
            <Column
              width={112}
              field="video.totalTalkTime"
              title="Total Talk Time"
            />
            <Column
              width={112}
              field="video.avgTalkTime"
              title="Avg. Talk Time"
            />
            <Column
              width={112}
              field="video.avgCallQuality"
              title="Avg. Call Quality"
              cell={cellWithIconVideo}
            />
          </Column>
        </Grid>
      )}
      
      {data && data.length > 0 && widgetId === 15 && (
        <Grid
          className="groupedGrid"
          style={{ height: "100%" }}
          data={orderBy(data, sort)}
          sortable={true}
          sort={sort}
          onSortChange={(e) => {
            setSort(e.sort);
          }}
        >
          <Column field="displayName" title="Name" width={80} locked />
          <Column title="Calls">
            <Column field="internalCalls" title="Internal" width={60} />
            <Column field="externalCalls" title="External" width={65} />
          </Column>
          <Column title="Meetings">            
            <Column className="darken" field="internalMeetings" title="Internal" width={75} />
            <Column className="darken" field="externalMeetings" title="External" width={85} />
          </Column>  
          <Column field="totalCalls" title="Total Calls" width={80} />
          <Column field="totalTalkTime" title="Total Talk Time" width={102} />
          <Column title="Chats">            
            <Column className="darken" field="totalSent" title="Sent" width={44} />
            <Column className="darken" field="totalReplied" title="Replied" width={60} />
          </Column>
          <Column field="totalChat" title="Total Chats" width={82} />
        </Grid>
      )}
      {data && data.length > 0 && (widgetId === 34 || widgetId === 37) && (
        <Grid
          className="groupedGrid"
          style={{ height: "100%" }}
          // resizable={true}
          // reorderable={true}
          data={orderBy(data, sort)}
          sortable={true}
          sort={sort}
          onSortChange={(e) => {
            setSort(e.sort);
          }}
        >
          <Column
            field="userName"
            title="Name"
            width={100}
            resizable={false}
            locked
          />
          <Column title="PeerToPeer">
            <Column className="darken" width={80} field="peer.sent" title="Sent" />
            <Column className="darken" width={80} field="peer.replied" title="Replied" />
            <Column
              className="darken" 
              width={126}
              field="peer.sentimentScore"
              title="Sentiment Score"
              cell={cellWithIconSentimentPeer}
            />
          </Column>
          <Column title="Team">
            <Column width={80} field="team.sent" title="Sent" />
            <Column width={80} field="team.replied" title="Replied" />
            <Column
              width={126}
              field="team.sentimentScore"
              title="Sentiment Score"
              cell={cellWithIconSentimentTeam}
            />
          </Column>
          <Column title="Total">
            <Column className="darken" width={80} field="total.sent" title="Sent" />
            <Column className="darken" width={80} field="total.replied" title="Replied" />
            <Column
              className="darken" 
              width={126}
              field="total.sentimentScore"
              title="Sentiment Score"
              cell={cellWithIconSentimentTotal}
            />
          </Column>
        </Grid>
      )}
      {data && data.length > 0 && widgetId === 40 && (
        <Grid
          className="groupedGrid"
          style={{ height: "100%" }}
          // resizable={true}
          // reorderable={true}
          data={orderBy(data, sort)}
          sortable={true}
          sort={sort}
          onSortChange={(e) => {
            setSort(e.sort);
          }}
        >
          <Column
            field="userName"
            title="Name"
            width={100}
            resizable={false}
            locked
          />
          <Column title="PeerToPeer">
            <Column className="darken" width={92} field="peer.organized" title="Organised" />
            <Column className="darken" width={92} field="peer.participated" title="Participated" />
            <Column
              className="darken" 
              width={106}
              field="peer.totalTalkTime"
              title="Total Talk Time"
            />
          </Column>
          <Column title="Group">
            <Column width={92} field="group.organized" title="Organised" />
            <Column
              width={92}
              field="group.participated"
              title="Participated"
            />
            <Column
              width={106}
              field="group.totalTalkTime"
              title="Total Talk Time"
            />
          </Column>
          <Column title="Team">
            <Column className="darken" width={92} field="team.organized" title="Organised" />
            <Column className="darken" width={92} field="team.participated" title="Participated" />
            <Column
              className="darken" 
              width={106}
              field="team.totalTalkTime"
              title="Total Talk Time"
            />
          </Column>
          <Column title="Total">
            <Column width={92} field="total.organized" title="Organised" />
            <Column
              width={92}
              field="total.participated"
              title="Participated"
            />
            <Column
              width={106}
              field="total.totalTalkTime"
              title="Total Talk Time"
            />
          </Column>
        </Grid>
      )}
    </>
  );
}
