import React from 'react';
import {useState, useEffect } from 'react';
import { 
  Flex, 
  Form, 
  Input,
  Box,
  Grid, 
  Button, 
  Text, 
  Loader
} from '@fluentui/react-northstar'
import { 
  WindowMinimizeIcon,
} from '@fluentui/react-icons-northstar'
import '../Dashboard/dashboard.scss';
import ConfigurationApiService from '../../../services/ConfigurationApiService';
import AuthHelper from '../../../services/auth-helper';
import TeamsTimePicker from "../../Shared/uicomponents/TeamsTimePicker";
import NativeTimePicker from "../../Shared/uicomponents/NativeTimePicker";
import WindowContext from "../../Shared/Context/Context";


const TimePickerComponent = (props) => {
    return (
        <> 
            <Text content={props.lableText} />
            {
                props.context.teams.hostClientType.web || props.context.teams.hostClientType.desktop ?
                <TeamsTimePicker value={props.startTime} onChange={(value) => {props.onStartTimeChange(value)}} required={true}/>
                :
                <NativeTimePicker id={props.day+"StartTime"} value={props.startTime} onChange={(value) => {props.onStartTimeChange(value)}} required={true} />
            }
            <WindowMinimizeIcon />
            {
                props.context.teams.hostClientType.web || props.context.teams.hostClientType.desktop ?
                <TeamsTimePicker min={props.startTime} value={props.endTime} onChange={(value) => {props.onEndTimeChange(value)}} required={true}/>
                :
                <NativeTimePicker id={props.day+"EndTime"} min={props.startTime} value={props.endTime} onChange={(value) => {props.onEndTimeChange(value)}} required={true} />
            }
        </>
    );
}


const BusinessHourTemplate = (props) => {
    const [loading, setLoading] = useState(false);
    const [templateGUID, setTemplateGUID] = useState('');
    const [templateName, setTemplateName] = useState('');
    const [mondayStart, setMondayStart] = useState('00:00');
    const [mondayEnd, setMondayEnd] = useState('00:00');
    const [tuesdayStart, setTuesdayStart] = useState('00:00');
    const [tuesdayEnd, setTuesdayEnd] = useState('00:00');
    const [wednesdayStart, setWednesdayStart] = useState('00:00');
    const [wednesdayEnd, setWednesdayEnd] = useState('00:00');
    const [thursdayStart, setThursdayStart] = useState('00:00');
    const [thursdayEnd, setThursdayEnd] = useState('00:00');
    const [fridayStart, setFridayStart] = useState('00:00');
    const [fridayEnd, setFridayEnd] = useState('00:00');
    const [saturdayStart, setSaturdayStart] = useState('00:00');
    const [saturdayEnd, setSaturdayEnd] = useState('00:00');
    const [sundayStart, setSundayStart] = useState('00:00');
    const [sundayEnd, setSundayEnd] = useState('00:00');

    useEffect(() => {
        if(props.isEdit){
            setLoading(true)
            var timeData = props.templateValues.timeDetails
            setTemplateGUID(props.templateValues.templateGUID)
            setTemplateName(props.templateValues.name)
            timeData.forEach(item => {
                if(item.dayOfWeekId === 0){
                    setSundayEnd(item.endTime)
                    setSundayStart(item.startTime)
                }
                else if(item.dayOfWeekId === 1){
                    setMondayEnd(item.endTime)
                    setMondayStart(item.startTime)
                }
                else if(item.dayOfWeekId === 2){
                    setTuesdayEnd(item.endTime)
                    setTuesdayStart(item.startTime)
                }
                else if(item.dayOfWeekId === 3){
                    setWednesdayEnd(item.endTime)
                    setWednesdayStart(item.startTime)
                }
                else if(item.dayOfWeekId === 4){
                    setThursdayEnd(item.endTime)
                    setThursdayStart(item.startTime)
                }
                else if(item.dayOfWeekId === 5){
                    setFridayEnd(item.endTime)
                    setFridayStart(item.startTime)
                }
                else if(item.dayOfWeekId === 6){
                    setSaturdayEnd(item.endTime)
                    setSaturdayStart(item.startTime)
                }
            });
            setLoading(false)
        }
    }, [])


    const handleNameValue = (event) => {
        setTemplateName(event.target.value)
    }

    
    const submitForm = () => {
        setLoading(true)
        if(props.isEdit){
            var templateData = {
                name: templateName,
                templateGUID: templateGUID,
                timeDetails: [
                    { dayOfWeekId: 0, startTime: sundayStart, endTime: sundayEnd },
                    { dayOfWeekId: 1, startTime: mondayStart, endTime: mondayEnd },
                    { dayOfWeekId: 2, startTime: tuesdayStart, endTime: tuesdayEnd },
                    { dayOfWeekId: 3, startTime: wednesdayStart, endTime: wednesdayEnd },
                    { dayOfWeekId: 4, startTime: thursdayStart, endTime: thursdayEnd },
                    { dayOfWeekId: 5, startTime: fridayStart, endTime: fridayEnd },
                    { dayOfWeekId: 6, startTime: saturdayStart, endTime: saturdayEnd }
                ]
            }
            AuthHelper.getAccessToken(function(token){
                ConfigurationApiService.UpdateTemplate(templateData, token)
                .then((response) => {
                    if(response !== undefined && response !== ""){
                        props.handleFormSubmit(response)
                    }
                    setLoading(false)
                }); 
            })
        }else{
            var templateData = {
                name: templateName,
                timeDetails: [
                    { dayOfWeekId: 0, startTime: sundayStart, endTime: sundayEnd },
                    { dayOfWeekId: 1, startTime: mondayStart, endTime: mondayEnd },
                    { dayOfWeekId: 2, startTime: tuesdayStart, endTime: tuesdayEnd },
                    { dayOfWeekId: 3, startTime: wednesdayStart, endTime: wednesdayEnd },
                    { dayOfWeekId: 4, startTime: thursdayStart, endTime: thursdayEnd },
                    { dayOfWeekId: 5, startTime: fridayStart, endTime: fridayEnd },
                    { dayOfWeekId: 6, startTime: saturdayStart, endTime: saturdayEnd }
                ]
            }
            AuthHelper.getAccessToken(function(token){
                ConfigurationApiService.AddTemplate(templateData, token)
                .then((response) => {
                    if(response !== undefined && response !== ""){
                        props.handleFormSubmit(response)
                    }
                    setLoading(false)
                });
            })
        }
        
    }

    const handleKeyDown = (e) => {
        if (e.keyCode === 32) {
            e.stopPropagation();
        }
    }

    return(
        <WindowContext.Consumer>
        {
            context => 
            (
                (loading) ? <Loader/> :
                <Box>
                    <Form className="edit_user_officeHoursForm" onSubmit={submitForm} styles={{marginTop: "0px", fontSize:'0.9rem'}}>
                        <Grid className="whRow" columns={2} styles={{width: '100%', alignItems: 'center', gridColumnGap: '8px', gridTemplateColumns:'130px calc(100% - 130px)', marginBottom: '0.6rem !important'}}>
                            <Text content="Profile Name : " />
                                <Form.Field required control={{ as: Input, value: templateName, onChange: handleNameValue, onKeyDown: handleKeyDown, fluid: true }} />
                        </Grid>
                        <Grid columns={4} styles={{width: '100%', alignItems: 'center', gridColumnGap: '8px', gridTemplateColumns:'94px calc(50% - 72px) 25px calc(50% - 72px)', marginBottom: '0.6rem !important'}}>
                            <TimePickerComponent 
                                lableText={"Monday : "}
                                day={"monday"}
                                context={context} 
                                startTime={mondayStart}
                                endTime={mondayEnd}
                                onStartTimeChange={(value) => {setMondayStart(value)}}
                                onEndTimeChange={(value) => {setMondayEnd(value)}}
                            />
                        </Grid>
                        <Grid columns={4} styles={{width: '100%', alignItems: 'center', gridColumnGap: '8px', gridTemplateColumns:'94px calc(50% - 72px) 25px calc(50% - 72px)', marginBottom: '0.6rem !important'}}>
                            <TimePickerComponent 
                                lableText={"Tuesday : "}
                                day={"tuesday"}
                                context={context} 
                                startTime={tuesdayStart}
                                endTime={tuesdayEnd}
                                onStartTimeChange={(value) => {setTuesdayStart(value)}}
                                onEndTimeChange={(value) => {setTuesdayEnd(value)}}
                            />
                        </Grid>
                        <Grid columns={4} styles={{width: '100%', alignItems: 'center', gridColumnGap: '8px', gridTemplateColumns:'94px calc(50% - 72px) 25px calc(50% - 72px)', marginBottom: '0.6rem !important'}}>
                            <TimePickerComponent 
                                lableText={"Wednesday : "}
                                day={"wednesday"}
                                context={context} 
                                startTime={wednesdayStart}
                                endTime={wednesdayEnd}
                                onStartTimeChange={(value) => {setWednesdayStart(value)}}
                                onEndTimeChange={(value) => {setWednesdayEnd(value)}}
                            />
                        </Grid>
                        <Grid columns={4} styles={{width: '100%', alignItems: 'center', gridColumnGap: '8px', gridTemplateColumns:'94px calc(50% - 72px) 25px calc(50% - 72px)', marginBottom: '0.6rem !important'}}>
                            <TimePickerComponent 
                                lableText={"Thursday : "}
                                day={"thursday"}
                                context={context} 
                                startTime={thursdayStart}
                                endTime={thursdayEnd}
                                onStartTimeChange={(value) => {setThursdayStart(value)}}
                                onEndTimeChange={(value) => {setThursdayEnd(value)}}
                            />
                        </Grid>
                        <Grid columns={4} styles={{width: '100%', alignItems: 'center', gridColumnGap: '8px', gridTemplateColumns:'94px calc(50% - 72px) 25px calc(50% - 72px)', marginBottom: '0.6rem !important'}}>
                            <TimePickerComponent 
                                lableText={"Friday : "}
                                day={"friday"}
                                context={context} 
                                startTime={fridayStart}
                                endTime={fridayEnd}
                                onStartTimeChange={(value) => {setFridayStart(value)}}
                                onEndTimeChange={(value) => {setFridayEnd(value)}}
                            />
                        </Grid>
                        <Grid columns={4} styles={{width: '100%', alignItems: 'center', gridColumnGap: '8px', gridTemplateColumns:'94px calc(50% - 72px) 25px calc(50% - 72px)', marginBottom: '0.6rem !important'}}>
                            <TimePickerComponent 
                                lableText={"Saturday : "}
                                day={"saturday"}
                                context={context} 
                                startTime={saturdayStart}
                                endTime={saturdayEnd}
                                onStartTimeChange={(value) => {setSaturdayStart(value)}}
                                onEndTimeChange={(value) => {setSaturdayEnd(value)}}
                            />
                        </Grid>
                        <Grid columns={4} styles={{width: '100%', alignItems: 'center', gridColumnGap: '8px', gridTemplateColumns:'94px calc(50% - 72px) 25px calc(50% - 72px)', marginBottom: '0.6rem !important'}}>
                            <TimePickerComponent 
                                lableText={"Sunday : "}
                                day={"sunday"}
                                context={context} 
                                startTime={sundayStart}
                                endTime={sundayEnd}
                                onStartTimeChange={(value) => {setSundayStart(value)}}
                                onEndTimeChange={(value) => {setSundayEnd(value)}}
                            />
                        </Grid>

                        <Flex space="between" styles={{marginTop: "0.6rem"}}>
                            <Box />
                            <Flex gap="gap.small">
                                <Button content="Cancel" onClick={() => props.onCancel()}/>
                                <Button content="Save" type="submit" primary/>
                            </Flex>
                        </Flex>
                    </Form>
                </Box>
            )
        }
        </WindowContext.Consumer>
    )
}
export default BusinessHourTemplate;