import React from "react";
// import { Loader } from "@fluentui/react-northstar";
import "./ReactionsView.scss";

const ReactionsView = ({ data }) => {
  return (
    <div className="reaction-part">
      {data &&
        data.length > 0 &&
        data.map((d, id) => {
          return (
            <div
              key={id}
              className={`reaction-part-trk p-${d.reactionName.toLowerCase()}`}
              title=""
              data-toggle="tooltip"
              data-placement="top"
              data-original-title={d.reactionName}
            >
              <div className="reaction-part-icn">
                {/* <img src="assets/images/reaction-like.png" alt="Like"> */}
                {/* <LikeSolidIcon /> */}
                <span
                  className={`static-reaction-${d.reactionName.toLowerCase()}`}
                  title={d.reactionName}
                >
                  <span
                    className={`animated-reaction-${d.reactionName.toLowerCase()}`}
                  >
                    <span className="reaction" />
                  </span>
                </span>
              </div>
              <div className="reaction-part-val text-center">
                {d.reactionCount}
              </div>
            </div>
          );
        })}
    </div>
  );
};

export default ReactionsView;
